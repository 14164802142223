<template>
  <div>
    <section class="py-16 px-4 ">
      <div class="text-center">
        <span class="text-gray-600">Was können wir sonst für dich tun?</span>
        <a href="https://www.leecon.de/" target="blank">
          <h2 class="text-4xl text-center mb-12 leading-tight text-blue-250">Unser Angebot für <span
              class="text-red-150 font-semibold">Firmen</span> </h2>
        </a>
      </div>
      <div class="flex flex-wrap max-w-5xl -mx-4 md:mx-auto -mb-8">
        <div class="w-full md:w-1/2 px-4 mb-8">
          <div class="h-full p-6 bg-white rounded border-t-4 border-red-150 shadow-md">
            <a href="https://www.leecon.de/" target="blank">
              <h3 class="text-2xl mb-3">eLearning PRO</h3>
            </a>
            <p>Lernen in Teams unterstützt durch einen "Personal Trainer" und virtuellem Assistenten.</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 px-4 mb-8">
          <div class="h-full p-6 bg-white rounded border-t-4 border-blue-150 shadow-md">
            <a href="https://www.leecon.de/" target="blank">
              <h3 class="text-2xl mb-3">Live Online Seminare</h3>
            </a>
            <p> Themen wie Service Management, Projektmanagement, Agiles Arbeiten und Hybride vermitteln dir unsere
              Trainer*innen gern in Live Online Seminaren (LOS). LOS geht's!</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 px-4 mb-8">
          <div class="h-full p-6 bg-white rounded border-t-4 border-blue-150 shadow-md">
            <a href="https://www.leecon.de/" target="blank">
              <h3 class="text-2xl mb-3">Zertifizierungen</h3>
            </a>
            <p>Stell dein erlerntes Wissen unter Beweis und lege eine Zertifizierungsprüfungen ab.
              Top Zertifizierungen in diesem Bereich sind u.A. ITIL®, ITIL® 4, PRINCE2® 6th Edition, PRINCE2 Agile®,
              Scrum, ISO und DevOps.</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 px-4 mb-8">
          <div class="h-full p-6 bg-white rounded border-t-4 border-blue-150 shadow-md">
            <a href="https://www.leecon.de/" target="blank">
              <h3 class="text-2xl mb-3">Consultants</h3>
            </a>
            <p>Ob individuelle Workshops oder Inhouse Consulting: Unsere Expert*innen mit Branchen- und Praxiserfahrung
              unterstützen dich gern im Alltag und bei speziellen Herausforderungen.</p>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
