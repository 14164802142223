<template>
  <Popover class="relative" v-slot="{ open, close }">
    <PopoverButton
      class="inline-flex items-center gap-x-1 text-blue-250 focus:outline-none">
      <span>Seminare</span>
      <ChevronDownIcon 
        class="h-5 w-5 transition-transform duration-300 ease-out" 
        :class="open ? 'rotate-180 transform' : ''"
        aria-hidden="true" />
    </PopoverButton>

    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
      <PopoverPanel class="absolute left-1/2 z-50 mt-3 flex w-screen max-w-min -translate-x-1/2 px-4">
        <div class="w-64 shrink overflow-hidden rounded-2xl bg-white py-3 shadow-xl ring-1 ring-gray-900/5 backdrop-blur-sm">
          <div class="px-3 pb-2 mb-2 border-b border-gray-100">
            <h3 class="text-sm font-semibold text-leeonDarkBlue">Wähle ein Thema</h3>
          </div>
          <div class="max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div v-for="(item, index) in subjects" :key="item" 
              @click="handleClick(item, close)"
              class="group relative mx-2 my-1 rounded-xl px-3 py-2.5 text-[14px] font-medium transition-all duration-200 hover:bg-gradient-to-r hover:from-gray-50 hover:to-white cursor-pointer"
              :class="[
                {'text-gray-500 hover:text-red-150': item === 'Alle Themen'},
                {'text-gray-700 hover:text-red-150': item !== 'Alle Themen'},
                {'animate-fadeIn': true}
              ]"
              :style="{ animationDelay: `${index * 50}ms` }">
              <div class="flex items-center justify-between">
                <span>{{ truncateText(item) }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 opacity-0 transition-opacity duration-200 group-hover:opacity-100 text-red-150" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { db } from "../firebaseinit";
import { collection, query, where, getDocs } from "firebase/firestore";

const router = useRouter();
const subjects = ref(['All Subjects']);

const fetchSubjects = async () => {
  const coursesQuery = query(
    collection(db, "courses"),
    where("hide", "==", false)
  );

  try {
    const coursesSnapshot = await getDocs(coursesQuery);
    const subjectSet = new Set();

    coursesSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.subject && data.partner === "a-leecon") {
        subjectSet.add(data.subject);
      }
    });

    subjects.value = ['Alle Themen', ...Array.from(subjectSet).sort()];
  } catch (error) {
    console.error("Error fetching subjects: ", error);
  }
};

const truncateText = (text) => {
  const maxLength = 29;
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const filterAndNavigate = (subject) => {
  if (subject === 'Alle Themen') {
    router.push('/katalog');
  } else {
    router.push({
      path: '/katalog',
      query: { subject: subject }
    });
  }
};

const handleClick = (subject, close) => {
  filterAndNavigate(subject);
  close();
};

onMounted(fetchSubjects);
</script>

<style>
.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 3px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out forwards;
}
</style>
