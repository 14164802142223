<template>
  <div class="w-full py-16 bg-gradient-to-b from-gray-50 to-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-16">
        <h2 class="text-4xl font-bold tracking-tight text-blue-250 sm:text-5xl">
          Was unsere Kunden sagen
        </h2>
        <div class="mt-4 flex justify-center">
          <p class="mt-3 text-xl text-gray-600 sm:mt-4 max-w-2xl">
            Bewertungen von Teilnehmern unserer Weiterbildungen
          </p>
        </div>
        <div class="mt-4 flex justify-center">
          <div class="h-1 w-24 bg-red-150 rounded-full"></div>
        </div>
      </div>

      <div class="mt-12">
        <div class="grid gap-8 lg:grid-cols-2">
          <div v-for="(testimonial, index) in testimonials" :key="index"
            class="group bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:scale-[1.02] border border-gray-100">
            <div class="p-8">
              <div class="flex items-center mb-6">
                <div class="flex-shrink-0">
                  <div class="relative">
                    <span class="inline-flex items-center justify-center h-16 w-16 rounded-full bg-gradient-to-r from-blue-250 to-red-150 group-hover:scale-110 transition-transform duration-300">
                      <span class="text-2xl font-medium text-white">{{ getInitials(testimonial.author) }}</span>
                    </span>
                    <span class="absolute -bottom-1 -right-1 h-5 w-5 rounded-full bg-red-150 border-2 border-white flex items-center justify-center">
                      <svg class="h-3 w-3 text-white" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="ml-6">
                  <h3 class="text-xl font-semibold text-gray-900 group-hover:text-blue-250 transition-colors duration-300">
                    {{ testimonial.author }}
                  </h3>
                  <p class="text-sm text-gray-500">verifizierte(r) Teilnehmer:in</p>
                </div>
              </div>
              <div class="relative">
                <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-250 opacity-25 group-hover:opacity-50 transition-opacity duration-300"
                  fill="currentColor" viewBox="0 0 32 32">
                  <path
                    d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <div class="relative text-gray-600 mt-4 text-lg" 
                  :class="{ 'line-clamp-6': !expandedTestimonials[index] }">
                  {{ testimonial.text }}
                </div>
                <button 
                  v-if="testimonial.text.length > 300"
                  @click="toggleTestimonial(index)"
                  class="mt-4 inline-flex items-center text-blue-250 hover:text-red-150 font-medium transition-all duration-300 group">
                  <span>{{ expandedTestimonials[index] ? 'Weniger anzeigen' : 'Mehr lesen' }}</span>
                  <svg 
                    class="ml-2 h-4 w-4 transform transition-transform duration-300"
                    :class="{ 'rotate-180': expandedTestimonials[index] }"
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const testimonials = [
  {
    author: 'Peter S.',
    text: 'Der Kurs "KI-Manager:in" und "KI-Consultant" sind inhaltlich und didaktisch toll gemacht. Das Team von A-LEECON hat hier den perfekten Ansatz gefunden: eine Mischung aus kurzen Audio/Video Tutorials, Live-Sessions mit menschlichen Expert:innen und vor allem der "Leeon" sind eine ideale Kombination bei diesem Thema. "Leeon" ist ein sehr gut trainierter Chatbot, den man (wie das bei Chatbots so ist) 24/7 mit Fragen zum Thema löchern kann. Die Antworten sind super-hilfreich. Das ist Lernen der Zukunft: individualisiert und inhaltlich hochwertig. Kann ich nur empfehlen'
  },
  {
    author: 'Cristina D.',
    text: 'Ich hatte eine fantastische Erfahrung mit A-LEECON. Das Team ist äußerst professionell und kompetent und liefert stets einen exzellenten Service mit viel Liebe zum Detail. Sie nehmen sich die Zeit, um die individuellen Bedürfnisse ihrer Kunden zu verstehen, und bieten maßgeschneiderte Lösungen, die die Erwartungen übertreffen. Besonders beeindruckt hat mich der innovative Ansatz, den Bereich Künstliche Intelligenz aktiv in die Kurse zu integrieren. Ich kann A-LEECON jedem empfehlen, der sich zukunftsorientiert weiterbilden möchte. Eine rundum positive Erfahrung!'
  },
  {
    author: 'Nina L.',
    text: 'Ich habe die Kurse "KI-Projektmanagerin" und "KI-Consultant" bei der A-Leecon absolviert und bin absolut begeistert. In den sechs Wochen der Weiterbildung habe ich unglaublich viel gelernt und konnte meine Zeit sowie mein Lernen vollkommen flexibel einteilen und organisieren. Der Kursinhalt war stets spannend und praxisnah gestaltet. Besonders gut gefallen hat mir die Mischung aus theoretischem Wissen und anwendungsbezogenen Aufgaben.'
  },
  {
    author: 'Karima C.',
    text: 'Herausragende KI-Weiterbildung bei A-LEECON: Von Anfänger zur Fachkraft in nur 3 Monaten. Als kompletter Neuling im Bereich Künstliche Intelligenz habe ich mich für die Kurse KI-Management, KI-Consultant und Programmieren mit KI bei A-LEECON entschieden. Was in den letzten drei Monaten geschah, übertraf alle meine Erwartungen.'
  }
];

// Initialize expanded state for each testimonial
const expandedTestimonials = ref(testimonials.map(() => false));

const toggleTestimonial = (index) => {
  expandedTestimonials.value[index] = !expandedTestimonials.value[index];
};

const getInitials = (name) => {
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase();
};
</script>

<style>
.line-clamp-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style> 