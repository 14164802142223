<template>
  <span 
    class="text-leeonBlue cursor-pointer hover:underline"
    @click="showCitation"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    [{{ id }}]
    <div 
      v-if="showTooltip" 
      class="absolute bg-white p-4 rounded-lg shadow-lg border border-gray-200 max-w-md z-50"
    >
      {{ citation.author }} ({{ citation.year }}): {{ citation.title }}
    </div>
  </span>
</template>

<script setup>
import { ref } from 'vue'
import { useCitationStore } from '../stores/citationStore'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const showTooltip = ref(false)
const citationStore = useCitationStore()
const citation = citationStore.getCitation(props.id)

const showCitation = () => {
  // Scroll to citations section
  document.getElementById('citations').scrollIntoView({ behavior: 'smooth' })
}
</script> 