<template>
  <div class="relative overflow-hidden">
    <!-- Background with gradient -->
    <div class="absolute inset-0 bg-gradient-to-br from-leeonBlue to-blue-250 opacity-95"></div>
    
    <!-- Decorative elements -->
    <div class="absolute top-0 right-0 -translate-y-12 translate-x-12 blur-3xl opacity-20">
      <div class="w-96 h-96 rounded-full bg-leeonPink"></div>
    </div>
    <div class="absolute bottom-0 left-0 translate-y-12 -translate-x-12 blur-3xl opacity-20">
      <div class="w-96 h-96 rounded-full bg-leeonPink"></div>
    </div>

    <div class="relative py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <!-- Section Title -->
        <div class="text-center mb-24">
          <h2 class="text-3xl font-bold text-white mb-4">
            Unsere Erfolge sprechen für sich
          </h2>
          <div class="w-20 h-1 bg-leeonPink mx-auto rounded-full"></div>
        </div>

        <!-- Stats Grid -->
        <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          <div v-for="stat in stats" 
               :key="stat.id" 
               class="mx-auto group hover:scale-105 transition-transform duration-300 cursor-default">
            <div class="relative pt-12">
              <!-- Icon -->
              <div class="absolute -top-8 left-1/2 -translate-x-1/2">
                <div class="w-16 h-16 rounded-full bg-leeonPink flex items-center justify-center">
                  <component 
                    :is="stat.icon" 
                    class="w-8 h-8 text-white" />
                </div>
              </div>
              
              <!-- Stat Value -->
              <dd class="pt-8 mb-4">
                <div class="text-5xl font-bold text-white mb-2">
                  {{ stat.value }}
                </div>
                <!-- Decorative line -->
                <div class="w-12 h-1 bg-leeonPink mx-auto rounded-full"></div>
              </dd>
              
              <!-- Stat Description -->
              <dt class="text-lg leading-7 text-white mt-4 max-w-xs mx-auto">
                {{ stat.name }}
              </dt>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { UserGroupIcon, ChartBarIcon, AcademicCapIcon } from '@heroicons/vue/24/outline'

const stats = [
  { 
    id: 1, 
    name: 'unserer Teilnehmer starten erfolgreich in einen neuen Job', 
    value: '88%',
    icon: UserGroupIcon
  },
  { 
    id: 2, 
    name: 'Weiterempfehlungsrate von zufriedenen Teilnehmern', 
    value: '90%',
    icon: ChartBarIcon
  },
  { 
    id: 3, 
    name: 'bestätigen die hohe Relevanz unserer Lerninhalte', 
    value: '100%',
    icon: AcademicCapIcon
  },
]
</script>
