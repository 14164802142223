<template>
  <div class="min-h-screen bg-gray-50">
    <SideNavigation />
    <main class="lg:pl-64 pb-12">
      <div class="py-8 px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between items-center mb-6">
          <h1 class="text-2xl font-bold">Statistiken</h1>
          <div class="flex gap-2">
            <button
              @click="exportCustomerData"
              class="px-4 py-2 bg-blue-250 text-white rounded hover:bg-blue-150 transition-colors flex items-center gap-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
              Kundendaten exportieren
            </button>
            <button
              @click="exportSeminarData"
              class="px-4 py-2 bg-blue-250 text-white rounded hover:bg-blue-150 transition-colors flex items-center gap-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
              Seminardaten exportieren
            </button>
            <button
              @click="exportCourseFeedback"
              class="px-4 py-2 bg-blue-250 text-white rounded hover:bg-blue-150 transition-colors flex items-center gap-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
              Kursfeedback exportieren
            </button>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-6">
          <div class="bg-white rounded-lg shadow p-4">
            <CourseFeedbackChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <TrainerFeedbackTrendChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <UserGrowthChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <LeadGrowthChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <CourseParticipantsChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <ActiveCustomersChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <CustomerStatusChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <CourseDistributionChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <CustomerCoursesChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <CustomerAgeChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <CustomerChannelChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <CustomerSourceChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <LeadCreationChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <LeadConversionChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <ConversionDateChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <GenderDistributionChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <CancellationReasonsChart />
          </div>

          <div class="bg-white rounded-lg shadow p-4">
            <LeeonChallengeChart />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { collection, getDocs } from 'firebase/firestore';
import { cp } from '../../firebaseinit';
import SideNavigation from '../../components/Admin/SideNavigation.vue';
import CustomerStatusChart from '../../components/Admin/charts/CustomerStatusChart.vue';
import CourseDistributionChart from '../../components/Admin/charts/CourseDistributionChart.vue';
import CustomerCoursesChart from '../../components/Admin/charts/CustomerCoursesChart.vue';
import CustomerAgeChart from '../../components/Admin/charts/CustomerAgeChart.vue';
import CustomerChannelChart from '../../components/Admin/charts/CustomerChannelChart.vue';
import CustomerSourceChart from '../../components/Admin/charts/CustomerSourceChart.vue';
import UserGrowthChart from '../../components/Admin/charts/UserGrowthChart.vue';
import ActiveCustomersChart from '../../components/Admin/charts/ActiveCustomersChart.vue';
import CourseParticipantsChart from '../../components/Admin/charts/CourseParticipantsChart.vue';
import LeadGrowthChart from '../../components/Admin/charts/LeadGrowthChart.vue';
import LeadConversionChart from '../../components/Admin/charts/LeadConversionChart.vue';
import LeadCreationChart from '../../components/Admin/charts/LeadCreationChart.vue';
import GenderDistributionChart from '../../components/Admin/charts/GenderDistributionChart.vue';
import CourseFeedbackChart from '../../components/Admin/charts/CourseFeedbackChart.vue';
import TrainerFeedbackTrendChart from '../../components/Admin/charts/TrainerFeedbackTrendChart.vue';
import CancellationReasonsChart from '../../components/Admin/charts/CancellationReasonsChart.vue';
import ConversionDateChart from '../../components/Admin/charts/ConversionDateChart.vue';
import LeeonChallengeChart from '../../components/Admin/charts/LeeonChallengeChart.vue';

// Helper function to parse dates
const parseDate = (dateValue) => {
  if (!dateValue) return null;
  if (typeof dateValue === 'string') {
    return new Date(dateValue);
  }
  if (dateValue.seconds) {
    return new Date(dateValue.seconds * 1000);
  }
  if (dateValue instanceof Date) {
    return dateValue;
  }
  return null;
};

// Helper function to format dates
const formatDate = (date) => {
  if (!date) return '';
  return date.toLocaleDateString('de-DE', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  });
};

// Helper function to escape CSV fields
const escapeCSV = (field) => {
  if (field === null || field === undefined) {
    return '';
  }
  field = field.toString().replace(/"/g, '""');
  return `"${field}"`;
};

const exportCustomerData = async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    const customerData = [];

    const excludedCourseIds = [
      "BfHGPoDdcIpDlWXmNjPZ",
      "l1DKt57j8FuR3dUR1fk6",
      "JiHhZsyMLB06ggMxkm0O"
    ];

    snapshot.forEach((doc) => {
      const customer = doc.data();
      const allTeamMateInfo = customer.TeamMateInfo || [];

      // Filter out excluded courses
      const teamMateInfo = allTeamMateInfo.filter(course =>
        !excludedCourseIds.includes(course.courseID) &&
        !course.name?.includes('Microsoft Teams')
      );

      // Get first and last course dates
      let firstSemStart = null;
      let lastSemEnd = null;

      teamMateInfo.forEach((info) => {
        const semStart = parseDate(info.semStart);
        const semEnd = parseDate(info.semEnd);

        if (semStart && (!firstSemStart || semStart < firstSemStart)) {
          firstSemStart = semStart;
        }
        if (semEnd && (!lastSemEnd || semEnd > lastSemEnd)) {
          lastSemEnd = semEnd;
        }
      });

      customerData.push({
        name: customer.name || '',
        surname: customer.surname || '',
        email: customer.email || '',
        status: customer.status || '',
        created: formatDate(parseDate(customer.created)),
        dateConverted: formatDate(parseDate(customer.dateConverted)) || '',
        source: customer.source || '',
        anrede: customer.anrede || '',
        firstSemStart: firstSemStart ? formatDate(firstSemStart) : '',
        lastSemEnd: lastSemEnd ? formatDate(lastSemEnd) : '',
        courseCount: teamMateInfo.length,
        phone: customer.phone || '',
        channel: customer.channel || ''
      });
    });

    // Generate CSV content
    const headers = [
      'Name',
      'Nachname',
      'Email',
      'Status',
      'Erstellt am',
      'Konversionsdatum',
      'Lead-Quelle',
      'Anrede',
      'Erster Kurs Start',
      'Letzter Kurs Ende',
      'Anzahl Kurse',
      'Telefon',
      'Kanal'
    ];

    const csvRows = [
      headers.join(';'),
      ...customerData.map(row => [
        escapeCSV(row.name),
        escapeCSV(row.surname),
        escapeCSV(row.email),
        escapeCSV(row.status),
        escapeCSV(row.created),
        escapeCSV(row.dateConverted),
        escapeCSV(row.source),
        escapeCSV(row.anrede),
        escapeCSV(row.firstSemStart),
        escapeCSV(row.lastSemEnd),
        escapeCSV(row.courseCount),
        escapeCSV(row.phone),
        escapeCSV(row.channel)
      ].join(';'))
    ];

    // Download CSV
    const csvContent = '\ufeff' + csvRows.join('\n'); // Add BOM for Excel
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', `customer_data_${formatDate(new Date())}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (error) {
    console.error('Error exporting customer data:', error);
    alert('Fehler beim Exportieren der Daten. Bitte versuchen Sie es später erneut.');
  }
};

const exportSeminarData = async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    const seminarData = [];

    const excludedCourseIds = [
      "BfHGPoDdcIpDlWXmNjPZ",
      "l1DKt57j8FuR3dUR1fk6",
      "JiHhZsyMLB06ggMxkm0O"
    ];

    snapshot.forEach((doc) => {
      const customer = doc.data();
      const teamMateInfo = customer.TeamMateInfo || [];

      // Filter out excluded courses and add each seminar as a row
      teamMateInfo
        .filter(course => !excludedCourseIds.includes(course.courseID))
        .forEach(course => {
          if (course.semStart) {
            seminarData.push({
              teilnehmer: `${customer.name} ${customer.surname}`,
              email: customer.email || '',
              seminar: course.name || '',
              startdatum: course.semStart ? formatDate(new Date(course.semStart)) : '',
              enddatum: course.semEnd ? formatDate(new Date(course.semEnd)) : ''
            });
          }
        });
    });

    // Sort by start date
    seminarData.sort((a, b) => {
      const dateA = a.startdatum ? new Date(a.startdatum.split('.').reverse().join('-')) : new Date(0);
      const dateB = b.startdatum ? new Date(b.startdatum.split('.').reverse().join('-')) : new Date(0);
      return dateA - dateB;
    });

    // Generate CSV content
    const headers = [
      'Teilnehmer',
      'Email',
      'Seminar',
      'Startdatum',
      'Enddatum'
    ];

    const csvRows = [
      headers.join(';'),
      ...seminarData.map(row => [
        escapeCSV(row.teilnehmer),
        escapeCSV(row.email),
        escapeCSV(row.seminar),
        escapeCSV(row.startdatum),
        escapeCSV(row.enddatum)
      ].join(';'))
    ];

    // Download CSV
    const csvContent = '\ufeff' + csvRows.join('\n'); // Add BOM for Excel
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', `seminar_data_${formatDate(new Date())}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (error) {
    console.error('Error exporting seminar data:', error);
    alert('Fehler beim Exportieren der Seminardaten. Bitte versuchen Sie es später erneut.');
  }
};

const exportCourseFeedback = async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    const feedbackData = [];

    // Helper function to translate feedback values to German
    const translateValue = (value) => {
      const translations = {
        'yes': 'ja',
        'no': 'nein',
        'partially': 'teilweise',
        'perfect': 'perfekt',
        'tooLong': 'zu lang',
        'tooShort': 'zu kurz',
        'satisfied': 'zufrieden',
        'very': 'sehr zufrieden',
        'not': 'nicht zufrieden',
        'noChange': 'keine Veränderung',
        'somewhat': 'etwas',
        'significantly': 'deutlich'
      };
      return translations[value] || value;
    };

    // Helper function to format date consistently
    const formatDateGerman = (dateStr) => {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      return date.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      });
    };

    snapshot.forEach((doc) => {
      const customer = doc.data();
      const courseFeedback = customer.courseFeedback || {};

      // Get TeamMateInfo for course names and dates
      const teamMateInfo = customer.TeamMateInfo || [];
      const courseInfoMap = {};
      teamMateInfo.forEach(course => {
        if (course.courseID) {
          // Get trainer information if available
          const trainerName = course.trainer ? 
            `${course.trainer.firstname || ''} ${course.trainer.name || ''}`.trim() : '';
          
          courseInfoMap[course.courseID] = {
            name: course.name || '',
            semStart: course.semStart ? formatDateGerman(course.semStart) : '',
            semEnd: course.semEnd ? formatDateGerman(course.semEnd) : '',
            trainer: trainerName
          };
        }
      });

      // Process each feedback entry
      Object.entries(courseFeedback).forEach(([courseId, feedback]) => {
        const courseInfo = courseInfoMap[courseId] || { name: courseId, semStart: '', semEnd: '', trainer: '' };

        const feedbackRow = {
          // Basic Information
          teilnehmer: `${customer.name || ''} ${customer.surname || ''}`.trim(),
          kurs: courseInfo.name,
          kurs_start: courseInfo.semStart,
          kurs_ende: courseInfo.semEnd,
          trainer: courseInfo.trainer,

          // Course Content and Organization
          inhalt_beschreibung_match: translateValue(feedback.organization?.contentMatchedDescription || ''),
          kursdauer: translateValue(feedback.organization?.courseDuration || ''),
          organisatorischer_support: translateValue(feedback.organization?.organizationalSupport || ''),
          tech_support_zufriedenheit: translateValue(feedback.organization?.technicalSupportSatisfaction || ''),

          // Career Development
          karriere_aussichten: translateValue(feedback.laborMarket?.careerProspects || ''),
          jobsuche_unterstuetzung: translateValue(feedback.laborMarket?.jobSearchSupport || ''),

          // Feedback
          kommentare: (feedback.additionalComments || '').replace(/[\n\r]+/g, ' ').trim()
        };

        feedbackData.push(feedbackRow);
      });
    });

    // Sort data by start date and then by participant name
    feedbackData.sort((a, b) => {
      if (a.kurs_start === b.kurs_start) {
        return a.teilnehmer.localeCompare(b.teilnehmer);
      }
      return b.kurs_start.localeCompare(a.kurs_start); // Newest first
    });

    // Generate CSV headers with logical grouping
    const headers = [
      // Group 1: Basic Information
      'Teilnehmer',
      'Kurs',
      'Kurs Start',
      'Kurs Ende',
      'Trainer',

      // Group 2: Course Content and Organization
      'Inhalt entspricht Beschreibung',
      'Kursdauer',
      'Organisatorischer Support',
      'Technischer Support',

      // Group 3: Career Development
      'Karriereaussichten',
      'Jobsuche-Unterstützung',

      // Group 4: Additional Information
      'Kommentare'
    ];

    const csvRows = [
      headers.join(';'),
      ...feedbackData.map(row => [
        // Group 1: Basic Information
        escapeCSV(row.teilnehmer),
        escapeCSV(row.kurs),
        escapeCSV(row.kurs_start),
        escapeCSV(row.kurs_ende),
        escapeCSV(row.trainer),

        // Group 2: Course Content and Organization
        escapeCSV(row.inhalt_beschreibung_match),
        escapeCSV(row.kursdauer),
        escapeCSV(row.organisatorischer_support),
        escapeCSV(row.tech_support_zufriedenheit),

        // Group 3: Career Development
        escapeCSV(row.karriere_aussichten),
        escapeCSV(row.jobsuche_unterstuetzung),

        // Group 4: Additional Information
        escapeCSV(row.kommentare)
      ].join(';'))
    ];

    // Download CSV
    const csvContent = '\ufeff' + csvRows.join('\n'); // Add BOM for Excel
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', `kursfeedback_${formatDateGerman(new Date())}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (error) {
    console.error('Error exporting course feedback:', error);
    alert('Fehler beim Exportieren der Kursfeedback-Daten. Bitte versuchen Sie es später erneut.');
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #0094da;
}

::-webkit-scrollbar-thumb:hover {
  background: #0094da;
}</style>
