import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { contentDBConfig, customerPortalConfig } from "./envConfig";

const FirebaseContentDB = initializeApp(contentDBConfig);
const FirebaseCustomerPortal = initializeApp(customerPortalConfig, "secondary");

const db = getFirestore(FirebaseContentDB);
const cp = getFirestore(FirebaseCustomerPortal);
const kursnetDB = getFirestore(FirebaseContentDB, "kursnet-feeder");

const auth = getAuth(FirebaseCustomerPortal);
const CPFunction = getFunctions(FirebaseCustomerPortal, "europe-west3");
const storage = getStorage(FirebaseCustomerPortal);

export const updates = collection(cp, "updates");

export {
  db,
  cp,
  CPFunction,
  storage,
  httpsCallable,
  kursnetDB,
  auth,
  FirebaseCustomerPortal,
  FirebaseContentDB,
  contentDBConfig,
  customerPortalConfig,
};
