<template>
  <div>
    <div class="flex flex-col md:flex-row justify-between items-start md:items-center mb-4 gap-4">
      <div class="flex items-center gap-2">
        <h3 class="text-lg font-semibold text-gray-800">Leeon Challenge-Annahmen</h3>
        <div class="relative group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-500 cursor-help"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
            <p class="mb-2">Diese Grafik zeigt Leeon Challenge-Annahmen:</p>
            <ul class="list-disc pl-4 space-y-1">
              <li>Zeigt angenommene Challenges pro Monat</li>
              <li>Kann als absolute Zahl oder Annahmerate angezeigt werden</li>
              <li>Kann für einzelne Challenge-Typen (Strategien) angezeigt werden</li>
              <li>Kann für alle Kurse oder einzelne Kurse angezeigt werden</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap gap-3">
        <!-- Display Type Dropdown -->
        <select
          v-model="displayType"
          class="rounded-md border border-gray-300 px-3 py-1.5 text-sm w-48"
        >
          <option value="count">Darstellung: Absolut</option>
          <option value="rate">Darstellung: Rate</option>
        </select>

        <!-- Strategy Dropdown -->
        <select
          v-model="selectedStrategy"
          class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
        >
          <option v-for="option in strategyOptions"
                  :key="option.value"
                  :value="option.value">
            Strategie: {{ option.label }}
          </option>
        </select>

        <!-- Aggregation Type Dropdown -->
        <select
          v-model="aggregationType"
          class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
        >
          <option value="all">Alle Kurse</option>
          <option value="individual" :disabled="coursesWithChallenges.length === 0">Einzelne Kurse</option>
        </select>

        <!-- Course Selection Dropdown (only visible when individual courses selected) -->
        <div v-if="aggregationType === 'individual'" class="relative">
          <button
            @click.stop="toggleCoursesDropdown"
            class="flex items-center gap-1 rounded-md border border-gray-300 px-3 py-1.5 text-sm"
            :disabled="coursesWithChallenges.length === 0"
          >
            Kurs-Auswahl
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          <div
            v-if="showCoursesDropdown && coursesWithChallenges.length > 0"
            class="absolute right-0 top-10 z-10 w-72 max-h-60 overflow-y-auto bg-white rounded-md shadow-lg border border-gray-200 courses-dropdown"
            @click.stop
          >
            <div class="p-2">
              <div class="mb-2 flex justify-between items-center">
                <span class="text-sm font-medium">Kurse</span>
                <button @click.stop="toggleAllCourses" class="text-xs text-blue-600 hover:text-blue-800 px-1">
                  {{ selectedCourses.length === coursesWithChallenges.length ? 'Alle abwählen' : 'Alle auswählen' }}
                </button>
              </div>
              <div class="space-y-1">
                <label
                  v-for="course in coursesWithChallenges"
                  :key="course.id"
                  class="flex items-start gap-2 text-sm cursor-pointer hover:bg-gray-50 p-1 rounded"
                >
                  <input
                    type="checkbox"
                    :id="`course-${course.id}`"
                    v-model="selectedCourses"
                    :value="course.id"
                    class="rounded border-gray-300 text-blue-600 focus:ring-blue-500 mt-0.5 flex-shrink-0"
                  />
                  <span class="break-words pr-1 w-full">{{ course.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <div v-if="isLoading" class="flex items-center justify-center h-full">
        <span class="text-gray-500">Lade Daten...</span>
      </div>
      <div v-else-if="hasError" class="flex flex-col items-center justify-center h-full">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-red-500 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
        <p class="text-gray-700 text-center">Fehler beim Laden der Daten</p>
        <p class="text-gray-500 text-sm text-center mt-1">{{ errorMessage }}</p>
        <button
          @click="retryLoading"
          class="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Erneut versuchen
        </button>
      </div>
      <div v-else-if="aggregationType === 'individual' && (coursesWithChallenges.length === 0 || selectedCourses.length === 0)" class="flex flex-col items-center justify-center h-full">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-gray-400 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p class="text-gray-700 text-center">{{ selectedCourses.length === 0 ? 'Keine Kurse ausgewählt' : 'Keine Kurse mit Leeon Challenges gefunden' }}</p>
        <p class="text-gray-500 text-sm text-center mt-1">
          {{ selectedCourses.length === 0 ? 'Bitte wählen Sie mindestens einen Kurs aus der Kurs-Auswahl.' : 'Es wurden noch keine Leeon Challenges in den Kursen erstellt.' }}
        </p>
      </div>
      <canvas v-else ref="chartCanvas"></canvas>
    </div>

    <!-- Legend for individual courses -->
    <div v-if="aggregationType === 'individual' && !isLoading && !hasError && coursesWithChallenges.length > 0" class="mt-4 flex flex-wrap gap-4 justify-center">
      <div
        v-for="(course, index) in filteredCourses"
        :key="course.id"
        class="flex items-center cursor-pointer hover:bg-gray-100 px-2 py-1 rounded transition-colors"
        @mouseenter="highlightCourse(index)"
        @mouseleave="resetHighlight()"
      >
        <span
          class="inline-block w-4 h-4 mr-2 rounded-full"
          :style="{ backgroundColor: getColorForIndex(index) }"
        ></span>
        <span class="text-sm">{{ course.name }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onUnmounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import Chart from 'chart.js/auto';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, cp } from '../../../firebaseinit';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const store = useStore();
const chartCanvas = ref(null);
const chart = ref(null);
const chartData = ref(null);
const displayType = ref('count');
const aggregationType = ref('all');
const selectedCourses = ref([]);
const selectedStrategy = ref('all');
const isLoading = ref(true);
const hasError = ref(false);
const errorMessage = ref('');
const showCoursesDropdown = ref(false);
const availableCoursesData = ref([]);
const challengeDataCache = ref(null);

// Get all available courses
const availableCourses = computed(() => {
  return availableCoursesData.value;
});

// Filtered courses based on selection
const filteredCourses = computed(() => {
  return availableCourses.value.filter(course => selectedCourses.value.includes(course.id));
});

// Toggle courses dropdown
const toggleCoursesDropdown = () => {
  showCoursesDropdown.value = !showCoursesDropdown.value;

  // If we're opening the dropdown, make sure we have courses to show
  if (showCoursesDropdown.value) {
    // If no courses with challenges are available, log a warning
    if (coursesWithChallenges.value.length === 0) {
      console.warn('No courses with challenges available to display in dropdown');
    } else {
      console.log('Available courses with challenges:', coursesWithChallenges.value.length);
    }

    // Force a DOM update to ensure the dropdown is rendered
    nextTick(() => {
      const dropdown = document.querySelector('.courses-dropdown');
      if (!dropdown) {
        console.warn('Dropdown element not found in DOM after toggling');
      } else {
        console.log('Dropdown element found in DOM after toggling');
      }
    });
  }
};

// Toggle all courses selection
const toggleAllCourses = () => {
  // If all courses are currently selected, deselect them all
  if (selectedCourses.value.length === coursesWithChallenges.value.length) {
    // Clear selected courses
    selectedCourses.value = [];
  } else {
    // Otherwise, select all courses with challenges
    selectedCourses.value = coursesWithChallenges.value.map(course => course.id);
  }
};

// Courses that have at least one challenge
const coursesWithChallenges = computed(() => {
  if (!challengeDataCache.value) return [];

  // Get unique course IDs from challenge data
  const courseIdsWithChallenges = new Set();
  challengeDataCache.value.forEach(challenge => {
    if (challenge.courseId) {
      courseIdsWithChallenges.add(challenge.courseId);
    }
  });

  // Filter available courses to only include those with challenges
  return availableCourses.value.filter(course =>
    courseIdsWithChallenges.has(course.id)
  );
});

// Watch for changes in aggregationType to select all courses by default when switching to individual
watch(aggregationType, (newValue, oldValue) => {
  if (newValue === 'individual' && challengeDataCache.value) {
    // Select all courses with challenges by default when switching to individual view
    // Use a direct assignment instead of modifying the array to avoid reactivity issues
    selectedCourses.value = [...coursesWithChallenges.value.map(course => course.id)];

    // We'll handle chart updates in the main watcher function
    // Don't try to update the chart here to avoid reactivity issues
  }
});

// Get color for chart based on index
const getColorForIndex = (index) => {
  const colors = [
    { borderColor: 'rgb(59, 130, 246)', backgroundColor: 'rgba(59, 130, 246, 0.5)' },  // Blue
    { borderColor: 'rgb(16, 185, 129)', backgroundColor: 'rgba(16, 185, 129, 0.5)' },  // Green
    { borderColor: 'rgb(249, 115, 22)', backgroundColor: 'rgba(249, 115, 22, 0.5)' },  // Orange
    { borderColor: 'rgb(236, 72, 153)', backgroundColor: 'rgba(236, 72, 153, 0.5)' },  // Pink
    { borderColor: 'rgb(139, 92, 246)', backgroundColor: 'rgba(139, 92, 246, 0.5)' },  // Purple
    { borderColor: 'rgb(234, 179, 8)', backgroundColor: 'rgba(234, 179, 8, 0.5)' },    // Yellow
    { borderColor: 'rgb(239, 68, 68)', backgroundColor: 'rgba(239, 68, 68, 0.5)' },    // Red
    { borderColor: 'rgb(20, 184, 166)', backgroundColor: 'rgba(20, 184, 166, 0.5)' },  // Teal
    { borderColor: 'rgb(217, 119, 6)', backgroundColor: 'rgba(217, 119, 6, 0.5)' },    // Amber
    { borderColor: 'rgb(124, 58, 237)', backgroundColor: 'rgba(124, 58, 237, 0.5)' },  // Violet
  ];

  return colors[index % colors.length].borderColor;
};

// Process challenge data for the chart
const processData = (challengeData) => {
  if (!challengeData || challengeData.length === 0) {
    console.warn('No challenge data to process');
    hasError.value = true;
    errorMessage.value = 'Keine Challenge-Daten verfügbar';
    return;
  }

  // Filter data by selected strategy if not 'all'
  const filteredData = selectedStrategy.value === 'all'
    ? challengeData
    : challengeData.filter(challenge =>
        challenge.strategyIndex !== undefined &&
        challenge.strategyIndex !== null &&
        challenge.strategyIndex.toString() === selectedStrategy.value
      );

  // Check if we have any data after filtering
  if (filteredData.length === 0) {
    console.warn('No data available for selected filters');
    hasError.value = true;
    const strategyName = strategyOptions.find(opt => opt.value === selectedStrategy.value)?.label;
    errorMessage.value = `Keine Daten verfügbar für ${strategyName}`;
    chartData.value = null;
    return;
  }

  // Group challenges by month and course
  const challengesByMonthAndCourse = {};

  filteredData.forEach(challenge => {
    if (!challenge.courseId) {
      console.warn('Challenge missing courseId:', challenge);
      return;
    }

    // Format date to YYYY-MM
    const date = challenge.timestamp instanceof Date ? challenge.timestamp : new Date(challenge.timestamp);
    const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

    // Initialize month if not exists
    if (!challengesByMonthAndCourse[month]) {
      challengesByMonthAndCourse[month] = {};
    }

    // Initialize course if not exists
    if (!challengesByMonthAndCourse[month][challenge.courseId]) {
      challengesByMonthAndCourse[month][challenge.courseId] = {
        accepted: 0,
        created: 0
      };
    }

    // Count all challenges created
    challengesByMonthAndCourse[month][challenge.courseId].created++;

    // Only count accepted challenges
    if (challenge.accepted) {
      challengesByMonthAndCourse[month][challenge.courseId].accepted++;
    }
  });

  // Sort months chronologically
  const sortedMonths = Object.keys(challengesByMonthAndCourse).sort();

  if (sortedMonths.length === 0) {
    console.warn('No months with challenge data found');
    return;
  }

  // Format month labels for display (YYYY-MM to MM/YYYY)
  const labels = sortedMonths.map(month => {
    const [year, monthNum] = month.split('-');
    return new Date(year, monthNum - 1).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'short'
    });
  });

  let datasets = [];

  if (aggregationType.value === 'all') {
    // Single dataset for all courses
    const data = sortedMonths.map(month => {
      const monthData = challengesByMonthAndCourse[month];
      let totalAccepted = 0;
      let totalCreated = 0;

      Object.values(monthData).forEach(courseStats => {
        totalAccepted += courseStats.accepted;
        totalCreated += courseStats.created;
      });

      if (displayType.value === 'count') {
        return totalAccepted;
      } else {
        // Calculate acceptance rate
        return totalCreated > 0 ? (totalAccepted / totalCreated) * 100 : 0;
      }
    });

    datasets = [{
      label: displayType.value === 'count' ? 'Angenommene Challenges' : 'Annahmerate (%)',
      data,
      backgroundColor: 'rgba(59, 130, 246, 0.5)',
      borderColor: 'rgb(59, 130, 246)',
      borderWidth: 2,
      fill: true,
      tension: 0.1,
      order: 1, // Lower order means it's drawn on top
      pointRadius: 5,
      pointHoverRadius: 6,
      pointBorderWidth: 2,
      pointBackgroundColor: 'rgb(59, 130, 246)',
      pointBorderColor: 'white'
    }];
  } else {
    // Multiple datasets, one per course
    // Filter to only include selected courses
    selectedCourses.value.forEach((courseId, index) => {
      const courseName = availableCourses.value.find(c => c.id === courseId)?.name || courseId;
      const color = getColorForIndex(index);

      const data = sortedMonths.map(month => {
        const courseStats = challengesByMonthAndCourse[month][courseId];

        if (!courseStats) {
          return 0;
        }

        if (displayType.value === 'count') {
          return courseStats.accepted;
        } else {
          // Calculate acceptance rate
          return courseStats.created > 0 ? (courseStats.accepted / courseStats.created) * 100 : 0;
        }
      });

      datasets.push({
        label: courseName,
        data,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 2,
        fill: false,
        tension: 0.1,
        order: 1, // Lower order means it's drawn on top
        pointRadius: 5,
        pointHoverRadius: 6,
        pointBorderWidth: 2,
        pointBackgroundColor: color,
        pointBorderColor: 'white'
      });
    });
  }

  // Set chart data
  chartData.value = {
    labels,
    datasets
  };
};

// Fetch data and initialize chart on mount
onMounted(async () => {
  // Add click outside listener
  document.addEventListener('click', handleClickOutside);

  // First ensure the component is fully mounted
  await nextTick();

  // Check if we're in individual mode and initialize dropdown state
  if (aggregationType.value === 'individual') {
    // Make sure dropdown is initially closed
    showCoursesDropdown.value = false;
  }

  // Use a longer delay to ensure DOM is fully rendered
  setTimeout(async () => {
    try {
      // First ensure the component is fully mounted and visible
      await ensureComponentMounted();

      // Then load data and initialize chart
      await loadData();

      // After data is loaded, check if dropdown elements are properly initialized
      if (aggregationType.value === 'individual') {
        const dropdownButton = document.querySelector('.relative button');
        if (!dropdownButton) {
          console.warn('Dropdown button not found after component mounted');
        } else {
          // Add an additional click event listener directly to the button
          dropdownButton.addEventListener('click', (event) => {
            event.stopPropagation();
            toggleCoursesDropdown();
          });
        }
      }
    } catch (error) {
      console.error('Error initializing Leeon Challenge chart:', error);
      // Error is already handled in loadData
    }
  }, 500); // Increased timeout to ensure DOM is ready
});

// Function to ensure the component is fully mounted and visible
const ensureComponentMounted = async () => {
  // Wait for next tick to ensure initial rendering
  await nextTick();

  // Check if the component container is visible and has dimensions
  return new Promise((resolve) => {
    const checkVisibility = () => {
      const container = document.querySelector('.h-\\[400px\\]');
      if (container && container.offsetHeight > 0 && container.offsetWidth > 0) {
        resolve();
      } else {
        console.log('Waiting for chart container to be visible...');
        setTimeout(checkVisibility, 100);
      }
    };

    checkVisibility();
  });
};

// Add these new refs for tracking highlighted course
const highlightedCourseIndex = ref(null);
const tooltipTimeoutId = ref(null);

// Store original styles to restore them later
const originalStyles = ref({
  borderWidth: 2,
  pointRadius: 4,
  pointHoverRadius: 6
});

// Completely rewrite the highlight and reset functions to avoid recursion issues
const highlightCourse = (index) => {
  if (!chart.value || aggregationType.value !== 'individual') return;

  try {
    // Set the highlighted index
    highlightedCourseIndex.value = index;

    // Clear any existing timeout
    if (tooltipTimeoutId.value) {
      clearTimeout(tooltipTimeoutId.value);
      tooltipTimeoutId.value = null;
    }

    // Get a non-reactive reference to the chart
    const chartInstance = chart.value;

    // Apply visual changes directly to the DOM elements
    for (let i = 0; i < chartInstance.data.datasets.length; i++) {
      try {
        const meta = chartInstance.getDatasetMeta(i);
        if (!meta || meta.hidden) continue;

        // Determine if this is the highlighted dataset
        const isHighlighted = i === index;

        // Apply styles to the line
        if (meta.dataset) {
          meta.dataset.options = meta.dataset.options || {};
          meta.dataset.options.borderWidth = isHighlighted ? 3 : 1;
          meta.dataset.options.borderColor = isHighlighted
            ? getColorForIndex(i)
            : `${getColorForIndex(i)}80`; // Add transparency to non-highlighted
        }

        // Apply styles to the points
        if (meta.data) {
          meta.data.forEach(point => {
            if (point && point.options) {
              point.options.radius = isHighlighted ? 6 : 2;
              point.options.hoverRadius = isHighlighted ? 8 : 3;
              point.options.borderWidth = isHighlighted ? 2 : 1;
            }
          });
        }
      } catch (err) {
        console.error(`Error processing dataset ${i}:`, err);
      }
    }

    // Force a redraw without triggering a full update
    chartInstance.draw();

    // Find the first non-zero data point of the highlighted dataset
    const dataset = chartInstance.data.datasets[index];
    if (dataset && dataset.data) {
      const pointIndex = dataset.data.findIndex(value => value > 0);
      const finalPointIndex = pointIndex >= 0 ? pointIndex : 0;

      // Use a timeout to ensure the chart has updated before showing tooltip
      tooltipTimeoutId.value = setTimeout(() => {
        try {
          if (!chartInstance || !chartInstance.getDatasetMeta) return;

          const meta = chartInstance.getDatasetMeta(index);
          if (!meta || !meta.data || !meta.data[finalPointIndex]) return;

          // Get the point coordinates
          const point = meta.data[finalPointIndex];
          if (!point || typeof point.x === 'undefined' || typeof point.y === 'undefined') return;

          // Instead of using the tooltip API directly, manually draw a custom tooltip
          // This avoids the reactivity issues with Chart.js's tooltip system
          const ctx = chartInstance.ctx;
          if (!ctx) return;

          // Draw a custom tooltip
          ctx.save();

          // Get the label for this point
          const label = chartInstance.data.labels[finalPointIndex];
          const value = dataset.data[finalPointIndex];
          const courseName = dataset.label;

          // Format the value based on display type
          const formattedValue = displayType.value === 'rate'
            ? value.toFixed(1) + '%'
            : value.toString();

          // Draw tooltip background
          ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
          ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)';

          // Position the tooltip near the point
          const tooltipX = point.x + 10;
          const tooltipY = point.y - 40;

          // Draw tooltip text
          ctx.font = 'bold 12px Arial';
          ctx.fillStyle = 'white';
          ctx.textAlign = 'left';

          // Force a redraw without triggering a full update
          chartInstance.draw();
        } catch (error) {
          console.error('Error showing custom tooltip:', error);
        }
      }, 50);
    }
  } catch (error) {
    console.error('Error in highlightCourse:', error);
  }
};

const resetHighlight = () => {
  if (!chart.value || aggregationType.value !== 'individual') return;

  try {
    // Clear the highlighted index
    highlightedCourseIndex.value = null;

    // Clear any active tooltips
    if (tooltipTimeoutId.value) {
      clearTimeout(tooltipTimeoutId.value);
      tooltipTimeoutId.value = null;
    }

    // Get a non-reactive reference to the chart
    const chartInstance = chart.value;
    if (!chartInstance) return;

    // Reset all datasets to original appearance
    for (let i = 0; i < chartInstance.data.datasets.length; i++) {
      try {
        const meta = chartInstance.getDatasetMeta(i);
        if (!meta || meta.hidden) continue;

        // Reset line style
        if (meta.dataset) {
          meta.dataset.options = meta.dataset.options || {};
          meta.dataset.options.borderWidth = originalStyles.value.borderWidth;
          meta.dataset.options.borderColor = getColorForIndex(i);
        }

        // Reset point styles
        if (meta.data) {
          meta.data.forEach(point => {
            if (point && point.options) {
              point.options.radius = originalStyles.value.pointRadius;
              point.options.hoverRadius = originalStyles.value.pointHoverRadius;
              point.options.borderWidth = originalStyles.value.borderWidth;
            }
          });
        }
      } catch (err) {
        console.error(`Error resetting dataset ${i}:`, err);
      }
    }

    // Force a redraw without triggering a full update
    chartInstance.draw();
  } catch (error) {
    console.error('Error in resetHighlight:', error);
  }
};

// Modify the initChartImplementation function to ensure tooltips work properly
const initChartImplementation = async () => {
  // Destroy existing chart if it exists
  if (chart.value) {
    try {
      chart.value.destroy();
    } catch (error) {
      console.error('Error destroying existing chart:', error);
    }
    chart.value = null;
  }

  // Ensure canvas element exists
  if (!chartCanvas.value) {
    throw new Error('Chart canvas element not found');
  }

  // Get the canvas context
  const ctx = chartCanvas.value.getContext('2d');

  if (!ctx) {
    throw new Error('Could not get canvas context');
  }

  // Check if we have valid chart data
  if (!chartData.value || !chartData.value.datasets || chartData.value.datasets.length === 0) {
    console.warn('No valid data available for Leeon Challenge Chart');
    throw new Error('Keine gültigen Daten für Leeon Challenge Chart verfügbar');
  }

  console.log('Creating chart with data:', chartData.value);

  // Determine chart title based on display options
  let chartTitle = '';
  if (displayType.value === 'count') {
    chartTitle = 'Angenommene Challenges pro Monat';
  } else {
    chartTitle = 'Annahmerate der Challenges pro Monat (%)';
  }

  if (aggregationType.value === 'individual') {
    chartTitle += ' nach Kurs';
  }

  // Create a deep clone of the chart data to avoid reactivity issues
  const clonedData = JSON.parse(JSON.stringify(chartData.value));

  // Create new chart with non-reactive data
  chart.value = new Chart(ctx, {
    type: 'line', // Use line chart for time series data
    data: clonedData,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      // Ensure data points are drawn on top of everything else
      elements: {
        point: {
          radius: 4,
          hoverRadius: 6,
          borderWidth: 2
        },
        line: {
          tension: 0.1,
          borderWidth: 2
        }
      },
      layout: {
        padding: {
          top: 10 // Add padding to the top of the chart area
        }
      },
      interaction: {
        mode: 'nearest',
        intersect: true,
        axis: 'xy'
      },
      plugins: {
        legend: {
          display: false, // Hide the top legend completely
          position: 'top',
          labels: {
            font: {
              size: 12
            }
          }
        },
        title: {
          display: true,
          text: chartTitle,
          font: {
            size: 16
          }
        },
        tooltip: {
          enabled: true,
          position: 'nearest',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleFont: {
            size: 14,
            weight: 'bold'
          },
          bodyFont: {
            size: 13
          },
          padding: 10,
          displayColors: true,
          callbacks: {
            title: function(tooltipItems) {
              return tooltipItems[0].label;
            },
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }

              // Format the value based on display type
              if (displayType.value === 'rate') {
                label += context.raw.toFixed(1) + '%';
              } else {
                label += context.raw;
              }

              return label;
            }
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          suggestedMax: displayType.value === 'rate' ? 100 : undefined,
          max: displayType.value === 'rate' ? 100 : undefined,
          ticks: {
            callback: function(value) {
              if (displayType.value === 'rate') {
                return value + '%';
              }
              return value;
            }
          },
          title: {
            display: true,
            text: displayType.value === 'count' ? 'Anzahl' : 'Annahmerate (%)'
          },
          // Add padding to ensure data points are fully visible
          afterFit: function(scaleInstance) {
            scaleInstance.paddingTop = 20; // Increase padding to ensure points are visible
          },
          grid: {
            drawBorder: true,
            z: 0 // Lower z-index for grid lines
          }
        },
        x: {
          title: {
            display: true,
            text: 'Monat'
          },
          ticks: {
            autoSkip: false,
            maxRotation: 45,
            minRotation: 45
          },
          grid: {
            z: 0 // Lower z-index for grid lines
          }
        }
      },
      // Enable events for all modes, not just individual mode
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove']
    },
    plugins: [{
      id: 'customDrawOrder',
      afterDraw: (chart) => {
        // Force redraw of datasets to ensure they're on top
        chart.data.datasets.forEach((dataset, i) => {
          const meta = chart.getDatasetMeta(i);
          // Only redraw if the dataset is visible
          if (!meta.hidden) {
            chart.ctx.save();
            // Draw points on top of everything
            meta.data.forEach((point) => {
              point.draw(chart.ctx);
            });
            chart.ctx.restore();
          }
        });
      }
    }]
  });

  // Store original styles for later restoration
  originalStyles.value = {
    borderWidth: 2,
    pointRadius: 4,
    pointHoverRadius: 6
  };

  console.log('Chart successfully initialized');
};

// Initialize chart with improved canvas detection
const initChart = async () => {
  try {
    // Wait for the next DOM update so the canvas is rendered
    await nextTick();

    // Add a small delay to ensure DOM is fully rendered
    await new Promise(resolve => setTimeout(resolve, 100));

    // Check if canvas element exists and is visible in the DOM
    if (!chartCanvas.value || !document.body.contains(chartCanvas.value)) {
      console.warn('Chart canvas element not found or not in DOM, waiting for DOM update...');

      // Try again after a longer delay
      return new Promise((resolve) => {
        setTimeout(async () => {
          // Force a DOM update and check again
          await nextTick();

          if (!chartCanvas.value || !document.body.contains(chartCanvas.value)) {
            console.error('Chart canvas element still not found after retry');
            hasError.value = true;
            errorMessage.value = 'Canvas-Element nicht gefunden. Bitte Seite neu laden.';
            isLoading.value = false;
            resolve();
            return;
          }

          // Try initialization again
          try {
            await initChartImplementation();
            resolve();
          } catch (error) {
            console.error('Error in chart initialization retry:', error);
            hasError.value = true;
            errorMessage.value = error.message || 'Fehler beim Initialisieren des Charts';
            isLoading.value = false;
            resolve();
          }
        }, 500); // Increased timeout for retry
      });
    }

    await initChartImplementation();
  } catch (error) {
    console.error('Error initializing chart:', error);
    hasError.value = true;
    errorMessage.value = error.message || 'Fehler beim Initialisieren des Charts';
    isLoading.value = false;
  }
};

// Retry loading data
const retryLoading = async () => {
  isLoading.value = true;
  hasError.value = false;
  errorMessage.value = '';

  try {
    // Reset to 'all' mode to avoid recursive updates when in individual mode with no selected courses
    if (aggregationType.value === 'individual' && selectedCourses.value.length === 0) {
      aggregationType.value = 'all';
    }

    // Load fresh data
    await loadCoursesDirectly();
    const challengeData = await loadChallengeDataDirectly();

    if (!challengeData || challengeData.length === 0) {
      console.warn('No challenge data found');
      hasError.value = true;
      errorMessage.value = 'Keine Challenge-Daten gefunden';
      isLoading.value = false;
      return;
    }

    // Process data directly without triggering watchers
    processData(challengeData);

    // Check if chart data was successfully created
    if (!chartData.value || !chartData.value.datasets || chartData.value.datasets.length === 0) {
      console.warn('No data available for Leeon Challenge Chart');
      hasError.value = true;
      errorMessage.value = 'Keine Daten für Leeon Challenge Chart verfügbar';
      isLoading.value = false;
      return;
    }

    // Make sure we're not in loading state before initializing chart
    isLoading.value = false;

    // Wait for the DOM to update
    await nextTick();

    // Initialize the chart
    await initChart();
  } catch (error) {
    console.error('Error retrying data load:', error);
    hasError.value = true;
    errorMessage.value = error.message || 'Fehler beim Laden der Daten';
    isLoading.value = false;
  }
};

// Function to directly load courses from Firestore using Firebase v9 syntax
const loadCoursesDirectly = async () => {
  try {
    // Use Firebase v9 syntax to get collection
    const coursesCollection = collection(db, 'courses');
    const coursesSnapshot = await getDocs(coursesCollection);

    if (coursesSnapshot.empty) {
      console.warn('No courses found');
      return [];
    }

    // Process the data
    const coursesData = [];
    coursesSnapshot.forEach(doc => {
      const course = {
        id: doc.id,
        ...doc.data()
      };
      coursesData.push(course);
    });

    console.log(`Successfully loaded ${coursesData.length} courses`);

    // Update local ref instead of committing to store
    availableCoursesData.value = coursesData;

    return coursesData;
  } catch (error) {
    console.error('Error loading courses:', error);
    throw error;
  }
};

// Load challenge data directly from Firestore
const loadChallengeDataDirectly = async () => {
  try {
    const challengesCollection = collection(cp, 'leeonChallengeStats');
    const challengesSnapshot = await getDocs(challengesCollection);

    if (challengesSnapshot.empty) {
      console.warn('No challenge data found');
      return [];
    }

    // Process the data
    const challengeData = [];
    challengesSnapshot.forEach(doc => {
      const challenge = {
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      };
      challengeData.push(challenge);
    });

    console.log(`Successfully loaded ${challengeData.length} challenges`);

    // Store challenge data in cache for filtering courses
    challengeDataCache.value = challengeData;

    return challengeData;
  } catch (error) {
    console.error('Error loading challenge data:', error);
    throw error;
  }
};

// Load data for the chart - simplified to use direct Firestore access
const loadData = async () => {
  isLoading.value = true;
  hasError.value = false;
  errorMessage.value = '';

  try {
    // Load courses directly from Firestore
    await loadCoursesDirectly();

    // Load challenge data directly from Firestore
    const challengeData = await loadChallengeDataDirectly();

    if (!challengeData || challengeData.length === 0) {
      console.warn('No challenge data found');
      hasError.value = true;
      errorMessage.value = 'Keine Challenge-Daten gefunden';
      isLoading.value = false;
      return;
    }

    console.log('Challenge data loaded successfully:', challengeData.length, 'records');

    // Check if any challenges are accepted
    const acceptedChallenges = challengeData.filter(challenge => challenge.accepted);
    console.log('Accepted challenges:', acceptedChallenges.length);

    if (acceptedChallenges.length === 0) {
      console.warn('No accepted challenges found');
      // We'll still process the data, but log a warning
    }

    // If we're in individual mode, make sure we're only showing courses with challenges
    if (aggregationType.value === 'individual') {
      // Get valid course IDs
      const validCourseIds = coursesWithChallenges.value.map(course => course.id);

      // If there are no courses with challenges, switch to 'all' mode
      if (validCourseIds.length === 0) {
        console.warn('No courses with challenges found, switching to all mode');
        aggregationType.value = 'all';
      } else {
        // Otherwise, update selected courses
        selectedCourses.value = validCourseIds;
      }
    }

    // Process and prepare data for chart
    processData(challengeData);

    // Check if chart data was successfully created
    if (!chartData.value || !chartData.value.datasets || chartData.value.datasets.length === 0) {
      console.warn('No data available for Leeon Challenge Chart');
      hasError.value = true;
      errorMessage.value = 'Keine Daten für Leeon Challenge Chart verfügbar';
      isLoading.value = false;
      return;
    }

    // Make sure we're not in loading state before initializing chart
    // This ensures the canvas element is visible in the DOM
    isLoading.value = false;

    // Wait for the DOM to update after changing isLoading
    await nextTick();

    // Initialize the chart
    await initChart();
  } catch (error) {
    console.error('Error loading data:', error);
    hasError.value = true;

    // Provide more specific error messages based on error type
    if (error.code === 'permission-denied') {
      errorMessage.value = 'Keine Berechtigung für den Zugriff auf die Daten';
    } else {
      errorMessage.value = error.message || 'Fehler beim Laden der Daten';
    }

    isLoading.value = false;
  }
};

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
  // If dropdown is not shown, no need to check
  if (!showCoursesDropdown.value) return;

  // Get all dropdown buttons and content elements
  const dropdownButtons = document.querySelectorAll('.relative button');
  const dropdowns = document.querySelectorAll('.courses-dropdown');

  // Check if click is inside any dropdown or button
  let isClickInsideDropdown = false;

  // Check dropdown contents
  dropdowns.forEach(dropdown => {
    if (dropdown && dropdown.contains(event.target)) {
      isClickInsideDropdown = true;
    }
  });

  // Check dropdown buttons
  dropdownButtons.forEach(button => {
    if (button && button.contains(event.target)) {
      isClickInsideDropdown = true;
    }
  });

  // If click is outside both dropdown and buttons, close the dropdown
  if (!isClickInsideDropdown) {
    showCoursesDropdown.value = false;
  }
};

// Clean up event listener
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);

  // Clear any pending timeouts
  if (tooltipTimeoutId.value) {
    clearTimeout(tooltipTimeoutId.value);
    tooltipTimeoutId.value = null;
  }

  // Destroy chart if it exists
  if (chart.value) {
    chart.value.destroy();
    chart.value = null;
  }
});

// Update the watch function to reset highlight when display options change
watch([displayType, aggregationType, selectedCourses, selectedStrategy], async (newValues, oldValues) => {
  // Reset highlight when changing display options
  highlightedCourseIndex.value = null;

  // Clear any tooltip timeouts
  if (tooltipTimeoutId.value) {
    clearTimeout(tooltipTimeoutId.value);
    tooltipTimeoutId.value = null;
  }

  // Skip if we're just initializing
  if (!challengeDataCache.value) {
    return;
  }

  isLoading.value = true;
  hasError.value = false;
  errorMessage.value = '';

  try {
    // If we're in individual mode with no selected courses, show appropriate message
    if (aggregationType.value === 'individual' && selectedCourses.value.length === 0) {
      console.log('No courses selected in individual mode');
      hasError.value = true;
      errorMessage.value = 'Keine Kurse ausgewählt. Bitte wählen Sie mindestens einen Kurs aus.';
      isLoading.value = false;

      // Clear the chart if it exists
      if (chart.value) {
        chart.value.destroy();
        chart.value = null;
      }
      return;
    }

    // Use cached challenge data
    const challengeData = challengeDataCache.value;

    // Process data - this will now handle the case of no data for selected filters
    processData(challengeData);

    // If processData set hasError, we should stop here
    if (hasError.value) {
      isLoading.value = false;

      // Clear the chart if it exists
      if (chart.value) {
        chart.value.destroy();
        chart.value = null;
      }
      return;
    }

    // Check if chart data was successfully created
    if (!chartData.value || !chartData.value.datasets || chartData.value.datasets.length === 0) {
      console.warn('No data found for Leeon Challenge Chart');
      hasError.value = true;
      errorMessage.value = 'Keine Daten für Leeon Challenge Chart gefunden';
      isLoading.value = false;

      // Clear the chart if it exists
      if (chart.value) {
        chart.value.destroy();
        chart.value = null;
      }
      return;
    }

    // Set loading to false before initializing chart
    isLoading.value = false;

    // Wait for DOM update
    await nextTick();

    // Initialize the chart
    await initChart();
  } catch (error) {
    console.error('Error updating chart:', error);
    hasError.value = true;
    errorMessage.value = error.message || 'Fehler beim Aktualisieren des Charts';
    isLoading.value = false;

    // Clear the chart if it exists
    if (chart.value) {
      chart.value.destroy();
      chart.value = null;
    }
  }
}, { deep: true });

// Add strategy options constant
const strategyOptions = [
  { value: 'all', label: 'Alle Strategien' },
  { value: '0', label: 'Active Recall and Elaboration' },
  { value: '1', label: 'Case Study and Application' },
  { value: '2', label: 'Problem-Solving Challenge' },
  { value: '3', label: 'Conceptual Understanding Quiz' },
  { value: '4', label: 'Critical Analysis Challenge' }
];
</script>

<style scoped>
input[type="checkbox"] {
  appearance: auto;
  -webkit-appearance: checkbox;
  height: 16px;
  width: 16px;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  background-color: white;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #2563eb;
  border-color: #2563eb;
}

/* Ensure dropdown is visible */
.courses-dropdown {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  z-index: 50 !important;
}

/* Style for dropdown button */
.relative button {
  position: relative;
  z-index: 40;
}

/* Ensure dropdown has proper dimensions */
.courses-dropdown {
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>
