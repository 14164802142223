<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Verteilung nach Kanal</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          <p class="mb-2">Diese Grafik zeigt die Verteilung der Kunden nach ihrem Registrierungskanal:</p>
          <ul class="list-disc pl-4 space-y-1">
            <li><span class="font-semibold">AdminCenter:</span> Manuell durch Admin angelegt</li>
            <li><span class="font-semibold">CustomerCenter:</span> Selbstregistrierung durch Kunden</li>
            <li>Die Prozentangaben zeigen den jeweiligen Anteil an der Gesamtmenge</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <div v-if="loading" class="flex items-center justify-center h-full">
        <span>Loading...</span>
      </div>
      <Pie 
        v-else-if="data.datasets[0].data.length > 0" 
        :data="data" 
        :options="options" 
      />
      <div v-else class="flex items-center justify-center h-full">
        <span>Keine Daten verfügbar</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'vue-chartjs';
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';

ChartJS.register(ArcElement, Tooltip, Legend);

const loading = ref(true);
const data = ref({
  labels: [],
  datasets: [{
    backgroundColor: [
      'rgba(54, 162, 235, 0.8)',  // blue for AdminCenter
      'rgba(75, 192, 192, 0.8)',  // green for CustomerCenter
    ],
    borderColor: [
      'rgba(54, 162, 235, 1)',
      'rgba(75, 192, 192, 1)',
    ],
    borderWidth: 1,
    data: []
  }]
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom'
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          const label = context.label || '';
          const value = context.raw || 0;
          const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
          const percentage = Math.round((value / total) * 100);
          return `${label}: ${value} (${percentage}%)`;
        }
      }
    }
  }
};

onMounted(async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    const channelCounts = {};
    
    snapshot.forEach(doc => {
      const docData = doc.data();
      const channel = docData?.channel;
      if (channel) {
        channelCounts[channel] = (channelCounts[channel] || 0) + 1;
      }
    });

    data.value.labels = Object.keys(channelCounts);
    data.value.datasets[0].data = Object.values(channelCounts);
    
  } catch (error) {
    console.error('Error fetching customer channel statistics:', error);
  } finally {
    loading.value = false;
  }
});
</script> 