<template>
  <div>
    <h3 class="text-lg font-medium text-gray-900 mb-4">Kursfeedback Analyse</h3>
    <div class="flex flex-col space-y-4">
      <div class="flex justify-between items-center gap-4">
        <div class="flex gap-4 flex-1">
          <!-- Trainer dropdown -->
          <div class="flex-1 max-w-md">
            <select v-model="selectedTrainer" class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-150 focus:ring-blue-150">
              <option value="">Alle Trainer:innen</option>
              <option v-for="trainer in availableTrainers" 
                      :key="trainer" 
                      :value="trainer">
                {{ trainer }}
              </option>
            </select>
          </div>
          <!-- Course dropdown -->
          <div class="flex-1 max-w-md">
            <select v-model="selectedCourse" 
                    :disabled="!selectedTrainer"
                    class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-150 focus:ring-blue-150 disabled:opacity-50">
              <option value="">Alle Kurse</option>
              <option v-for="course in filteredCourses" 
                      :key="course.courseName" 
                      :value="course.courseName">
                {{ course.courseName }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="error" class="text-red-500 text-sm flex-1">
          {{ error }}
        </div>
        <select v-model="selectedTimeframe" class="rounded-md border-gray-300 shadow-sm focus:border-blue-150 focus:ring-blue-150">
          <option value="30">Letzte 30 Tage</option>
          <option value="90">Letzte 90 Tage</option>
          <option value="180">Letzte 180 Tage</option>
          <option value="365">Letztes Jahr</option>
        </select>
      </div>
      <div class="h-[600px] relative">
        <div v-if="loading" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-250"></div>
        </div>
        <canvas ref="feedbackChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { collection, getDocs } from 'firebase/firestore';
import { cp } from '../../../firebaseinit';
import Chart from 'chart.js/auto';

const feedbackChart = ref(null);
const selectedTimeframe = ref(30);
const selectedTrainer = ref('');
const selectedCourse = ref('');
const availableCourseOptions = ref([]);
const availableTrainers = ref([]);
const loading = ref(false);
const error = ref('');
let chart = null;

// Scoring system for feedback responses
const feedbackScores = {
  teachingContent: {
    yes: 1,
    partially: 0.5,
    no: 0
  },
  satisfaction: {
    very: 1,
    satisfied: 0.75,
    less: 0.25,
    not: 0
  },
  instructorAndFeedback: { // For instructorSupport and regularFeedback
    yes: 1,
    partially: 0.5,
    no: 0
  },
  technicalSupport: { // For technicalSupport
    fully: 1,
    partially: 0.5,
    not: 0
  },
  leconSupport: { // For leconSupport
    very: 1,
    somewhat: 0.5,
    barely: 0
  }
};

// Tooltips explaining the calculations
const tooltips = {
  teachingContent: (trainerName) => `Durchschnitt aus: Verständlichkeit der Inhalte, ausreichend Zeit, Lernmethoden, berufliche Entwicklung, Erwartungen erfüllt, Vorkenntnisse (Ja=100%, Teilweise=50%, Nein=0%)${trainerName ? `\nTrainer:in: ${trainerName}` : ''}`,
  professionalGuidance: (trainerName) => `Durchschnitt aus: Lehrkraft-Support (Ja=100%, Teilweise=50%, Nein=0%), Lernfortschritt-Feedback (Ja=100%, Teilweise=50%, Nein=0%), Technischer Support (Voll=100%, Teilweise=50%, Nicht=0%), Leeon-Support (Sehr=100%, Teilweise=50%, Kaum=0%)${trainerName ? `\nTrainer:in: ${trainerName}` : ''}`,
  satisfaction: (trainerName) => `Gesamtzufriedenheit (Sehr zufrieden=100%, Zufrieden=75%, Weniger zufrieden=25%, Unzufrieden=0%)${trainerName ? `\nTrainer:in: ${trainerName}` : ''}`
};

const calculateCategoryScore = (feedback, category) => {
  let total = 0;
  let count = 0;

  if (category === 'teachingContent') {
    const fields = [
      'contentUnderstandable',
      'sufficientTime',
      'learningMethods',
      'professionalDevelopment',
      'meetExpectations',
      'priorKnowledge'
    ];
    
    fields.forEach(field => {
      if (feedback.teachingContent[field]) {
        total += feedbackScores.teachingContent[feedback.teachingContent[field]] || 0;
        count++;
      }
    });
  } else if (category === 'professionalGuidance') {
    // Instructor Support
    if (feedback.professionalGuidance?.instructorSupport) {
      total += feedbackScores.instructorAndFeedback[feedback.professionalGuidance.instructorSupport] || 0;
      count++;
    }
    
    // Regular Feedback
    if (feedback.professionalGuidance?.regularFeedback) {
      total += feedbackScores.instructorAndFeedback[feedback.professionalGuidance.regularFeedback] || 0;
      count++;
    }
    
    // Technical Support
    if (feedback.professionalGuidance?.technicalSupport) {
      total += feedbackScores.technicalSupport[feedback.professionalGuidance.technicalSupport] || 0;
      count++;
    }
    
    // Lecon Support
    if (feedback.professionalGuidance?.leconSupport) {
      total += feedbackScores.leconSupport[feedback.professionalGuidance.leconSupport] || 0;
      count++;
    }
  } else if (category === 'satisfaction') {
    if (feedback.satisfaction?.overallSatisfaction) {
      total += feedbackScores.satisfaction[feedback.satisfaction.overallSatisfaction] || 0;
      count++;
    }
  }

  return count > 0 ? (total / count) * 100 : 0;
};

// Computed property for filtered courses based on selected trainer
const filteredCourses = computed(() => {
  if (!selectedTrainer.value) return availableCourseOptions.value;
  return availableCourseOptions.value.filter(course => 
    course.trainers.includes(selectedTrainer.value)
  );
});

// Reset course selection when trainer changes
watch(selectedTrainer, () => {
  selectedCourse.value = '';
});

const fetchAvailableCourses = async () => {
  const customersRef = collection(cp, 'customers');
  const snapshot = await getDocs(customersRef);
  const courseMap = new Map(); // Map to store course names and their trainers
  const trainerSet = new Set(); // Set to store all trainers
  const courseTrainerFeedbackMap = new Map(); // Map to track which courses have feedback for which trainers

  // First pass: collect courses with feedback and their trainers
  snapshot.forEach(doc => {
    const customerData = doc.data();
    if (customerData.courseFeedback) {
      Object.values(customerData.courseFeedback).forEach(feedback => {
        if (feedback.courseName) {
          // Find trainer for this feedback
          let trainerName = '';
          if (customerData.TeamMateInfo) {
            const courseInfo = customerData.TeamMateInfo.find(info => 
              info.courseID === feedback.courseId || 
              info.name === feedback.courseName
            );
            if (courseInfo?.trainer) {
              trainerName = typeof courseInfo.trainer === 'object' 
                ? courseInfo.trainer.firstname 
                : courseInfo.trainer;
            }
          }
          
          if (trainerName) {
            // Track which trainers have feedback for which courses
            if (!courseTrainerFeedbackMap.has(feedback.courseName)) {
              courseTrainerFeedbackMap.set(feedback.courseName, new Set());
            }
            courseTrainerFeedbackMap.get(feedback.courseName).add(trainerName);
            
            // Add to course map
            if (!courseMap.has(feedback.courseName)) {
              courseMap.set(feedback.courseName, new Set());
            }
            courseMap.get(feedback.courseName).add(trainerName);
            trainerSet.add(trainerName);
          }
        }
      });
    }
  });

  // Convert the map to array of objects with course name and trainers
  availableCourseOptions.value = Array.from(courseMap.entries())
    .map(([courseName, trainers]) => ({
      courseName,
      trainers: Array.from(trainers).sort()
    }))
    .sort((a, b) => a.courseName.localeCompare(b.courseName));

  // Set available trainers (only those who have feedback)
  availableTrainers.value = Array.from(trainerSet).sort();
};

// Update fetchFeedbackData to consider selected trainer
const fetchFeedbackData = async () => {
  loading.value = true;
  error.value = '';
  
  try {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - selectedTimeframe.value);

    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    const feedbackData = {
      teachingContent: [],
      professionalGuidance: [],
      satisfaction: [],
      trainerNames: []
    };

    if (snapshot.empty) {
      error.value = 'Keine Feedback-Daten für den ausgewählten Zeitraum verfügbar';
      return null;
    }

    snapshot.forEach(doc => {
      const customerData = doc.data();
      if (customerData.courseFeedback) {
        Object.values(customerData.courseFeedback).forEach(feedback => {
          // Skip if course doesn't match (when a course is selected)
          if (selectedCourse.value && feedback.courseName !== selectedCourse.value) return;

          // Find trainer name from TeamMateInfo
          let trainerName = '';
          if (customerData.TeamMateInfo) {
            const courseInfo = customerData.TeamMateInfo.find(info => 
              info.courseID === feedback.courseId || 
              info.name === feedback.courseName
            );
            if (courseInfo?.trainer) {
              trainerName = typeof courseInfo.trainer === 'object' 
                ? courseInfo.trainer.firstname 
                : courseInfo.trainer;
            }
          }

          // Skip if trainer doesn't match (when a trainer is selected)
          if (selectedTrainer.value && trainerName !== selectedTrainer.value) return;

          // Skip feedback outside the selected timeframe
          if (!feedback.timestamp) return;
          
          // Handle timestamp based on its format
          let feedbackDate;
          if (typeof feedback.timestamp === 'string') {
            feedbackDate = new Date(feedback.timestamp);
          } else if (feedback.timestamp?.seconds) {
            feedbackDate = new Date(feedback.timestamp.seconds * 1000);
          } else if (feedback.timestamp instanceof Date) {
            feedbackDate = feedback.timestamp;
          } else {
            return; // Skip if timestamp format is not recognized
          }
          
          if (feedbackDate < startDate || feedbackDate > endDate) return;

          // Process each feedback entry
          const teachingContentScore = calculateCategoryScore({
            teachingContent: {
              contentUnderstandable: feedback.teachingContent?.contentUnderstandable,
              sufficientTime: feedback.teachingContent?.sufficientTime,
              learningMethods: feedback.teachingContent?.learningMethods,
              professionalDevelopment: feedback.teachingContent?.professionalDevelopment,
              meetExpectations: feedback.teachingContent?.meetExpectations,
              priorKnowledge: feedback.teachingContent?.priorKnowledge
            }
          }, 'teachingContent');

          const professionalGuidanceScore = calculateCategoryScore({
            professionalGuidance: {
              instructorSupport: feedback.professionalGuidance?.instructorSupport,
              regularFeedback: feedback.professionalGuidance?.regularFeedback,
              technicalSupport: feedback.professionalGuidance?.technicalSupport,
              leconSupport: feedback.professionalGuidance?.leconSupport
            }
          }, 'professionalGuidance');

          const satisfactionScore = calculateCategoryScore({
            satisfaction: {
              overallSatisfaction: feedback.satisfaction?.overallSatisfaction
            }
          }, 'satisfaction');

          feedbackData.teachingContent.push(teachingContentScore);
          feedbackData.professionalGuidance.push(professionalGuidanceScore);
          feedbackData.satisfaction.push(satisfactionScore);
          feedbackData.trainerNames.push(trainerName);
        });
      }
    });

    // Only return data if we have feedback entries
    if (feedbackData.teachingContent.length === 0) {
      let errorMessage = 'Keine Feedback-Daten';
      if (selectedTrainer.value) {
        errorMessage += ` für ${selectedTrainer.value}`;
      }
      if (selectedCourse.value) {
        errorMessage += ` im Kurs "${selectedCourse.value}"`;
      }
      errorMessage += ' im ausgewählten Zeitraum verfügbar';
      error.value = errorMessage;
      return null;
    }

    // Get unique trainer names
    const uniqueTrainers = [...new Set(feedbackData.trainerNames)].filter(Boolean).join(', ');

    return {
      teachingContent: feedbackData.teachingContent.reduce((a, b) => a + b, 0) / feedbackData.teachingContent.length || 0,
      professionalGuidance: feedbackData.professionalGuidance.reduce((a, b) => a + b, 0) / feedbackData.professionalGuidance.length || 0,
      satisfaction: feedbackData.satisfaction.reduce((a, b) => a + b, 0) / feedbackData.satisfaction.length || 0,
      trainers: uniqueTrainers
    };
  } catch (error) {
    console.error('Error fetching feedback data:', error);
    error.value = 'Fehler beim Laden der Feedback-Daten';
    return null;
  } finally {
    loading.value = false;
  }
};

const updateChart = async () => {
  const data = await fetchFeedbackData();
  if (!data) return;

  if (chart) {
    chart.destroy();
  }

  const ctx = feedbackChart.value.getContext('2d');
  chart = new Chart(ctx, {
    type: 'radar',
    data: {
      labels: ['Lehrinhalte', 'Fachliche Begleitung', 'Zufriedenheit'],
      datasets: [{
        label: 'Feedback Score (%)',
        data: [
          data.teachingContent,
          data.professionalGuidance,
          data.satisfaction
        ],
        fill: true,
        backgroundColor: 'rgba(59, 130, 246, 0.2)',
        borderColor: 'rgb(59, 130, 246)',
        pointBackgroundColor: 'rgb(59, 130, 246)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(59, 130, 246)'
      }]
    },
    options: {
      maintainAspectRatio: false,
      scales: {
        r: {
          min: 0,
          max: 100,
          beginAtZero: true,
          ticks: {
            stepSize: 20,
            font: {
              size: 14
            }
          },
          pointLabels: {
            font: {
              size: 16
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          titleFont: {
            size: 16
          },
          bodyFont: {
            size: 14
          },
          callbacks: {
            label: function(context) {
              const label = context.dataset.label || '';
              const value = context.raw || 0;
              const category = ['teachingContent', 'professionalGuidance', 'satisfaction'][context.dataIndex];
              const tooltipLines = [
                `${label}: ${value.toFixed(1)}%`,
                '',
                'Berechnung:',
                tooltips[category](data.trainers)
              ];
              return tooltipLines;
            }
          }
        }
      }
    }
  });
};

watch([selectedTimeframe, selectedCourse], () => {
  updateChart();
});

onMounted(async () => {
  await fetchAvailableCourses();
  updateChart();
});
</script> 