<template>
  <section class="py-16 px-4 relative overflow-hidden">
    <!-- Background decoration -->
    <div class="absolute inset-0 bg-gradient-to-tr from-leeonBlue20 to-leeonPink20 opacity-40"></div>
    
    <div class="max-w-7xl mx-auto relative">
      <!-- Logo Section -->
      <div class="flex items-center justify-center flex-shrink-0 px-6 mb-8">
        <div class="flex gap-8 items-center">
          <img loading="lazy" src="../assets/images/logo/ExinCloud.png" class="w-24" alt="ExinCloud">
          <img loading="lazy" src="../assets/images/logo/DevOpsMaster.png" class="w-24" alt="DevOps Master">
          <img loading="lazy" src="../assets/images/logo/AgileScrum.png" class="w-24" alt="Agile Scrum">
          <img loading="lazy" src="../assets/images/logo/InformationSecFoundation.png" class="w-24" alt="Information Security Foundation">
        </div>
      </div>
      
      <!-- Decorative element -->
      <div class="flex justify-center mb-8">
        <div class="w-20 h-1 bg-gradient-to-r from-leeonBlue to-leeonPink rounded-full"></div>
      </div>
      
      <h2 class="text-3xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-leeonBlue to-blue-150">
        Was A-Leecon auszeichnet
      </h2>
      
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div v-for="(feature, index) in features" :key="index" 
             class="group p-6 rounded-xl bg-white shadow-sm border border-leeonBlue20 hover:shadow-lg hover:border-leeonBlue40 transition-all duration-300 relative overflow-hidden">
          <!-- Hover effect background -->
          <div class="absolute inset-0 bg-gradient-to-br from-leeonBlue20 to-leeonPink20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          
          <!-- Content -->
          <div class="relative">
            <!-- Icon placeholder with custom colors -->
            <div class="w-12 h-12 rounded-lg bg-gradient-to-br from-leeonBlue20 to-leeonPink20 flex items-center justify-center mb-4">
              <div class="w-6 h-6 text-leeonBlue">
                <!-- You can add specific icons for each feature here -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
            </div>
            
            <h3 class="text-xl font-semibold text-blue-250 mb-3 group-hover:text-leeonBlue transition-colors duration-300">
              {{ feature.title }}
            </h3>
            
            <p class="text-leeonDarkBlue60 group-hover:text-blue-250 transition-colors duration-300">
              {{ feature.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const features = [
  {
    title: "Ausgewogene Integration von KI und Menschlichkeit",
    description: "Wir verbessern unsere Lehrmethoden durch KI, während menschliches Wohlbefinden, Ethik und Werte im Mittelpunkt unseres Ansatzes stehen."
  },
  {
    title: "Hauseigene Forschung und Innovation",
    description: "Durch aktive Forschung in Bildungsmethodik und KI-Anwendungen bleiben unsere Programme stets an der Spitze der Entwicklung."
  },
  {
    title: "Ganzheitliche Bildung",
    description: "Über Fachwissen hinaus vermitteln wir kritisches Denken, emotionale Intelligenz und Anpassungsfähigkeit für die komplexe Welt von heute."
  },
  {
    title: "Kollaborative Lernumgebungen",
    description: "Wir fördern Teamarbeit und Peer-Learning durch Gruppenprojekte, Live-Sessions und interaktive Chatrooms."
  },
  {
    title: "Leidenschaft für Bildung",
    description: "Unser Team liebt, was es tut - diese Begeisterung überträgt sich auf jeden Aspekt unseres Unterrichts und unserer Interaktionen."
  },
  {
    title: "Inspirierende Lernräume",
    description: "Wir schaffen eine Umgebung, in der sich Lernende motiviert und unterstützt fühlen, ihr volles Potenzial zu entfalten."
  },
  {
    title: "Ethik im Vordergrund",
    description: "Wir lehren den verantwortungsvollen Umgang mit KI und legen besonderen Wert auf ethische Überlegungen und langfristige gesellschaftliche Auswirkungen."
  },
  {
    title: "Globale und inklusive Perspektive",
    description: "Unsere Kurse sind für verschiedene Lernende zugänglich und an unterschiedliche kulturelle und berufliche Kontexte anpassbar."
  },
  {
    title: "Zukunftsgestalter fördern",
    description: "Wir bilden eine Gemeinschaft von Führungskräften und Innovatoren, die zu einer nachhaltigeren Zukunft beitragen."
  }
]
</script>