const state = {
  audioUrl: null,
  isPlaying: false,
  currentTime: 0,
  duration: 0,
  seekPosition: 0,
  playbackSpeed: 1,
  hasAvatar: false,
  avatarUrl: null,
  videoElement: null,
};

const getters = {
  getAudioUrl: (state) => state.audioUrl,
  getIsPlaying: (state) => state.isPlaying,
  getCurrentTime: (state) => state.currentTime,
  getDuration: (state) => state.duration,
  getSeekPosition: (state) => state.seekPosition,
  getPlaybackSpeed: (state) => state.playbackSpeed,
  hasAvatar: (state) => state.hasAvatar,
  getAvatarUrl: (state) => state.avatarUrl,
  getVideoElement: (state) => state.videoElement,
};

const mutations = {
  SET_AUDIO_URL(state, url) {
    state.audioUrl = url;
  },
  SET_PLAYING_STATE(state, isPlaying) {
    state.isPlaying = isPlaying;
  },
  SET_CURRENT_TIME(state, time) {
    state.currentTime = time;
  },
  SET_DURATION(state, duration) {
    state.duration = duration;
  },
  SET_SEEK_POSITION(state, position) {
    state.seekPosition = position;
  },
  SET_PLAYBACK_SPEED(state, speed) {
    state.playbackSpeed = speed;
  },
  SET_AVATAR_STATE(state, { hasAvatar, avatarUrl }) {
    state.hasAvatar = hasAvatar;
    state.avatarUrl = avatarUrl;
  },
  SET_VIDEO_ELEMENT(state, element) {
    state.videoElement = element;
  },
};

const actions = {
  initializeMedia({ commit }, { audioUrl, slideData }) {
    commit("SET_AUDIO_URL", audioUrl);
    commit("SET_AVATAR_STATE", {
      hasAvatar: !!slideData?.avatar,
      avatarUrl: slideData?.avatar || null,
    });
    commit("SET_PLAYING_STATE", false);
    commit("SET_CURRENT_TIME", 0);
    commit("SET_DURATION", 0);
    commit("SET_SEEK_POSITION", 0);
    commit("SET_PLAYBACK_SPEED", 1);
    commit("SET_VIDEO_ELEMENT", null);
  },

  updateAudioState(
    { commit, state },
    { isPlaying, currentTime, duration, seekPosition, playbackSpeed }
  ) {
    if (isPlaying !== undefined) {
      commit("SET_PLAYING_STATE", isPlaying);
      if (state.videoElement) {
        if (isPlaying) {
          state.videoElement.play();
        } else {
          state.videoElement.pause();
        }
      }
    }

    if (currentTime !== undefined) {
      commit("SET_CURRENT_TIME", currentTime);
      if (state.videoElement) {
        state.videoElement.currentTime = currentTime;
      }
    }

    if (duration !== undefined) commit("SET_DURATION", duration);
    if (seekPosition !== undefined) {
      commit("SET_SEEK_POSITION", seekPosition);
      if (state.videoElement) {
        state.videoElement.currentTime = seekPosition;
      }
    }

    if (playbackSpeed !== undefined) {
      commit("SET_PLAYBACK_SPEED", playbackSpeed);
      if (state.videoElement) {
        state.videoElement.playbackRate = playbackSpeed;
      }
    }
  },

  setVideoElement({ commit }, element) {
    commit("SET_VIDEO_ELEMENT", element);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
