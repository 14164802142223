<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Kursverteilung nach Kurs</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          <p class="mb-2">Diese Grafik zeigt die Verteilung der Teilnehmer auf die verschiedenen Kurse:</p>
          <ul class="list-disc pl-4 space-y-1">
            <li>Zeigt die 15 am häufigsten gebuchten Kurse</li>
            <li>Jeder Balken zeigt die Gesamtanzahl der Teilnehmer pro Kurs</li>
            <li>Interne Testkurse und Microsoft Teams Kurse sind ausgeschlossen</li>
            <li>Die Prozentangabe zeigt den Anteil am Gesamtvolumen</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <Bar
        v-if="data.datasets[0].data.length > 0"
        :data="data"
        :options="options"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span>Keine Daten verfügbar</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'vue-chartjs'
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const loading = ref(true);
const selectedRange = ref('all');
const startDate = ref(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0]);
const endDate = ref(new Date().toISOString().split('T')[0]);

const data = ref({
  labels: [],
  datasets: [{
    label: 'Kursverteilung',
    backgroundColor: 'rgba(75, 192, 192, 0.8)',
    borderColor: 'rgba(75, 192, 192, 1)',
    borderWidth: 1,
    data: []
  }]
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          const value = context.raw || 0;
          const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
          const percentage = Math.round((value / total) * 100);
          return `${value} Teilnehmer (${percentage}%)`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1
      },
      title: {
        display: true,
        text: 'Anzahl Teilnehmer'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Kursname'
      },
      ticks: {
        autoSkip: false,
        maxRotation: 45,
        minRotation: 45
      }
    }
  }
};

const isDateInRange = (semStart, semEnd) => {
  if (selectedRange.value === 'all') return true;
  
  const start = new Date(startDate.value);
  const end = new Date(endDate.value);
  const courseStart = new Date(semStart);
  const courseEnd = new Date(semEnd);

  return (courseStart >= start && courseStart <= end) || 
         (courseEnd >= start && courseEnd <= end) ||
         (courseStart <= start && courseEnd >= end);
};

const updateChartData = async () => {
  try {
    loading.value = true;
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    const courseCounts = {};
    
    const excludedCourseIds = [
      "BfHGPoDdcIpDlWXmNjPZ",
      "l1DKt57j8FuR3dUR1fk6",
      "JiHhZsyMLB06ggMxkm0O"
    ];
    
    snapshot.forEach(doc => {
      const teamMateInfo = doc.data().TeamMateInfo || [];
      teamMateInfo.forEach(course => {
        if (!excludedCourseIds.includes(course.courseID) && 
            course.name && 
            !course.name.includes('Microsoft Teams') &&
            isDateInRange(course.semStart, course.semEnd)) {
          
          let courseName = course.name;
          
          if (courseName.includes('Scrum Master')) {
            courseName = 'Scrum Master:in';
          } else if (courseName.includes('PRINCE2') && 
                    courseName.includes('Foundation') && 
                    !courseName.includes('Agile')) {
            courseName = 'PRINCE2 Foundation';
          } else {
            if (courseName.length > 15 && 
                !courseName.includes('Scrum Master') && 
                !courseName.includes('PRINCE2')) {
              courseName = courseName.substring(0, 15) + '...';
            }
          }
          courseCounts[courseName] = (courseCounts[courseName] || 0) + 1;
        }
      });
    });

    const sortedCourses = Object.entries(courseCounts)
      .sort(([,a], [,b]) => b - a)
      .slice(0, 15)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    data.value.labels = Object.keys(sortedCourses);
    data.value.datasets[0].data = Object.values(sortedCourses);
    
  } catch (error) {
    console.error('Error fetching course distribution statistics:', error);
  } finally {
    loading.value = false;
  }
};

// Watch for changes in date range or selection mode
watch([selectedRange, startDate, endDate], () => {
  updateChartData();
});

onMounted(() => {
  updateChartData();
});
</script> 