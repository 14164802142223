<template>
  <div>
    <section class="py-12 px-4">
      <h2 class="text-4xl mb-2 text-center leading-tight text-blue-250">Über die A-Leecon</h2>
      <p class="text-center mb-12 text-gray-500">Lerne die Advanced Leecon kennen!</p>
      <div>
        <div class="flex flex-wrap items-center -mx-8 mt-24 bg-slate-50 pt-8 rounded-md shadow-sm ">
          <div class="md:w-1/2 px-8 mb-8 self-start"><img loading="lazy" class="w-4/5 mx-auto"
              src="../assets/images/vision.svg" alt="" style="max-height: 250px;"></div>
          <div class="md:w-1/2 px-8 mb-8 self-start">

            <h3 class="text-3xl mb-6 leading-tight text-blue-250 uppercase">Vision</h3>
            <p class="text-gray-500 leading-relaxed">Die A-Leecon GmbH strebt eine <span
                class="text-red-150">Bildungsrevolution</span> an, die individuelles Lernen durch fortschrittliche
              Technologie und maßgeschneiderte Lernpfade neu definiert. <br>
              Unser Ziel ist es, Lernenden die Freiheit zu geben, ihre Bildung selbst zu gestalten, unterstützt durch KI
              und Analytics, um Inhalte auf ihre Bedürfnisse, Fähigkeiten und Vorwissen zuzuschneiden.
              Wir durchbrechen Barrieren, machen Bildung für alle zugänglich und fördern dadurch gesellschaftlichen
              Wandel. <br><br>
              <span class="font-bold"> Mit A-Leecon wird Lernen zu einer persönlichen Reise der Selbstentfaltung und
                -ermächtigung.</span>
            </p>
          </div>
        </div>

        <div class="flex flex-wrap items-center -mx-8 mt-8">
          <div class="md:w-1/2 px-8 mb-8 md:order-1"><img loading="lazy" class="w-4/5 mx-auto"
              src="../assets/images/values1.svg" alt=""></div>
          <div class="md:w-1/2 px-8 md:text-right">

            <h3 class="text-3xl mb-6 leading-tight text-blue-250 uppercase">Unsere Werte</h3>
            <ul class="text-gray-500 space-y-2">
              <li>Den Einsatz und die Integration <span class="text-red-150 ">neuer Technologien</span>
              </li>
              <li><span class="text-red-150 ">Kontinuierliche Weiterentwicklung</span> und Verbesserung in
                allen Bereichen</li>
              <li><span class="text-red-150 ">Exzellenten Service</span>, der unsere Kunden begeistert
              </li>
              <li>Anwendung <span class="text-red-150 ">modernster Lernmethoden</span> für effektives und
                nachhaltiges Lernen</li>
              <li><span class="text-red-150 "> Nachhaltigkeit</span> in unserem Handeln und
                Entscheidungen</li>
              <li>Vielfalt und <span class="text-red-150 ">Inklusivität</span> als Bereicherung unserer
                Unternehmenskultur</li>
              <li><span class="text-red-150 ">Teamgeist</span> und Zusammenarbeit als Grundpfeiler unseres
                Erfolges</li>
              <li><span class="text-red-150 ">Offene Fehlerkultur</span>, die organisationale Entwicklung fördert</li>
            </ul>
          </div>
        </div>
        <div class="flex justify-center text-gray-500 mt-8 ">Wir lehnen strikt jegliche Form von Rassismus,
          Sektenzugehörigkeit
          sowie
          alle Arten von
          Menschenfeindlichkeit und Diskriminierung ab!</div>
        <div class="flex flex-wrap items-center -mx-8 mt-16 bg-slate-50 pt-8 rounded-md shadow-sm">
          <div class="md:w-1/2 px-8 mb-8 order-none"><img loading="lazy" class="w-4/5 mx-auto"
              src="../assets/images/values.svg" alt=""></div>
          <div class="md:w-1/2 px-8 self-start ">

            <h3 class="text-3xl mb-6 leading-tight text-blue-250 uppercase">Ausrichtung
            </h3>
            <div class="text-gray-500 leading-relaxed"> Wir richten uns am Arbeits- und Ausbildungsmarkt aus. Unsere
              Kernaufgaben dabei sind:
              <ol class="text-gray-500 space-y-2 mt-2">
                <li>- Analyse des Arbeitsmarktes</li>
                <li>- Ermittlung der Kund*innenbedürfnisse</li>
                <li>- Konzeption und Durchführung wirtschaftlicher Maßnahmen</li>
                <li>- Auswertung und Weiterentwicklung des Maßnahmenangebotes</li>
                <li>- Berücksichtigung der Vereinbarkeit von Familie und Beruf.</li>
              </ol>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
