import { defineStore } from 'pinia';

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    collapsedSections: {
      communication: false,
      management: false,
      business: false,
      internal: false
    }
  }),
  
  actions: {
    toggleSection(section) {
      this.collapsedSections[section] = !this.collapsedSections[section];
    },
    
    resetState() {
      this.collapsedSections = {
        communication: false,
        management: false,
        business: false,
        internal: false
      };
    }
  }
}); 