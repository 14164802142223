<template>
  <div class="lg:flex lg:justify-center">
    <svg
      class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      aria-hidden="true">
      <defs>
        <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-1"
          patternUnits="userSpaceOnUse">
          <path d="M100 200V.5M.5 .5H200" fill="none" />
        </pattern>
      </defs>
      <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
        <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
          stroke-width="0" />
      </svg>
      <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
    </svg>

    <div class="md:py-12 max-w-screen-xl relative">
      <!-- Hero Section -->
      <FadeInSection>
        <Welcome />
      </FadeInSection>
      
     
      
      <!-- Mission Statement -->
      <FadeInSection>
        <MissionStatement />
      </FadeInSection>
      
      <!-- Key Differentiators -->
      <FadeInSection>
        <ValueProposition />
      </FadeInSection>
      
      <!-- Social Proof -->
      <FadeInSection>
        <div class="space-y-12">
          <Stats />
          <Quote />
        </div>
      </FadeInSection>

           <!-- Leeon Career Coach Section -->
    
        <LeeonCareerCoach />
  

      <!-- Tuesday Teaser -->
      <FadeInSection>
        <TuesdayTeaser />
      </FadeInSection>
      
      <!-- Main Offerings -->
      <FadeInSection>
        <div class="space-y-16">
        
          <Angebot />
          <Katalog />
        </div>
      </FadeInSection>

  
      
      <!-- Trust Signals -->
      <FadeInSection>
        <div class="space-y-8 mt-16">
          <APMG />
          <Logos />
          <Axelos />
        </div>
      </FadeInSection>
      
      <!-- Call to Action Section -->
      <div class="mt-16 space-y-8">
        <CallMe />
        <Kontakt />
      </div>
      
      <!-- Additional Information -->
      <div class="mt-16">
        <Leitbild />
        <div class="md:hidden mt-8">
          <Why />
        </div>
      </div>
      
         <!-- Testimonials section -->
         <div class="relative isolate mt-32 sm:mt-56 sm:pt-32">
        <svg
          class="absolute inset-0 -z-10 hidden h-full w-full stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)] sm:block"
          aria-hidden="true">
          <defs>
            <pattern id="55d3d46d-692e-45f2-becd-d8bdc9344f45" width="200" height="200" x="50%" y="0"
              patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="0" class="overflow-visible fill-gray-50">
            <path d="M-200.5 0h201v201h-201Z M599.5 0h201v201h-201Z M399.5 400h201v201h-201Z M-400.5 600h201v201h-201Z"
              stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#55d3d46d-692e-45f2-becd-d8bdc9344f45)" />
        </svg>
        <div class="relative">
          <div
            class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
            aria-hidden="true">
            <div
              class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
              style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
          </div>
          <div
            class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-8 opacity-25 blur-3xl xl:justify-end"
            aria-hidden="true">
            <div
              class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff6584] to-[#0094da] xl:ml-0 xl:mr-[calc(50%-12rem)]"
              style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
          </div>
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-xl sm:text-center">
              <h2 class="text-lg font-semibold leading-8 tracking-tight text-red-150">Wie du von unseren
                KI-Kursen profitieren kannst</h2>
              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Maximiere deine
                Karrierechancen mit KI-Kompetenzen</p>
              <p class="mt-4 text-lg leading-8 text-gray-600">Egal in welchem Berufsfeld du tätig bist – von
                Scrum Master über IT-Service Managerin bis hin zu vielen anderen Rollen – unsere KI-Kurse
                sind so
                konzipiert, dass sie für alle Berufe relevante Kenntnisse vermitteln. Erfahre hier anhand
                konkreter Beispiele, wie unsere Kurse den
                Unterschied machen können.</p>

            </div>

            <div
              class="mx-auto my-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
              <figure
                class="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
                <blockquote class="p-12 text-xl font-semibold leading-8 tracking-tight text-gray-900">
                  <p>{{ `“${featuredTestimonial.body}”` }}</p>
                </blockquote>
                <figcaption class="flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4">

                  <div class="flex-auto">
                    <div class="font-semibold text-red-150">{{ featuredTestimonial.author.name }}</div>

                  </div>
                  <img class="h-10 w-auto flex-none" :src="featuredTestimonial.author.logoUrl" alt="" />
                </figcaption>
              </figure>
              <div v-for="(columnGroup, columnGroupIdx) in testimonials" :key="columnGroupIdx"
                class="space-y-8 xl:contents xl:space-y-0 ">
                <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx"
                  :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
                  <figure v-for="testimonial in column" :key="testimonial.author.imageUrl"
                    class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
                    <blockquote class="text-gray-900">
                      <p>{{ `“${testimonial.body}”` }}</p>
                    </blockquote>
                    <figcaption class="mt-6 flex items-center gap-x-4">

                      <div>
                        <div class="font-semibold text-blue-250">{{ testimonial.author.name }}</div>

                      </div>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MissionStatement from '../components/MissionStatement.vue'
import ValueProposition from '../components/ValueProposition.vue'
import Welcome from '../components/Welcome.vue'
import Stats from '../components/Stats.vue'
import Quote from '../components/Quote.vue'
import TuesdayTeaser from '../components/TuesdayTeaser.vue'
import Angebot from '../components/Angebot.vue'
import Katalog from '../components/Lieblingsseminare.vue'
import Logos from '../components/Logos.vue'
import APMG from '../components/APMG.vue'
import Axelos from '../components/Axelos.vue'
import CallMe from '../components/CallMe.vue'
import Kontakt from '../components/Kontakt.vue'
import Leitbild from '../components/UeberUns.vue'
import Why from '../components/WhyElearningPro.vue'
import FadeInSection from '../components/FadeInSection.vue'
import LeeonCareerCoach from '../components/LeeonCareerCoach.vue'

// Add these constants for testimonials
const featuredTestimonial = {
  body: 'Unsere Kurse bieten mehr als nur technisches Wissen. Sie zeigen praxisnah, wie moderne Technologien in verschiedenen Berufsfeldern sinnvoll und effektiv angewendet werden können. Von der Automatisierung einfacher Aufgaben bis zur Datenanalyse - entdecke die vielfältigen Möglichkeiten.',
  author: {
    name: 'Praxisnahe Anwendung in allen Berufsfeldern',
  },
};

const testimonials = [
  [
    [
      {
        body: 'Die Grundlagenkurse vermitteln nicht nur theoretisches Wissen, sondern zeigen auch, wie moderne Technologien in verschiedenen Berufsfeldern angewendet werden können. Das Ergebnis: effizientere Projekte und eine optimierte Arbeitsweise.',
        author: {
          name: 'Effizienteres Projektmanagement',
        },
      },
      {
        body: 'Der Kurs für IT-Service Management bietet fundierte Einblicke, die zur Verbesserung der Entscheidungsfindung beitragen. Die Integration moderner Methoden ermöglicht eine bessere Analyse und Prognose.',
        author: {
          name: 'Bessere Entscheidungsfindung',
        },
      },
    ],
    [
      {
        body: 'Unser spezieller Kurs für Projektmanager integriert moderne Konzepte, die das Potenzial haben, die Effizienz des gesamten Teams zu steigern. Die Kombination aus agilen Methoden und neuen Technologien bietet innovative Lösungen.',
        author: {
          name: 'Steigerung der Team-Effizienz',
        },
      },
    ],
  ],
  [
    [
      {
        body: 'Für diejenigen, die bereits über ITIL-Kenntnisse verfügen, eröffnen unsere Kurse neue Perspektiven zur Optimierung von IT-Service-Management-Prozessen.',
        author: {
          name: 'Erweiterung des ITIL-Wissens',
        },
      },
    ],
    [
      {
        body: 'Die Kombination aus sozialem Lernen und Expert*innenunterstützung in unseren Kursen bereitet die Teilnehmer optimal auf die Herausforderungen der modernen Arbeitswelt vor.',
        author: {
          name: 'Soziales Lernen und Expertenunterstützung',
        },
      },
    ],
  ],
];
</script>

<style>
/* Add smooth scrolling to the page */
html {
  scroll-behavior: smooth;
}

/* Hover animations for interactive elements */
.hover-lift {
  transition: transform 0.2s ease-in-out;
}

.hover-lift:hover {
  transform: translateY(-2px);
}

/* Pulse animation for important CTAs */
@keyframes subtle-pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.pulse-animation {
  animation: subtle-pulse 3s infinite;
}
</style>
