<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div class="flex items-center gap-2">
        <h2 class="text-lg font-semibold">Kursteilnehmer</h2>
        <div class="relative group">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="h-5 w-5 text-gray-500 cursor-help" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              stroke-linecap="round" 
              stroke-linejoin="round" 
              stroke-width="2" 
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
            />
          </svg>
          <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
            <p class="mb-2">Diese Grafik zeigt die Teilnehmerzahlen des ausgewählten Kurses über die Zeit:</p>
            <ul class="list-disc pl-4 space-y-1">
              <li>Zeigt aktive Teilnehmer pro Monat</li>
              <li>Basiert auf Kursstart- und Endedatum</li>
              <li>Interne Testkurse sind ausgeschlossen</li>
            </ul>
          </div>
        </div>
      </div>

      <select 
        v-model="selectedCourse" 
        class="rounded-md border border-gray-300 px-3 py-1.5 text-sm min-w-[200px]"
      >
        <option value="">Kurs auswählen...</option>
        <option 
          v-for="course in availableCourses" 
          :key="course.name" 
          :value="course.name"
        >
          {{ course.name }}
        </option>
      </select>
    </div>

    <div class="h-[400px]">
      <div v-if="loading" class="flex items-center justify-center h-full">
        <span>Loading...</span>
      </div>
      <Line
        v-else-if="chartData"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Bitte wählen Sie einen Kurs aus</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { Line } from 'vue-chartjs';
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const loading = ref(true);
const selectedCourse = ref('');
const availableCourses = ref([]);
const chartData = ref(null);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `Teilnehmer: ${context.raw}`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Anzahl Teilnehmer'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Datum'
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45
      }
    }
  }
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'short'
  });
};

const loadAvailableCourses = async () => {
  const customersRef = collection(cp, 'customers');
  const snapshot = await getDocs(customersRef);
  
  const excludedCourseIds = [
    "BfHGPoDdcIpDlWXmNjPZ",
    "l1DKt57j8FuR3dUR1fk6",
    "JiHhZsyMLB06ggMxkm0O"
  ];

  const courseCounts = new Map(); // Track course counts
  
  snapshot.forEach(doc => {
    const teamMateInfo = doc.data().TeamMateInfo || [];
    teamMateInfo.forEach(course => {
      if (!excludedCourseIds.includes(course.courseID) && 
          course.name && 
          !course.name.includes('Microsoft Teams')) {
        const count = courseCounts.get(course.name) || 0;
        courseCounts.set(course.name, count + 1);
      }
    });
  });

  // Convert to array and sort by count
  const sortedCourses = Array.from(courseCounts.entries())
    .sort((a, b) => b[1] - a[1]) // Sort by count descending
    .map(([name]) => ({ name })); // Convert to required format

  availableCourses.value = sortedCourses;
  
  // Set the most popular course as default
  if (sortedCourses.length > 0) {
    selectedCourse.value = sortedCourses[0].name;
  }
};

const updateChartData = async () => {
  if (!selectedCourse.value) {
    chartData.value = null;
    return;
  }

  try {
    loading.value = true;
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    // Collect all date points where participant count changes
    const datePoints = [];

    snapshot.forEach(doc => {
      const teamMateInfo = doc.data().TeamMateInfo || [];
      const courseSessions = teamMateInfo.filter(course => 
        course.name === selectedCourse.value &&
        course.semStart &&
        course.semEnd
      );

      courseSessions.forEach(course => {
        // Add start date with positive count
        datePoints.push({
          date: new Date(course.semStart),
          type: 'start',
          change: 1
        });

        // Add end date with negative count
        datePoints.push({
          date: new Date(course.semEnd),
          type: 'end',
          change: -1
        });
      });
    });

    // Sort all points chronologically
    datePoints.sort((a, b) => a.date - b.date);

    // Create running total for each date point
    let runningTotal = 0;
    const participantCounts = datePoints.map(point => {
      runningTotal += point.change;
      return {
        date: formatDate(point.date),
        count: runningTotal
      };
    });

    // Group by month and take the last value for each month
    const monthlyData = new Map();
    participantCounts.forEach(item => {
      monthlyData.set(item.date, item.count);
    });

    // Prepare chart data
    chartData.value = {
      labels: Array.from(monthlyData.keys()),
      datasets: [
        {
          label: 'Teilnehmer',
          data: Array.from(monthlyData.values()),
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          tension: 0.1,
          fill: true
        }
      ]
    };

    console.log('Course participant data:', {
      course: selectedCourse.value,
      totalDataPoints: datePoints.length,
      monthlyData: Array.from(monthlyData.entries()).map(([date, count]) => ({
        date,
        count
      }))
    });

  } catch (error) {
    console.error('Error fetching course participant data:', error);
  } finally {
    loading.value = false;
  }
};

watch(selectedCourse, () => {
  updateChartData();
});

onMounted(async () => {
  await loadAvailableCourses();
  loading.value = false;
});
</script> 