<template>
  <div class="relative isolate overflow-hidden bg-gray-700">
    <div class="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Kostenloser Rückruf-Service<br />Wir rufen
          dich kostenlos zurück!</h2>
        <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">Hier kannst du sofort einen Termin vereinbaren oder uns per WhatsApp schreiben!
        </p>
        <div class="mt-10 flex flex-wrap items-center justify-center gap-4">
          <router-link to="/booking">
            <button class="bg-transparent text-red-150 font-semibold hover:text-red-150 py-2 px-4 border border-transparent hover:border-red-150 rounded">
              <i class="far fa-calendar-alt fa-lg mr-2"></i>Termin vereinbaren!
            </button>
          </router-link>
          
          <a href="http://wa.me/491722439836/?text=Hi%20advanced%20Leecon," class="inline-flex items-center py-2 px-4 bg-green-600 hover:bg-green-700 text-white font-semibold rounded transition-colors duration-300">
            <i class="fab fa-whatsapp fa-lg mr-2"></i>
            WhatsApp Chat
          </a>
        </div>
      </div>
    </div>
    <svg viewBox="0 0 1024 1024"
      class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
      aria-hidden="true">
      <circle cx="512" cy="512" r="512" fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fill-opacity="0.7" />
      <defs>
        <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
          <stop stop-color="#7775D6" />
          <stop offset="1" stop-color="#ff6584" />
        </radialGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import { db } from "../firebaseinit";
export default {
  data() {
    return {
      name: "",
      phone: "",
    };
  },
  methods: {
    callMe() {
      db.collection("callme")
        .add({
          name: this.name,
          phone: this.phone,
        })
        .then(() => {
          this.name = "";
          this.phone = "";
        });
    },
  },
};
</script>
