<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Geschlechterverteilung</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          <p class="mb-2">Diese Grafik zeigt die Geschlechterverteilung der Kunden:</p>
          <ul class="list-disc pl-4 space-y-1">
            <li>Basiert auf der Anrede (Herr/Frau)</li>
            <li>Berücksichtigt nur Einträge mit vorhandener Anrede</li>
            <li>Die Prozentangaben zeigen den jeweiligen Anteil</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <div v-if="loading" class="flex items-center justify-center h-full">
        <span>Loading...</span>
      </div>
      <Pie
        v-else-if="chartData && chartData.datasets[0].data.length > 0"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Keine Daten verfügbar</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Pie } from 'vue-chartjs';
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const loading = ref(true);
const chartData = ref(null);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom'
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          const label = context.label || '';
          const value = context.raw || 0;
          const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
          const percentage = Math.round((value / total) * 100);
          return `${label}: ${value} (${percentage}%)`;
        }
      }
    }
  }
};

const updateChartData = async () => {
  try {
    loading.value = true;
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    const genderCounts = {
      'Männlich': 0,
      'Weiblich': 0
    };
    
    let totalWithGender = 0;

    snapshot.forEach(doc => {
      const data = doc.data();
      const anrede = data?.anrede;
      
      if (anrede === 'Herr') {
        genderCounts['Männlich']++;
        totalWithGender++;
      } else if (anrede === 'Frau') {
        genderCounts['Weiblich']++;
        totalWithGender++;
      }
    });

    if (totalWithGender === 0) {
      console.log('No gender data found');
      return;
    }

    chartData.value = {
      labels: Object.keys(genderCounts),
      datasets: [{
        data: Object.values(genderCounts),
        backgroundColor: [
          'rgba(54, 162, 235, 0.8)',  // blue for male
          'rgba(255, 99, 132, 0.8)',  // pink for female
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1
      }]
    };

    console.log('Gender distribution data:', {
      total: totalWithGender,
      male: genderCounts['Männlich'],
      female: genderCounts['Weiblich'],
      malePercentage: Math.round((genderCounts['Männlich'] / totalWithGender) * 100),
      femalePercentage: Math.round((genderCounts['Weiblich'] / totalWithGender) * 100)
    });

  } catch (error) {
    console.error('Error fetching gender distribution data:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  updateChartData();
});
</script> 