import { defineStore } from 'pinia'

export const useCitationStore = defineStore('citations', {
  state: () => ({
    citations: {
      'itif2021': {
        author: 'Information Technology & Innovation Foundation (ITIF)',
        title: 'How Much Will the Artificial Intelligence Act Cost Europe?',
        year: 2021,
        url: 'https://itif.org/publications/2021/07/26/how-much-will-artificial-intelligence-act-cost-europe/',
        accessed: '2024-02-15'
      },
      'digitalpoland2023': {
        author: 'Digital Poland & allies',
        title: 'European AI Associations Survey',
        year: 2023,
        url: 'https://digitalpoland.org/en/initiatives',
        accessed: '2024-02-15'
      },
      'atomico2022': {
        author: 'Atomico',
        title: 'The State of European Tech 2022',
        year: 2022,
        url: 'https://stateofeuropeantech.com',
        accessed: '2024-02-15'
      },
      'bitkom2023': {
        author: 'Bitkom',
        title: 'Bitkom on the AI Act debate',
        year: 2023,
        url: 'https://www.bitkom.org',
        accessed: '2024-02-15'
      }
    }
  }),

  getters: {
    getCitation: (state) => (id) => state.citations[id],
    getAllCitations: (state) => Object.entries(state.citations)
      .sort(([,a], [,b]) => a.author.localeCompare(b.author))
  }
}) 