<template>
  <div ref="fadeSection" class="opacity-0 transition-all duration-1000" :class="{ 'opacity-100': isVisible }">
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const fadeSection = ref(null);
const isVisible = ref(false);

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};

const observerCallback = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      isVisible.value = true;
    }
  });
};

let observer;

onMounted(() => {
  observer = new IntersectionObserver(observerCallback, options);
  if (fadeSection.value) {
    observer.observe(fadeSection.value);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script> 