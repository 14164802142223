<template>
  <Bar
    :data="chartData"
    :options="chartOptions"
    class="h-64"
  />
</template>

<script setup>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const chartData = {
  labels: ['2021', '2022', '2023', '2024 (Prognose)'],
  datasets: [
    {
      label: 'KI-Investitionen in der EU (Mrd. €)',
      backgroundColor: '#3B82F6',
      data: [7.2, 6.1, 5.3, 4.2]
    }
  ]
}

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'KI-Investitionen in der EU'
    }
  }
}
</script> 