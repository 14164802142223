<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Kursverteilung pro Kunde</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          <p class="mb-2">Diese Grafik zeigt die Verteilung der Kursanzahl pro Kunde:</p>
          <ul class="list-disc pl-4 space-y-1">
            <li>X-Achse: Anzahl der gebuchten Kurse pro Kunde</li>
            <li>Y-Achse: Anzahl der Kunden mit dieser Kursanzahl</li>
            <li>Beispiel: "3 Kunden haben je 2 Kurse gebucht"</li>
            <li>Interne Testkurse und Microsoft Teams Kurse sind ausgeschlossen</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <Bar
        v-if="chartData"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Lade Daten...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { cp } from '../../../firebaseinit.js';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { collection, getDocs } from 'firebase/firestore';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const chartData = ref(null);
const selectedRange = ref('all');
const startDate = ref(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0]); // Start of current year
const endDate = ref(new Date().toISOString().split('T')[0]); // Today

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `Anzahl Kunden: ${context.raw}`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1
      },
      title: {
        display: true,
        text: 'Anzahl Kunden'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Anzahl Kurse'
      }
    }
  }
};

const isDateInRange = (semStart, semEnd) => {
  if (selectedRange.value === 'all') return true;
  
  const start = new Date(startDate.value);
  const end = new Date(endDate.value);
  const courseStart = new Date(semStart);
  const courseEnd = new Date(semEnd);

  return (courseStart >= start && courseStart <= end) || 
         (courseEnd >= start && courseEnd <= end) ||
         (courseStart <= start && courseEnd >= end);
};

const updateChartData = async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    const courseCounts = {};
    snapshot.forEach(doc => {
      const teamMateInfo = doc.data().TeamMateInfo || [];
      const filteredTeamMateInfo = teamMateInfo.filter(course => {
        const excludedCourseIds = [
          "BfHGPoDdcIpDlWXmNjPZ",
          "l1DKt57j8FuR3dUR1fk6",
          "JiHhZsyMLB06ggMxkm0O"
        ];
        
        // Check both course ID and date range
        return !excludedCourseIds.includes(course.courseID) && 
               isDateInRange(course.semStart, course.semEnd);
      });
      
      const courseCount = filteredTeamMateInfo.length;
      if (courseCount > 0) {
        courseCounts[courseCount] = (courseCounts[courseCount] || 0) + 1;
      }
    });

    const sortedLabels = Object.keys(courseCounts)
      .map(Number)
      .sort((a, b) => a - b);

    chartData.value = {
      labels: sortedLabels.map(count => count.toString()),
      datasets: [{
        data: sortedLabels.map(count => courseCounts[count]),
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };
  } catch (error) {
    console.error('Error fetching customer course statistics:', error);
  }
};

// Watch for changes in date range or selection mode
watch([selectedRange, startDate, endDate], () => {
  updateChartData();
});

onMounted(() => {
  updateChartData();
});
</script> 