<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Altersverteilung der Kunden</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          <p class="mb-2">Diese Grafik zeigt die Altersverteilung der aktiven Kunden:</p>
          <ul class="list-disc pl-4 space-y-1">
            <li>Nur Kunden mit mindestens einem Kurs werden gezählt</li>
            <li>Das Alter wird aus dem Geburtsjahr berechnet</li>
            <li>Kunden ohne Altersangabe werden nicht berücksichtigt</li>
            <li>Die Altersgruppen sind in 10-Jahres-Schritten eingeteilt</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <Bar
        v-if="chartData"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Lade Daten...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { cp } from '../../../firebaseinit.js';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { collection, getDocs } from 'firebase/firestore';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const chartData = ref(null);
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `${context.raw} aktive Kunden`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1
      },
      title: {
        display: true,
        text: 'Anzahl Kunden'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Altersgruppe'
      }
    }
  }
};

function getAgeGroup(birthYear) {
  const currentYear = new Date().getFullYear();
  const age = currentYear - birthYear;
  
  if (age < 20) return '< 20';
  if (age < 30) return '20-29';
  if (age < 40) return '30-39';
  if (age < 50) return '40-49';
  if (age < 60) return '50-59';
  return '60+';
}

const ageGroupsOrder = ['< 20', '20-29', '30-39', '40-49', '50-59', '60+'];

onMounted(async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    // Initialize age groups with 0
    const ageCounts = {};
    ageGroupsOrder.forEach(group => {
      ageCounts[group] = 0;
    });

    snapshot.forEach(doc => {
      const data = doc.data();
      // Only count customers with courses
      if (data.TeamMateInfo && data.TeamMateInfo.length > 0) {
        const birthYear = data?.birthdayObject?.year;
        if (birthYear) {
          const ageGroup = getAgeGroup(birthYear);
          ageCounts[ageGroup]++;
        }
      }
    });

    chartData.value = {
      labels: ageGroupsOrder,
      datasets: [{
        data: ageGroupsOrder.map(group => ageCounts[group]),
        backgroundColor: 'rgba(75, 192, 192, 0.5)',  // green for active customers
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1
      }]
    };
  } catch (error) {
    console.error('Error fetching customer age statistics:', error);
  }
});
</script> 