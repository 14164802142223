const state = {
  trainer: false,
};
const getters = {
  isTrainer: (state) => {
    return state.trainer;
  },
};
const mutations = {
  SET_TRAINER: (state, trainer) => {
    state.trainer = trainer;
  },
  ADD_TRAINER: (state, trainerEmail) => {
    state.trainerArray.push(trainerEmail);
  },
};
const actions = {
  setTrainer: ({ commit }, payload) => {
    let trainer = false;
    const TrainerArray = [
      "stefanie.loeser@leecon.de",
      "robin.troeger@leecon.de",
      "nadine.wischmeier@leecon.de",
      "martin.schoenfeld@leecon.de",
      "elaine.zache@leecon.de",
      "daniel.goerlich@leecon.de",
      "artur.hahn@leecon.de",
    ];
    TrainerArray.forEach((el) => {
      if (el === payload) {
        trainer = true;
      }
    });
    commit("SET_TRAINER", trainer);
  },
  addTrainer: ({ commit }, trainerEmail) => {
    commit("ADD_TRAINER", trainerEmail);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
