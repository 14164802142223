<template>
  <Doughnut
    :data="chartData"
    :options="chartOptions"
    class="h-64"
  />
</template>

<script setup>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

const chartData = {
  labels: ['In EU verblieben', 'In USA abgewandert', 'In andere Länder'],
  datasets: [
    {
      backgroundColor: ['#3B82F6', '#EF4444', '#10B981'],
      data: [63, 20, 17]
    }
  ]
}

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Verteilung europäischer KI-Talente'
    }
  }
}
</script> 