<template>
  <div>
    <h3 class="text-lg font-semibold text-leeonBlue mb-4">Absagegründe</h3>
    <div class="relative" style="height: 400px">
      <Bar v-if="chartData" :data="chartData" :options="chartOptions" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { cp } from '../../../firebaseinit';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const chartData = ref(null);

const reasonTranslations = {
  'nichts_passendes': 'Nichts Passendes gefunden',
  'besseres_angebot': 'Besseres Angebot gefunden',
  'spaeter_interessant': 'Könnte später interessant werden',
  'falsch_registriert': 'Falsch registriert',
  'jobaufnahme': 'Jobaufnahme',
  'keine_rueckmeldung': 'Keine Rückmeldung'
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `Anzahl: ${context.raw}`;
        }
      }
    }
  }
};

onMounted(async () => {
  try {
    const q = query(collection(cp, "customers"), where("status", "==", "archive"));
    const snapshot = await getDocs(q);
    
    const reasonCounts = {};
    
    snapshot.forEach((doc) => {
      const data = doc.data();
      const reason = data.cancellationReason;
      if (reason) {
        reasonCounts[reason] = (reasonCounts[reason] || 0) + 1;
      }
    });

    const sortedReasons = Object.entries(reasonCounts)
      .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    chartData.value = {
      labels: Object.keys(sortedReasons).map(key => reasonTranslations[key] || key),
      datasets: [
        {
          data: Object.values(sortedReasons),
          backgroundColor: '#003882', // leeonBlue
          hoverBackgroundColor: '#6687b4', // leeonBlue60
          borderRadius: 6
        }
      ]
    };
  } catch (error) {
    console.error('Error fetching cancellation data:', error);
  }
});
</script> 