export default {
  namespaced: true,

  state: {
    isOpen: false,
    response: '',
    lernBuddyResponse: '',
    currentChallengeId: null
  },

  mutations: {
    setLernBuddyOpen(state, value) {
      state.isOpen = value;
    },
    setResponse(state, response) {
      state.response = response;
      state.lernBuddyResponse = response; // Keep both for compatibility
    },
    setCurrentChallengeId(state, challengeId) {
      state.currentChallengeId = challengeId;
      console.log(`Current challenge ID set to: ${challengeId}`);
    }
  },

  actions: {
    updateResponse({ commit }, response) {
      commit('setResponse', response);
    },
    // Add this new action for backward compatibility
    updateLernBuddyResponse({ commit }, response) {
      commit('setResponse', response);
    },
    toggleLeeonChallenge({ commit, state }) {
      commit('setLernBuddyOpen', !state.isOpen);
    },
    setChallengeId({ commit }, challengeId) {
      commit('setCurrentChallengeId', challengeId);
    }
  },

  getters: {
    lernBuddyResponse: state => state.response,
    currentChallengeId: state => state.currentChallengeId
  }
};
