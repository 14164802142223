import { collection, addDoc, getDocs, query, where, orderBy, updateDoc, doc, getDoc, getDocs as getDocsOriginal } from 'firebase/firestore';
import { cp } from '../../firebaseinit';

export default {
  namespaced: true,

  state: {
    challengeStats: [],
    isLoading: false,
    error: null
  },

  mutations: {
    setChallengeStats(state, stats) {
      state.challengeStats = stats;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setError(state, error) {
      state.error = error;
    },
    addChallengeCreated(state, { courseId, slideId, timestamp, id, strategyIndex }) {
      // This is for local state updates before persisting to Firestore
      state.challengeStats.push({
        id,
        courseId,
        slideId,
        timestamp,
        status: 'created',
        accepted: false,
        strategyIndex: strategyIndex !== undefined ? strategyIndex : null
      });
    },
    updateChallengeAccepted(state, { id }) {
      // Find the challenge in the local state and update its status
      const challenge = state.challengeStats.find(c => c.id === id);
      if (challenge) {
        challenge.status = 'accepted';
        challenge.accepted = true;
        console.log(`Challenge ${id} marked as accepted in local state`);
      } else {
        console.warn(`Challenge ${id} not found in local state for acceptance update`);
      }
    }
  },

  actions: {
    // Record when a challenge is created (called from AILearning.vue)
    async recordChallengeCreated({ commit }, { courseId, slideId, strategyIndex }) {
      try {
        commit('setLoading', true);

        const timestamp = new Date();

        // Add to Firestore
        const docRef = await addDoc(collection(cp, 'leeonChallengeStats'), {
          courseId,
          slideId,
          timestamp,
          status: 'created',
          accepted: false,
          strategyIndex: strategyIndex !== undefined ? strategyIndex : null, // Store the strategy index
        });

        // Update local state with the document ID
        commit('addChallengeCreated', {
          id: docRef.id,
          courseId,
          slideId,
          timestamp,
          strategyIndex
        });
        commit('setLoading', false);

        return docRef.id; // Return the ID for potential future use
      } catch (error) {
        console.error('Error recording challenge creation:', error);
        commit('setError', error.message);
        commit('setLoading', false);
        throw error;
      }
    },

    // Record when a challenge is accepted (called from LernBuddy.vue)
    async recordChallengeAccepted({ commit }, { courseId, slideId, challengeId }) {
      try {
        commit('setLoading', true);

        let docId = challengeId;

        // If no specific challengeId is provided, try to find the most recent created challenge
        // for this course and slide
        if (!docId) {
          console.log('No challengeId provided, searching for the most recent challenge');
          // First, get all challenges for this course and slide
          const q = query(
            collection(cp, 'leeonChallengeStats'),
            where('courseId', '==', courseId),
            where('slideId', '==', slideId)
          );

          console.log('Querying for challenges with:', { courseId, slideId });
          const querySnapshot = await getDocs(q);
          console.log(`Found ${querySnapshot.size} challenges for this course and slide`);

          // Then filter in memory to find the most recent created, non-accepted challenge
          let mostRecentChallenge = null;
          let mostRecentTimestamp = null;

          querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log('Examining challenge:', { id: doc.id, ...data });
            if (data.status === 'created' && data.accepted === false) {
              // Convert Firestore timestamp to JS Date for comparison
              const timestamp = data.timestamp.toDate();
              if (!mostRecentTimestamp || timestamp > mostRecentTimestamp) {
                mostRecentChallenge = doc;
                mostRecentTimestamp = timestamp;
              }
            }
          });

          if (mostRecentChallenge) {
            docId = mostRecentChallenge.id;
            console.log(`Found most recent challenge to accept: ${docId}`);
          } else {
            console.warn('No matching challenge found to accept. Recording a new accepted challenge.');

            // If no matching challenge is found, create a new one that's already accepted
            const timestamp = new Date();
            const newDocRef = await addDoc(collection(cp, 'leeonChallengeStats'), {
              courseId,
              slideId,
              timestamp,
              status: 'accepted',
              accepted: true,
              strategyIndex: null // Include strategyIndex field for consistency
            });
            console.log(`Created new accepted challenge with ID: ${newDocRef.id}`);

            commit('addChallengeCreated', {
              id: newDocRef.id,
              courseId,
              slideId,
              timestamp,
              strategyIndex: null
            });
            commit('updateChallengeAccepted', { id: newDocRef.id });
            commit('setLoading', false);
            return newDocRef.id;
          }
        } else {
          console.log(`Using provided challengeId: ${docId}`);
          // Verify that the document exists
          const challengeDoc = await getDoc(doc(cp, 'leeonChallengeStats', docId));
          if (!challengeDoc.exists()) {
            console.warn(`Challenge with ID ${docId} not found`);
            throw new Error(`Challenge with ID ${docId} not found`);
          }
        }

        // Update the existing document
        console.log(`Updating challenge ${docId} to accepted status`);
        const challengeRef = doc(cp, 'leeonChallengeStats', docId);

        await updateDoc(challengeRef, {
          status: 'accepted',
          accepted: true,
        });
        console.log(`Successfully updated challenge ${docId}`);

        // Update local state
        commit('updateChallengeAccepted', { id: docId });
        commit('setLoading', false);

        return docId;
      } catch (error) {
        console.error('Error recording challenge acceptance:', error);
        commit('setError', error.message);
        commit('setLoading', false);
        throw error;
      }
    },

    // Fetch all challenge statistics from Firestore
    async fetchChallengeStats({ commit }) {
      try {
        commit('setLoading', true);

        const q = query(
          collection(cp, 'leeonChallengeStats'),
          orderBy('timestamp', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const stats = [];

        querySnapshot.forEach((doc) => {
          stats.push({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp.toDate()
          });
        });

        commit('setChallengeStats', stats);
        commit('setLoading', false);
      } catch (error) {
        console.error('Error fetching challenge stats:', error);
        commit('setError', error.message);
        commit('setLoading', false);
      }
    }
  },

  getters: {
    // Get all challenge stats
    allChallengeStats: state => state.challengeStats,

    // Get stats grouped by month and course
    statsByMonthAndCourse: state => {
      const statsByMonth = {};

      state.challengeStats.forEach(stat => {
        const date = new Date(stat.timestamp);
        const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        const courseId = stat.courseId || 'unknown';

        if (!statsByMonth[monthYear]) {
          statsByMonth[monthYear] = {};
        }

        if (!statsByMonth[monthYear][courseId]) {
          statsByMonth[monthYear][courseId] = {
            created: 0,
            accepted: 0
          };
        }

        // Count all challenges created
        statsByMonth[monthYear][courseId].created++;

        // Only count accepted challenges
        if (stat.accepted) {
          statsByMonth[monthYear][courseId].accepted++;
        }
      });

      return statsByMonth;
    },

    // Get aggregated stats for all courses by month
    aggregatedStatsByMonth: (state, getters) => {
      const statsByMonth = getters.statsByMonthAndCourse;
      const aggregatedStats = {};

      Object.keys(statsByMonth).forEach(monthYear => {
        aggregatedStats[monthYear] = {
          created: 0,
          accepted: 0
        };

        Object.values(statsByMonth[monthYear]).forEach(courseStat => {
          aggregatedStats[monthYear].created += courseStat.created;
          aggregatedStats[monthYear].accepted += courseStat.accepted;
        });
      });

      return aggregatedStats;
    },

    // Get list of all course IDs that have challenge data
    courseIds: state => {
      const courseIds = new Set();

      state.challengeStats.forEach(stat => {
        if (stat.courseId) {
          courseIds.add(stat.courseId);
        }
      });

      return Array.from(courseIds);
    },

    // Get stats grouped by strategy index
    statsByStrategy: state => {
      const statsByStrategy = {
        0: { created: 0, accepted: 0, name: 'Active Recall and Elaboration' },
        1: { created: 0, accepted: 0, name: 'Case Study and Application' },
        2: { created: 0, accepted: 0, name: 'Problem-Solving Challenge' },
        3: { created: 0, accepted: 0, name: 'Conceptual Understanding Quiz' },
        4: { created: 0, accepted: 0, name: 'Critical Analysis Challenge' },
        null: { created: 0, accepted: 0, name: 'Unknown Strategy' }
      };

      state.challengeStats.forEach(stat => {
        const strategyKey = stat.strategyIndex !== undefined ? stat.strategyIndex : null;

        // Ensure the key exists in our stats object
        if (!statsByStrategy[strategyKey]) {
          statsByStrategy[strategyKey] = { created: 0, accepted: 0, name: `Strategy ${strategyKey}` };
        }

        // Count all challenges created
        statsByStrategy[strategyKey].created++;

        // Only count accepted challenges
        if (stat.accepted) {
          statsByStrategy[strategyKey].accepted++;
        }
      });

      return statsByStrategy;
    },

    // Check if data is currently loading
    isLoading: state => state.isLoading,

    // Get any error message
    error: state => state.error
  }
};
