<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Kundenwachstum</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-72 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          Diese Grafik zeigt das kumulative Wachstum der Kundenanzahl über die Zeit. 
          Jeder Kunde wird ab dem Startdatum seines ersten Kurses gezählt. 
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <Line
        v-if="chartData"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Lade Daten...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Line } from 'vue-chartjs';
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const chartData = ref(null);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `Gesamtanzahl Kunden: ${context.raw}`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Gesamtanzahl Kunden'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Datum'
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45
      }
    }
  }
};

const formatDate = (date) => {
  const d = new Date(date);
  return d.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'short'
  });
};

const updateChartData = async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    // Get first course date for each customer
    const customerFirstDates = new Map(); // Use Map to ensure one date per customer
    const excludedCourseIds = [
      "BfHGPoDdcIpDlWXmNjPZ",
      "l1DKt57j8FuR3dUR1fk6",
      "JiHhZsyMLB06ggMxkm0O"
    ];

    snapshot.forEach(doc => {
      const teamMateInfo = doc.data().TeamMateInfo;
      // Only process if TeamMateInfo exists and is an array
      if (Array.isArray(teamMateInfo) && teamMateInfo.length > 0) {
        const validCourses = teamMateInfo.filter(course => 
          !excludedCourseIds.includes(course.courseID) &&
          course.semStart &&
          isValidDate(course.semStart)
        );

        if (validCourses.length > 0) {
          // Sort courses by start date and get the earliest
          const firstDate = validCourses
            .reduce((earliest, course) => {
              const courseDate = new Date(course.semStart);
              return earliest ? (courseDate < new Date(earliest) ? course.semStart : earliest) : course.semStart;
            }, null);

          if (firstDate) {
            customerFirstDates.set(doc.id, firstDate); // Use customer ID as key
          }
        }
      }
    });

    // Convert to array and sort chronologically
    const sortedDates = Array.from(customerFirstDates.values())
      .map(date => new Date(date))
      .sort((a, b) => a - b);

    // Create cumulative data points by month
    const monthlyCount = new Map();
    let cumulative = 0;

    sortedDates.forEach(date => {
      const monthKey = new Date(date.getFullYear(), date.getMonth(), 1);
      cumulative++;
      monthlyCount.set(monthKey.getTime(), cumulative);
    });

    // Sort months and create final data
    const sortedMonths = Array.from(monthlyCount.entries())
      .sort(([a], [b]) => a - b)
      .map(([timestamp, count]) => ({
        date: new Date(timestamp),
        count
      }));

    // Prepare chart data
    chartData.value = {
      labels: sortedMonths.map(item => formatDate(item.date)),
      datasets: [
        {
          label: 'Gesamtanzahl Kunden',
          data: sortedMonths.map(item => item.count),
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          tension: 0.1,
          fill: true
        }
      ]
    };

    console.log('Customer growth data:', {
      totalCustomers: customerFirstDates.size,
      firstDate: formatDate(sortedDates[0]),
      lastDate: formatDate(sortedDates[sortedDates.length - 1]),
      monthlyData: sortedMonths.map(item => ({
        date: formatDate(item.date),
        count: item.count
      }))
    });

  } catch (error) {
    console.error('Error fetching user growth data:', error);
  }
};

// Helper function to validate dates
const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date) && date.getFullYear() >= 2000;
};

onMounted(() => {
  updateChartData();
});
</script> 