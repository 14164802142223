<template>
  <div class="flex-wrap -mx-8 justify-center  pt-8 hidden lg:flex">
    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/ExinCloud.png" class="w-24"
        alt=""></div>
    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/DevOpsMaster.png" class="w-24"
        alt=""></div>

    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/AgileScrum.png" class="w-24"
        alt=""></div>

    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/InformationSecFoundation.png"
        class="w-24" alt=""></div>

    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/PO_Bridge.png" class="w-24"
        alt=""></div>
    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/PO_Scrum.png" class="w-24"
        alt=""></div>
    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/Scrum.png" class="w-24" alt="">
    </div>
    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/VeriSM_Essential.png"
        class="w-24" alt=""></div>
    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/VeriSM_Foundation.png"
        class="w-24" alt=""></div>

    <div class="w-1/11 md:w-1/11 px-4 mb-8"><img loading="lazy" src="../assets/images/logo/ITSM_Foundation.png"
        class="w-24" alt=""></div>

  </div>
</template>
