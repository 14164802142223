<template>
  <nav class="bg-white shadow-md">
    <div class="max-w-7xl mx-auto p-2 sm:p-4">
      <div class="relative flex items-center justify-between h-14 sm:h-16">
        <!-- Desktop Navigation -->
        <div class="flex items-center">
          <div class="flex-shrink-0 mr-4 lg:mr-8">
            <router-link to="/"> <img loading="lazy" class="h-8 sm:h-10" src="../assets/images/Leecon.png"
                alt="Workflow"></router-link>
          </div>
          <div class="hidden lg:block">
            <div class="flex items-center space-x-4 xl:space-x-8">
              <Flyout_Katalog />
              <Flyout_Leeon />
              <Flyout_Service />
              <div class="relative inline-flex items-center group">
                <router-link
                  to="/case-studies"
                  class="relative group text-leeonDarkBlue hover:text-leeonPink transition-colors duration-300 whitespace-nowrap"
                >
                  <span class="relative">
                    Erfolge
                    <span class="absolute -bottom-1 left-0 w-3/4 h-0.5 bg-gradient-to-r from-leeonPink to-leeonBlue"></span>
                  </span>
                </router-link>
              </div>
              <div v-if="user" @click="toCustomerPortal"
                class="text-blue-250 hover:text-red-150 font-bold focus:text-red-150 active:text-red-150 px-4 py-2 rounded-full border-2 border-leeonPink bg-gradient-to-r from-white to-pink-50 shadow-sm hover:shadow-md transition-all duration-300 whitespace-nowrap"
                style="cursor: pointer">Kundenportal</div>
              <div style="cursor: pointer" @click="$router.push('/login')" v-if="!user"
                class="text-blue-250 hover:text-red-150 focus:text-red-150 active:text-red-150 px-4 py-2 rounded-full border-2 border-leeonPink bg-gradient-to-r from-white to-pink-50 shadow-sm hover:shadow-md transition-all duration-300 whitespace-nowrap">Kundenportal</div>
            </div>
          </div>
        </div>
        <p v-if="isDevelopment" class="hidden xl:block ml-12 text-blue-250">****Development Mode!****</p>
        <p v-if="isStaging" class="hidden xl:block ml-12 text-red-150">****Staging Mode!****</p>
        <div class="hidden lg:block" style="cursor:pointer">
          <div class="flex items-center">
            <div class="relative ml-4">
              <div @click="callMeBanner" class="text-blue-250 hover:text-red-150 active:text-red-150 whitespace-nowrap px-4">
                Kontakt
              </div>
            </div>
          </div>
        </div>
        <!--  Mobile Menu -->
        <div class="-mr-2 flex items-center lg:hidden">
          <button @click="toggle"
            class="text-blue-250 inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-leeonPink">
            <span class="sr-only">Open main menu</span>
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu overlay -->
    <div v-if="open"
      class="fixed inset-0 z-50 lg:hidden"
      aria-modal="true">
      <!-- Background backdrop -->
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
        @click="toggle"></div>

      <!-- Sliding menu panel -->
      <div class="relative flex flex-col w-4/5 max-w-sm h-full bg-white overflow-y-auto">
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button
            type="button"
            class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            @click="toggle">
            <span class="sr-only">Close menu</span>
            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Mobile menu content -->
        <div class="flex-shrink-0 px-4 py-4 flex items-center">
          <router-link to="/">
            <img loading="lazy" class="h-8" src="../assets/images/Leecon.png" alt="Leecon">
          </router-link>
        </div>

        <div class="mt-5 flex-1 px-4 space-y-4">
          <router-link to="/katalog" @click="toggle" class="block text-leeonDarkBlue hover:text-leeonPink py-2">
            Unsere Seminare
          </router-link>
          <router-link to="/KI_Weiterbildung" @click="toggle" class="block text-leeonDarkBlue hover:text-leeonPink py-2">
            KI-Kompetenz
          </router-link>
          <router-link to="/coaching" @click="toggle" class="block text-leeonDarkBlue hover:text-leeonPink py-2">
            Leeon Coaching
          </router-link>
          <router-link to="/futurelab" @click="toggle" class="block text-leeonDarkBlue hover:text-leeonPink py-2">
            Leeon Future Lab
          </router-link>
          <router-link to="/case-studies" @click="toggle" class="block text-leeonDarkBlue hover:text-leeonPink py-2">
            Erfolge
          </router-link>
          <router-link to="/faq" @click="toggle" class="block text-leeonDarkBlue hover:text-leeonPink py-2">
            FaQ
          </router-link>
          <router-link to="/vlog" @click="toggle" class="block text-leeonDarkBlue hover:text-leeonPink py-2">
            VLog
          </router-link>
          <a href="https://a-leecon.notion.site/ai-blog?v=12ca5cfa1ba68144b4bc000cd629b663&pvs=4"
            target="_blank"
            @click="toggle"
            class="block text-leeonDarkBlue hover:text-leeonPink py-2">
            AI-Blog
          </a>

          <div class="pt-4">
            <div v-if="user"
              @click="handleCustomerPortalClick"
              class="block text-center px-4 py-2 rounded-full border-2 border-leeonPink bg-gradient-to-r from-white to-pink-50 shadow-sm hover:shadow-md transition-all duration-300">
              <span class="text-blue-250 hover:text-red-150 font-bold">Kundenportal</span>
            </div>
            <div v-else
              @click="handleLoginClick"
              class="block text-center px-4 py-2 rounded-full border-2 border-leeonPink bg-gradient-to-r from-white to-pink-50 shadow-sm hover:shadow-md transition-all duration-300">
              <span class="text-blue-250 hover:text-red-150">Kundenportal</span>
            </div>
          </div>

          <div @click="handleCallMeBanner" class="block text-leeonDarkBlue hover:text-leeonPink py-2 mt-4">
            Kontakt
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, shallowRef } from 'vue'
import { auth } from "../firebaseinit";
import Flyout_Leeon from "./Flyout_Leeon.vue";
import Flyout_Service from "./Flyout_Service.vue";
import Flyout_Katalog from "./Flyout_Katalog.vue";

// Use shallowRef for non-reactive objects
const navigationItems = shallowRef([
  // ... your navigation items
])

// Cache DOM queries
const navRef = ref(null)

// Use v-once for static content
const currentLanguage = ref('Deutsch') // or use your i18n store value
const toggleLanguage = () => {
  // Your language toggle logic here
}
</script>

<script>
export default {
  components: {
    Flyout_Leeon,
    Flyout_Service,
    Flyout_Katalog,
  },
  data() {
    return {
      open: false,
      user: null,
      userID: null,
      showLink: true,
      isActive: false,
    };
  },
  created() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.userID = user.uid;
      } else {
        this.user = null;
      }
    });
  },
  computed: {
    isDevelopment() {
      return import.meta.env.MODE === 'development';
    },
    isStaging() {
      return import.meta.env.MODE === 'staging';
    }
  },
  methods: {
    toggle() {
      this.open = !this.open;
      // Prevent body scroll when menu is open
      if (this.open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
    handleCustomerPortalClick() {
      this.toggle(); // Close menu
      this.toCustomerPortal();
    },
    handleLoginClick() {
      this.toggle(); // Close menu
      this.$router.push("Login");
    },
    handleCallMeBanner() {
      this.toggle(); // Close menu
      this.callMeBanner();
    },
    toCustomerPortal() {
      if (this.user) {
        this.$router.push({
          name: "CustomerDashboard",
          params: { c_id: this.userID },
        });
      } else {
        this.$router.push("Login");
      }
    },
    callMeBanner() {
      this.$store.commit("callMeBanner");
    },
    logout() {
      auth.signOut().then(() => {
        this.$router.push("/");
      });
    },
  },
  beforeUnmount() {
    // Reset body overflow when component is destroyed
    document.body.style.overflow = '';
  }
};
</script>

<style>
@keyframes sparkle {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.sparkle-text {
  background: linear-gradient(
    90deg,
    #006ca3 0%,
    #e2007a 25%,
    #006ca3 50%,
    #e2007a 75%,
    #006ca3 100%
  );
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: sparkle 4s ease infinite;
  text-decoration: none;
}

.sparkle-text:hover {
  animation: sparkle 2s ease infinite;
}
</style>
