<template>
  <div class="relative mt-12 overflow-hidden bg-gradient-to-r from-leeonPink/10 to-leeonBlue/10 rounded-2xl p-1">
    <div class="relative bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow">
      <div class="absolute top-0 right-0 w-32 h-32 md:w-48 md:h-48 -mt-8 -mr-8 opacity-30">
        <img src="../assets/images/Leeon_standing_noBG.png" alt="" class="w-full h-full object-cover" />
      </div>
      
      <div class="relative">
        <h3 class="text-2xl font-bold text-leeonBlue mb-2">
          Ein Tag im KI-Manager-Kurs
          <span class="inline-block animate-bounce ml-2">👋</span>
        </h3>
        
        <p class="text-leeonDarkBlue mb-4">
          Erlebe einen typischen Dienstag bei uns: Von morgendlichem Selbstlernen bis zur 
          spannenden Live-Session – entdecke, wie modern Weiterbildung sein kann.
        </p>
        
        <p class="text-sm text-gray-500 italic mb-4">
          * Der Tagesablauf kann je nach Kurs, Trainer:in und Lernfortschritt variieren.
          Dies ist ein beispielhafter Einblick in unseren KI-Manager-Kurs.
        </p>
        
        <div class="flex flex-wrap gap-3 mb-6">
          <span class="px-3 py-1 bg-leeonPink/10 text-leeonPink rounded-full text-sm">
            Live-Sessions
          </span>
          <span class="px-3 py-1 bg-leeonBlue/10 text-leeonBlue rounded-full text-sm">
            KI-gestütztes Lernen
          </span>
          <span class="px-3 py-1 bg-leeonDarkBlue/10 text-leeonDarkBlue rounded-full text-sm">
            Praxisprojekte
          </span>
        </div>
        
        <router-link 
          to="/ki-manager-kurstag"
          class="inline-flex items-center px-6 py-3 bg-leeonPink text-white rounded-lg hover:bg-leeonPink/90 transition-colors group pulse-animation"
        >
          Tagesablauf entdecken
          <svg 
            class="ml-2 w-4 h-4 transition-transform group-hover:translate-x-1" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template> 