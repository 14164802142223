const state = {
  admin: false,
  adminArray: [
    "fzmMXqARvEayYu0NVPn6tJAMR8m1",
    "hlo1Owg50gMaeno4zZR5GuRzTCT2",
    "AZqpzJxvmzdY8VtuR2elsSNEQLv1",
    "FgVxI78REneJuC4K06QRrzgZRS33",
    "HzxBAVfrQwRek4FufJaYaPKqgrV2",
    "GWwNmC8ainYLu2zL9sBKUBK8Wv32",
    "Ph3QhBYtORSOUh6iQF7S3kQHrwx2",
    "NHy1fRImvQaMVRWdnIKcnDQ99Nh1",
    "ZyM0xWCaMuc22ET2jVNeOaiNV3E3",
    "vLYDoI5En8YnOFCnn1mSTgdShdI2",
    "0PUtnsTPQIVMM9qBB2oobAhz7xg1",
    "io9dAu2fFxQtL58UBUsKxjbWvBP2",
    "iT6wjncwqNXY0ATnR1h0ykcI2LL2",
    "O8xPdxsddTdNX1top4L4NFaKj7t1",
    "UAM6yxlKJKbWZZZTZ3pwoLJ7NDr1",
    "AxZQzy1DjVgh2Fyy6J4gEpo4tN52",
    "KpPxEHFlXoVwJRRjWim49opRqCZ2",
    "Du5ULGtsP7eHYH9pWVmtB1jLH7u2",
    "ANzspfwpP4dFeEtRDm6jrrKofLD2",
    "Skh9dsmxvsVZ4ybGKQEcqV0bVk23",
  ],
};

const getters = {
  isAdmin: (state) => {
    return state.admin;
  },
  adminArray: (state) => {
    return state.adminArray;
  },
};

const mutations = {
  SET_ADMIN: (state, admin) => {
    state.admin = admin;
  },
  ADD_ADMIN: (state, adminId) => {
    state.adminArray.push(adminId);
  },
};

const actions = {
  setAdmin: ({ commit }, payload) => {
    let Admin = false;
    state.adminArray.forEach((el) => {
      if (el === payload) {
        Admin = true;
      }
    });
    commit("SET_ADMIN", Admin);
  },
  addAdmin: ({ commit }, adminId) => {
    commit("ADD_ADMIN", adminId);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
