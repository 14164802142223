<script setup>
import { ref, onMounted, watch } from 'vue'
import CloseSidebarIcon from "../icons/CloseSidebarIcon.vue"

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  },
  customerData: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['close', 'form-submitted'])

const language = ref('de') // Default language is German

const formData = ref({
  education: '',
  professionalTraining: [],
  jobTitle: '',
  englishLevel: '',
  previousTraining: '',
  learningGoals: [],
  computerSkills: '',
  technicalEquipment: [],
})

// Initialize form with existing data when component mounts or customerData changes
watch(() => props.customerData, (newData) => {
  if (newData?.starterFormData) {
    formData.value = {
      ...formData.value,
      ...newData.starterFormData
    }
    // Set language if it was saved with the form
    if (newData.starterFormData.language) {
      language.value = newData.starterFormData.language
    }
  }
}, { immediate: true })

// Watch for visibility changes to reset form if it's being reopened
watch(() => props.isVisible, (newValue) => {
  if (newValue && props.customerData?.starterFormData) {
    formData.value = {
      ...formData.value,
      ...props.customerData.starterFormData
    }
    if (props.customerData.starterFormData.language) {
      language.value = props.customerData.starterFormData.language
    }
  }
})

const translations = {
  de: {
    title: 'Starter Fragebogen',
    education: {
      title: 'Dein höchster Bildungsabschluss ist:',
      options: [
        { value: 'none', label: 'Ich habe keinen Bildungsabschluss' },
        { value: 'hauptschule', label: 'Hauptschule' },
        { value: 'mittlere-reife', label: 'Mittlere Reife/gleichwertiger Abschluss' },
        { value: 'abitur', label: 'Abitur/Fachabitur' },
      ]
    },
    professionalTraining: {
      title: 'Deine höchste Berufsausbildung ist:',
      options: [
        { value: 'anerkannte', label: 'Anerkannte Berufsausbildung' },
        { value: 'meister', label: 'Meister/Techniker/gleichwertiger Fachschulabschluss' },
        { value: 'bachelor', label: 'Bachelor' },
        { value: 'master', label: 'Diplom/Magister/Master/Staatsexamen' },
        { value: 'promotion', label: 'Promotion' },
      ]
    },
    jobTitle: {
      title: 'Bitte trage deine Berufsbezeichnung ein:',
      placeholder: 'Deine Antwort'
    },
    englishLevel: {
      title: 'Bitte teile uns Deine Englisch-Sprachkenntnisse mit:',
      options: [
        { value: 'A1', label: 'A1 - Anfänger' },
        { value: 'A2', label: 'A2 - Grundlegende Kenntnisse' },
        { value: 'B1', label: 'B1 - Fortgeschrittene Sprachverwendung' },
        { value: 'B2', label: 'B2 - Selbständige Sprachverwendung' },
        { value: 'C1', label: 'C1 - Fachkundige Sprachkenntnisse' },
        { value: 'C2', label: 'C2 - Annähernd muttersprachliche Kenntnisse' },
      ]
    },
    previousTraining: {
      title: 'Hast Du dich früher schon beruflich weitergebildet?',
      placeholder: 'Falls ja, welche Zertifikate oder Lehrgänge hast du bereits absolviert? (optional)'
    },
    learningGoals: {
      title: 'Dein Ziel an dieser beruflichen Weiterbildung teilzunehmen ist:',
      options: [
        { value: 'improve-chances', label: 'Meine berufliche Weiterbildung soll meine Vermittlungschancen deutlich verbessern' },
        { value: 'practical-theoretical', label: 'Meine Berufserfahrung praktisch und theoretisch zu untermauern' },
        { value: 'time-available', label: 'Ich habe Zeit und möchte nicht mehr arbeiten gehen' },
      ]
    },
    computerSkills: {
      title: 'Wie gut sind deine EDV-Kenntnisse?',
      options: [
        { value: 'basic', label: 'EDV-Grundkenntnisse (z.B. Microsoft-Office: Word, Excel, PowerPoint etc.)' },
        { value: 'advanced', label: 'fundierte EDV-Kenntnisse (ich arbeite mit verschiedenen Programmen)' },
        { value: 'none', label: 'Ich kenne mich nicht aus' },
      ]
    },
    technicalEquipment: {
      title: 'Dein technisches Equipment besteht aus folgendem:',
      options: [
        { value: 'laptop', label: 'Laptop' },
        { value: 'computer', label: 'Computer' },
        { value: 'second-screen', label: 'Zweitbildschirm' },
        { value: 'tablet', label: 'Tablet' },
        { value: 'smartphone', label: 'Handy' },
        { value: 'headset', label: 'Headset' },
        { value: 'webcam', label: 'Webcam' },
        { value: 'none', label: 'Ich habe kein technisches Equipment' },
      ]
    },
    submit: 'Absenden'
  },
  en: {
    title: 'Starter Questionnaire',
    education: {
      title: 'Your highest educational qualification:',
      options: [
        { value: 'none', label: 'I have no educational qualification' },
        { value: 'hauptschule', label: 'Secondary School' },
        { value: 'mittlere-reife', label: 'Middle School/equivalent degree' },
        { value: 'abitur', label: 'High School/A-Levels' },
      ]
    },
    professionalTraining: {
      title: 'Your highest professional qualification:',
      options: [
        { value: 'anerkannte', label: 'Recognized Vocational Training' },
        { value: 'meister', label: 'Master Craftsman/Technician/equivalent degree' },
        { value: 'bachelor', label: 'Bachelor\'s Degree' },
        { value: 'master', label: 'Diploma/Master\'s Degree/State Examination' },
        { value: 'promotion', label: 'PhD' },
      ]
    },
    jobTitle: {
      title: 'Please enter your job title:',
      placeholder: 'Your answer'
    },
    englishLevel: {
      title: 'Please tell us your English language skills:',
      options: [
        { value: 'A1', label: 'A1 - Beginner' },
        { value: 'A2', label: 'A2 - Elementary' },
        { value: 'B1', label: 'B1 - Intermediate' },
        { value: 'B2', label: 'B2 - Upper Intermediate' },
        { value: 'C1', label: 'C1 - Advanced' },
        { value: 'C2', label: 'C2 - Near Native' },
      ]
    },
    previousTraining: {
      title: 'Have you undertaken professional development before?',
      placeholder: 'If yes, which certificates or courses have you completed? (optional)'
    },
    learningGoals: {
      title: 'Your goal in participating in this professional development is:',
      options: [
        { value: 'improve-chances', label: 'My professional development should significantly improve my job prospects' },
        { value: 'practical-theoretical', label: 'To underpin my professional experience practically and theoretically' },
        { value: 'time-available', label: 'I have time and don\'t want to work anymore' },
      ]
    },
    computerSkills: {
      title: 'How good are your computer skills?',
      options: [
        { value: 'basic', label: 'Basic computer skills (e.g., Microsoft Office: Word, Excel, PowerPoint etc.)' },
        { value: 'advanced', label: 'Advanced computer skills (I work with various programs)' },
        { value: 'none', label: 'I\'m not familiar with computers' },
      ]
    },
    technicalEquipment: {
      title: 'Your technical equipment consists of:',
      options: [
        { value: 'laptop', label: 'Laptop' },
        { value: 'computer', label: 'Computer' },
        { value: 'second-screen', label: 'Second Screen' },
        { value: 'tablet', label: 'Tablet' },
        { value: 'smartphone', label: 'Smartphone' },
        { value: 'headset', label: 'Headset' },
        { value: 'webcam', label: 'Webcam' },
        { value: 'none', label: 'I have no technical equipment' },
      ]
    },
    submit: 'Submit'
  }
}

const close = () => {
  emit('close')
}

const handleSubmit = async () => {
  try {
    emit('form-submitted', { ...formData.value, language: language.value })
    // Reset form after submission
    formData.value = {
      education: '',
      professionalTraining: [],
      jobTitle: '',
      englishLevel: '',
      previousTraining: '',
      learningGoals: [],
      computerSkills: '',
      technicalEquipment: [],
    }
    close()
  } catch (error) {
    console.error('Error submitting form:', error)
  }
}
</script>

<template>
  <div v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
    <div class="relative bg-white rounded-lg shadow-2xl max-w-3xl w-full m-4 max-h-[90vh] overflow-y-auto">
      <!-- Header with close button and language toggle -->
      <div class="sticky top-0 bg-white border-b border-gray-200 p-4 flex justify-between items-center z-10">
        <h2 class="text-2xl font-bold text-gray-900">{{ translations[language].title }}</h2>
        <div class="flex items-center gap-4">
          <!-- Language Toggle -->
          <div class="flex items-center gap-2 bg-gray-100 rounded-lg p-1">
            <button 
              @click="language = 'de'"
              :class="[
                'px-3 py-1 rounded-md text-sm font-medium transition-colors',
                language === 'de' 
                  ? 'bg-white text-blue-250 shadow-sm' 
                  : 'text-gray-600 hover:text-blue-250'
              ]"
            >
              DE
            </button>
            <button 
              @click="language = 'en'"
              :class="[
                'px-3 py-1 rounded-md text-sm font-medium transition-colors',
                language === 'en' 
                  ? 'bg-white text-blue-250 shadow-sm' 
                  : 'text-gray-600 hover:text-blue-250'
              ]"
            >
              EN
            </button>
          </div>
          <!-- Close Button -->
          <button @click="close" class="text-gray-400 hover:text-gray-600">
            <CloseSidebarIcon class="h-7 w-7" />
          </button>
        </div>
      </div>

      <!-- Form content -->
      <div class="p-6">
        <form @submit.prevent="handleSubmit" class="space-y-6">
          <!-- Education Level -->
          <div class="space-y-4 bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-medium text-gray-900">{{ translations[language].education.title }}</h3>
            <div class="space-y-2">
              <div v-for="option in translations[language].education.options" :key="option.value" class="flex items-center">
                <input
                  type="radio"
                  :id="option.value"
                  v-model="formData.education"
                  :value="option.value"
                  class="h-4 w-4 text-red-150 border-gray-300 focus:ring-red-150"
                >
                <label :for="option.value" class="ml-3 text-sm text-gray-700">{{ option.label }}</label>
              </div>
            </div>
          </div>

          <!-- Professional Training -->
          <div class="space-y-4 bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-medium text-gray-900">{{ translations[language].professionalTraining.title }}</h3>
            <div class="space-y-2">
              <div v-for="option in translations[language].professionalTraining.options" :key="option.value" class="flex items-center">
                <input
                  type="checkbox"
                  :id="option.value"
                  v-model="formData.professionalTraining"
                  :value="option.value"
                  class="h-4 w-4 text-red-150 rounded border-gray-300 focus:ring-red-150"
                >
                <label :for="option.value" class="ml-3 text-sm text-gray-700">{{ option.label }}</label>
              </div>
            </div>
          </div>

          <!-- Job Title -->
          <div class="space-y-4 bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-medium text-gray-900">{{ translations[language].jobTitle.title }}</h3>
            <input
              type="text"
              v-model="formData.jobTitle"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-150 focus:ring-red-150 sm:text-sm"
              :placeholder="translations[language].jobTitle.placeholder"
            >
          </div>

          <!-- English Level -->
          <div class="space-y-4 bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-medium text-gray-900">{{ translations[language].englishLevel.title }}</h3>
            <div class="space-y-2">
              <div v-for="level in translations[language].englishLevel.options" :key="level.value" class="flex items-center">
                <input
                  type="radio"
                  :id="level.value"
                  v-model="formData.englishLevel"
                  :value="level.value"
                  class="h-4 w-4 text-red-150 border-gray-300 focus:ring-red-150"
                >
                <label :for="level.value" class="ml-3 text-sm text-gray-700">{{ level.label }}</label>
              </div>
            </div>
          </div>

          <!-- Previous Training -->
          <div class="space-y-4 bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-medium text-gray-900">{{ translations[language].previousTraining.title }}</h3>
            <textarea
              v-model="formData.previousTraining"
              rows="3"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-150 focus:ring-red-150 sm:text-sm"
              :placeholder="translations[language].previousTraining.placeholder"
            ></textarea>
          </div>

          <!-- Learning Goals -->
          <div class="space-y-4 bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-medium text-gray-900">{{ translations[language].learningGoals.title }}</h3>
            <div class="space-y-2">
              <div v-for="goal in translations[language].learningGoals.options" :key="goal.value" class="flex items-center">
                <input
                  type="checkbox"
                  :id="goal.value"
                  v-model="formData.learningGoals"
                  :value="goal.value"
                  class="h-4 w-4 text-red-150 rounded border-gray-300 focus:ring-red-150"
                >
                <label :for="goal.value" class="ml-3 text-sm text-gray-700">{{ goal.label }}</label>
              </div>
            </div>
          </div>

          <!-- Computer Skills -->
          <div class="space-y-4 bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-medium text-gray-900">{{ translations[language].computerSkills.title }}</h3>
            <div class="space-y-2">
              <div v-for="skill in translations[language].computerSkills.options" :key="skill.value" class="flex items-center">
                <input
                  type="radio"
                  :id="skill.value"
                  v-model="formData.computerSkills"
                  :value="skill.value"
                  class="h-4 w-4 text-red-150 border-gray-300 focus:ring-red-150"
                >
                <label :for="skill.value" class="ml-3 text-sm text-gray-700">{{ skill.label }}</label>
              </div>
            </div>
          </div>

          <!-- Technical Equipment -->
          <div class="space-y-4 bg-gray-50 p-4 rounded-lg">
            <h3 class="text-lg font-medium text-gray-900">{{ translations[language].technicalEquipment.title }}</h3>
            <div class="space-y-2">
              <div v-for="equipment in translations[language].technicalEquipment.options" :key="equipment.value" class="flex items-center">
                <input
                  type="checkbox"
                  :id="equipment.value"
                  v-model="formData.technicalEquipment"
                  :value="equipment.value"
                  class="h-4 w-4 text-red-150 rounded border-gray-300 focus:ring-red-150"
                >
                <label :for="equipment.value" class="ml-3 text-sm text-gray-700">{{ equipment.label }}</label>
              </div>
            </div>
          </div>

          <!-- Submit Button -->
          <div class="flex justify-end pt-5">
            <button
              type="submit"
              class="ml-auto border border-red-150 bg-white text-red-150 hover:shadow-md py-3 px-6 text-sm rounded-full"
            >
              {{ translations[language].submit }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style> 