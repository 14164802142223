import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { version } from "./config/version";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseinit";

const environment = import.meta.env.DEV ? "dev" : "prod";
const defaultUserConsent = "granted";
let currentUserConsent = defaultUserConsent;

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: import.meta.env.VITE_DATADOG_SITE,
  service: import.meta.env.VITE_DATADOG_SERVICE,
  env: environment,
  version: version.number,
  sessionSampleRate: 100,
  sessionReplaySampleRate: import.meta.env.DEV ? 100 : 20,
  trackingConsent: currentUserConsent,
  enablePrivacyForActionName: true,
  defaultPrivacyLevel: "mask-user-input",
  storeContextsAcrossPages: true,
});

datadogLogs.init({
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: import.meta.env.VITE_DATADOG_SITE,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: import.meta.env.VITE_DATADOG_SERVICE,
  env: environment,
  version: version.number,
  trackingConsent: currentUserConsent,
});

// Set up auth state listener to update Datadog user info
onAuthStateChanged(auth, (user) => {
  if (user) {
    // To determine if Terms of Service overrides the consent, we check if the user is a lead, customer or employee
    // - Only track if the user is a lead and consented to analytics
    // - Always track customer or employee
    try {
      // Validate if the user consented to analytics cookies before trying to set user information
      const analyticsChoiseMade = getCkyConsent().isUserActionCompleted;
      const analyticsConsentGranted = getCkyConsent().categories.analytics;
      // Enable tracking if the user consented to analytics
      if (analyticsChoiseMade && analyticsConsentGranted) {
        currentUserConsent = "granted";
        datadogRum.setTrackingConsent(currentUserConsent);
        datadogLogs.setTrackingConsent(currentUserConsent);
        // Set user information in Datadog
        datadogRum.setUser({
          id: user.uid,
          email: user.email,
          name: user.displayName || "Unknown",
          verified: user.emailVerified,
          createdAt: user.metadata.creationTime,
          lastLoginAt: user.metadata.lastSignInTime,
        });
      }
      else {
        currentUserConsent = "not-granted";
        datadogRum.setTrackingConsent(currentUserConsent);
        datadogLogs.setTrackingConsent(currentUserConsent);
      }
    } catch (error) {
      datadogLogs.logger.error("Error setting user information for signed in user", {error: error});
    }
  } else {
    // If the user is not logged in, we remove the user information on signout
    datadogRum.clearUser();
  }
});

/**
 * Event listener for CookieYes consent updates
 * Updates Datadog tracking consent based on user's analytics preferences
 */
document.addEventListener("cookieyes_consent_update", (eventData) => {
  if (eventData.origin !== "https://cdn-cookieyes.com") {
    datadogLogs.logger.warn("Unexpected origin of cookie consent event.", {origin: eventData.origin});
  } else {
    try {
      const data = eventData?.detail;

    if (!data) {
      datadogLogs.logger.warn("Cookie consent update event received with no detail data");
      return;
    }

    if (data.accepted?.includes("analytics")) {
      currentUserConsent = "granted"
      datadogRum.setTrackingConsent(currentUserConsent);
      datadogLogs.setTrackingConsent(currentUserConsent);
      datadogLogs.logger.info("Analytics tracking consent granted by user");
      } else {
        datadogLogs.logger.info("Analytics tracking consent declined by user");
      }
    } catch (error) {
      datadogLogs.logger.error("Error processing cookie consent update", { error });
    }
  }
});

export { datadogRum, datadogLogs };
