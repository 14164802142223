<template>
  <section class="py-16 px-4 relative overflow-hidden">
    <!-- Background decoration -->
    <div class="absolute inset-0 bg-gradient-to-br from-leeonBlue20 to-leeonDarkBlue20 opacity-50"></div>
    <div class="absolute -right-10 -top-10 w-40 h-40 bg-leeonBlue20 rounded-full blur-3xl opacity-20"></div>
    <div class="absolute -left-10 -bottom-10 w-40 h-40 bg-leeonPink20 rounded-full blur-3xl opacity-20"></div>
    
    <div class="max-w-3xl mx-auto text-center relative">
      <!-- Logo Section -->
      <div class="flex items-center justify-center flex-shrink-0 px-6 mb-8">
        <img loading="lazy" class="h-16 w-auto" src="../assets/images/Leecon.png" alt="Advanced Leecon">
      </div>
      
      <!-- Decorative element -->
      <div class="flex justify-center mb-8">
        <div class="w-20 h-1 bg-gradient-to-r from-leeonBlue to-leeonPink rounded-full"></div>
      </div>
      
      <!-- Main Claim -->
      <h2 class="text-3xl font-bold sm:text-4xl mb-3 bg-clip-text text-transparent bg-gradient-to-r from-leeonBlue to-blue-150">
        Bildung für die Menschheit von Morgen
      </h2>
      
      <!-- English Translation -->
      <p class="text-xl text-leeonDarkBlue60 mb-8 italic">
        Educate Tomorrow's Humanity
      </p>
      
      <p class="text-lg text-blue-250 leading-relaxed backdrop-blur-sm">
        Bei A-Leecon bereiten wir Menschen darauf vor, in einer sich schnell entwickelnden Welt erfolgreich zu sein. Dies erreichen wir durch die Integration modernster KI-Technologie bei gleichzeitiger Fokussierung auf menschliches Wohlbefinden, Zusammenarbeit, Innovation und ethische Verantwortung. Unser Ziel ist es, Bildung neu zu definieren, indem wir Lernende dazu befähigen, aktiv an der Gestaltung einer besseren Zukunft für die Menschheit und unsere Umwelt mitzuwirken.
      </p>
      
      <!-- Decorative dots -->
      <div class="absolute -right-4 top-1/2 w-24 h-24 opacity-10">
        <div class="grid grid-cols-3 gap-2">
          <div v-for="i in 9" :key="i" class="w-2 h-2 rounded-full bg-leeonPink"></div>
        </div>
      </div>
    </div>
  </section>
</template>
