<template>
  <section class="py-12 px-4">
    <div class="flex flex-wrap justify-center items-center gap-4 mb-12">
      <h2 class="text-4xl text-center text-blue-250 leading-tight">
        Lieblingsseminare
      </h2> 
      <img loading="lazy" src="../assets/images/centuria.png" alt="Centuria Logo" class="h-16 sm:h-20">
    </div>

    <!-- Loading State -->
    <div v-if="isLoading" class="flex justify-center items-center py-20">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-250"></div>
    </div>

    <!-- Error State -->
    <div v-if="error" class="rounded-md bg-red-50 p-4 mt-8">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-red-700">{{ error }}</p>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div v-if="!isLoading && !error" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <router-link v-for="(course, i) in all" 
           :key="i"
           :to="course.modules ? `/${course.id}/Bundle` : `/${course.id}/Seminar`"
           class="group block">
        <div class="bg-white rounded-lg overflow-hidden shadow-md transform transition-all duration-300 
                    hover:-translate-y-1 hover:shadow-xl">
          <div class="relative overflow-hidden">
            <img loading="lazy" 
                 :src="course.imageLink"
                 class="h-48 w-full object-cover transition-transform duration-500 group-hover:scale-110" 
                 :alt="course.name" />
            <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
              <div class="flex items-center gap-1">
                <svg v-for="i in 5" 
                     :key="i" 
                     class="h-4 w-4 fill-current transition-colors duration-300"
                     :class="i <= course.timebox ? 'text-green-400' : 'text-gray-400'" 
                     viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>

          <div class="p-5">
            <h3 class="text-xl font-bold text-blue-250 mb-2 group-hover:text-blue-600 transition-colors duration-300">
              {{ course.name }}
            </h3>
            <p class="text-gray-600 leading-relaxed line-clamp-2 mb-3">
              {{ course.shortdescription }}
            </p>
            <div class="flex items-center text-blue-250 group-hover:text-blue-600 transition-colors duration-300">
              <span>Mehr erfahren</span>
              <svg class="w-4 h-4 ml-1 transform transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
              </svg>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="rounded-md bg-blue-50 p-6 mt-12 shadow-sm hover:shadow-md transition-shadow duration-300">
      <div class="flex flex-col md:flex-row md:items-center justify-between gap-4">
        <p class="text-lg text-blue-250 font-medium">
          Entdecke weitere spannende Seminare in unserem Katalog
        </p>
        <router-link to="/katalog" 
          class="inline-flex items-center text-blue-250 hover:text-blue-600 transition-colors duration-300 font-medium">
          Zum Seminarkatalog
          <svg class="w-5 h-5 ml-2 transform transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
          </svg>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseinit";
import { useCourseStore } from '../store/courses';

export default {
  name: "Lieblingsseminare",
  data() {
    return {
      courses: [],
      bundles: [],
      all: [],
      courseStore: null,
      isLoading: true,
      error: null,
      lieblingsseminare: [
        'YjdbgQr3VDlAO3Tdfy2P',
        'uZUpZZjl1kFWLPD9qX9t',
        '0ujoO2iUFlDTx4WBxSMe',
        'ZRqrvsxc05mG7760PzOZ',
        '6IQf3rYYLJ1cCdlykEy0',
        'ZvfPnGO05zibD2Vbyt3n',
      ]
    };
  },
  created() {
    this.courseStore = useCourseStore();
  },
  async mounted() {
    try {
      this.isLoading = true;
      this.error = null;

      const coursePromises = this.lieblingsseminare.map(async (id) => {
        try {
          const docRef = doc(db, "courses", id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            let data = docSnap.data();
            data.id = docSnap.id;
            data.timebox = this.calculateTimeBox(data.units);
            return data;
          }
          return null;
        } catch (err) {
          console.error(`Error fetching course ${id}:`, err);
          return null;
        }
      });

      const bundlePromises = this.lieblingsseminare.map(async (id) => {
        try {
          const docRef = doc(db, "bundles", id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            let data = docSnap.data();
            data.id = docSnap.id;
            data.timebox = this.calculateTimeBox(data.units);
            return data;
          }
          return null;
        } catch (err) {
          console.error(`Error fetching bundle ${id}:`, err);
          return null;
        }
      });

      const [courses, bundles] = await Promise.all([
        Promise.all(coursePromises),
        Promise.all(bundlePromises)
      ]);

      this.courses = courses.filter(Boolean);
      this.bundles = bundles.filter(Boolean);
      this.all = [...this.courses, ...this.bundles];

      if (this.all.length === 0) {
        this.error = "Keine Seminare gefunden. Bitte versuchen Sie es später erneut.";
      }
    } catch (err) {
      console.error('Error loading seminars:', err);
      this.error = "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    calculateTimeBox(units) {
      if (units <= 160) return 1;
      if (units <= 320) return 2;
      if (units <= 640) return 3;
      if (units <= 960) return 4;
      return 5;
    }
  }
};
</script>

<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
