<template>
  <div class="py-12 px-4">
    <div class="flex flex-wrap items-center max-w-5xl mx-auto">
      <div class="lg:w-1/2 px-8 mb-8 lg:mb-0">

        <div class='embed-container mb-10  shadow'><iframe :src="video.id" frameborder='0' webkitAllowFullScreen
            mozallowfullscreen allowFullScreen></iframe></div>

      </div>

      <div class="lg:w-1/2 px-8 mb-8 lg:mb-0">
        <h3 class="text-2xl mb-2 font-heading text-blue-250">
          Angebot für geförderte Maßnahme
        </h3>
        <p class="mb-4 text-gray-500 leading-relaxed">
          In unserem <a style="cursor: pointer" class=" font-bold" @click="$router.push('/signup')"><span
              class="text-red-150 hover:underline">Kundenportal</span></a> kannst du
          dir dein Angebot für die Arbeitsagentur zum Download selber erstellen.
          Bitte registriere dich dazu in unserem Kundenportal. Mit dem Angebot
          kannst du dich an deine Arbeitsagentur wenden und einen
          <a style="cursor: pointer" class=" font-bold" @click="$router.push('/signup')"><span
              class="text-red-150 hover:underline">Bildungsgutschein</span></a> beantragen.
        </p>
        <a style="cursor: pointer" class="text-blue-250 hover:underline font-bold" @click="$router.push('/signup')">zum
          Kundenportal <i class="fas fa-hand-point-right"></i></a>
      </div>
      <div class="lg:w-1/2 px-8 mb-8 lg:mb-0 lg:order-1 mt-32">
        <img loading="lazy" class="lg:max-w-sm mx-auto" src="../assets/images/contact.svg" alt="" />
      </div>

      <div class="lg:w-1/2 px-8 mt-16">
        <h3 class="text-2xl mb-2 text-blue-250 font-heading">
          Bildungsberatung
        </h3>
        <p class="mb-4 text-gray-500 leading-relaxed">
          Natürlich beraten wir dich gerne persönlich und stellen mit dir dein
          optimales Bildungsprogramm zusammen. Kontaktiere uns einfach. Wir
          freuen uns auf dich!
        </p>
        <div>
          <div class="mb-2">
            <img loading="lazy" class="inline-block w-8 h-8 pr-2" src="../assets/images/mobile.svg" alt="" /><span
              class="text-gray-500 leading-relaxed">
              0800 0003704 </span>
          </div>
          <div class="mb-4 flex items-center">
            <div class="inline-flex items-center justify-center w-8 h-8 mr-2 bg-green-500 rounded-full">
              <i class="fab fa-whatsapp text-white fa-lg"></i>
            </div>
            <a href="http://wa.me/491722439836/?text=Hi%20advanced%20Leecon," 
               class="text-gray-700 hover:text-green-600 transition-colors duration-300 leading-relaxed flex items-center">
              <span class="mr-2">0172-24 39 836</span>
              <span class="px-2 py-1 text-xs font-medium bg-green-100 text-green-800 rounded-full">
                Schnelle Antwort!
              </span>
            </a>
          </div>
          <div class="mb-2">
            <img loading="lazy" class="inline-block w-8 h-8 pr-2" src="../assets/images/message.svg" alt="" /><span
              class="text-gray-500 leading-relaxed"> beratung@a-leecon.de</span>
          </div>

        </div>
      </div>
    </div>
    <div class="flex flex-wrap items-center max-w-5xl mx-auto mt-16">
      <div class="lg:w-1/2 px-8 mb-8 lg:mb-0">
        <img loading="lazy" class="lg:max-w-sm mx-auto" src="../assets/images/business_deal.svg" alt="" />
      </div>

      <div class="lg:w-1/2 px-8 mb-8 lg:mb-0">
        <h3 class="text-2xl mb-2 font-heading text-blue-250">
          Kooperation mit Firmenkunden
        </h3>
        <p class="mb-4 text-gray-500 leading-relaxed">
          Wir kooperieren für dich mit Firmenkunden. Deshalb ist eine Weiterbildung bei der <span
            class="text-red-150">Advanced</span> Leecon optimal angepasst an die Nachfrage am Arbeitsmarkt. Für
          erfolgreiche Teilnehmer und <span class="text-red-150">Bewerbungskandidaten</span> stellen wir gern
          den Kontakt zu unseren Kooperationspartnern her.

        </p>

      </div>

    </div>
    
    <!-- WhatsApp CTA Banner -->
    <div class="max-w-5xl mx-auto mt-16 px-8">
      <div class="bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="flex flex-col md:flex-row">
          <div class="bg-green-500 text-white p-6 md:w-1/3 flex flex-col justify-center items-center">
            <i class="fab fa-whatsapp fa-4x mb-4"></i>
            <h3 class="text-xl font-bold">Direkt chatten!</h3>
            <p class="text-center mt-2">Schnelle & unkomplizierte Beratung</p>
          </div>
          <div class="p-6 md:w-2/3">
            <h3 class="text-xl font-bold text-gray-800 mb-2">Kontaktiere uns per WhatsApp</h3>
            <p class="text-gray-600 mb-4">Hast du Fragen zu unseren Weiterbildungen oder Bildungsgutscheinen? Schreib uns einfach eine Nachricht per WhatsApp und wir melden uns schnellstmöglich bei dir zurück!</p>
            <a href="http://wa.me/491722439836/?text=Hi%20advanced%20Leecon," 
               class="inline-flex items-center px-5 py-2 bg-green-500 hover:bg-green-600 text-white font-medium rounded-lg transition-colors duration-300">
              <i class="fab fa-whatsapp mr-2"></i>
              WhatsApp starten
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      video: {
        question:
          "Wie kann ich mir selber ein Angebot für die Arbeitsagentur erstellen? ",
        author: "Nadine Wischmeier",
        id: "https://player.vimeo.com/video/548767860",
      },
    };
  },
};
</script>

<style scoped>
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
