<template>
  <div>
    <h3 class="text-lg font-medium text-gray-900 mb-4">Trainer Feedback Entwicklung</h3>
    <div class="flex flex-col space-y-4">
      <div class="flex justify-between items-center gap-4">
        <div class="flex-1 max-w-md">
          <select v-model="selectedTrainer" class="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-150 focus:ring-blue-150">
            <option value="">Trainer:in auswählen</option>
            <option v-for="trainer in availableTrainers" 
                    :key="trainer" 
                    :value="trainer">
              {{ trainer }}
            </option>
          </select>
        </div>
        <div class="flex gap-4">
          <select v-model="timeGrouping" class="rounded-md border-gray-300 shadow-sm focus:border-blue-150 focus:ring-blue-150">
            <option value="month">Monatlich</option>
            <option value="quarter">Quartalsweise</option>
            <option value="year">Jährlich</option>
          </select>
          <select v-model="timeRange" class="rounded-md border-gray-300 shadow-sm focus:border-blue-150 focus:ring-blue-150">
            <option value="365">Letztes Jahr</option>
            <option value="730">Letzte 2 Jahre</option>
            <option value="1095">Letzte 3 Jahre</option>
            <option value="0">Alle Zeit</option>
          </select>
        </div>
      </div>
      <div v-if="error" class="text-red-500 text-sm">
        {{ error }}
      </div>
      <div class="h-[400px] relative">
        <div v-if="loading" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-250"></div>
        </div>
        <canvas ref="trendChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { collection, getDocs } from 'firebase/firestore';
import { cp } from '../../../firebaseinit';
import Chart from 'chart.js/auto';

const trendChart = ref(null);
const selectedTrainer = ref('');
const timeGrouping = ref('month');
const timeRange = ref('365');
const availableTrainers = ref([]);
const loading = ref(false);
const error = ref('');
let chart = null;

// Reuse scoring system from CourseFeedbackChart
const feedbackScores = {
  teachingContent: {
    yes: 1,
    partially: 0.5,
    no: 0
  },
  satisfaction: {
    very: 1,
    satisfied: 0.75,
    less: 0.25,
    not: 0
  },
  instructorAndFeedback: {
    yes: 1,
    partially: 0.5,
    no: 0
  },
  technicalSupport: {
    fully: 1,
    partially: 0.5,
    not: 0
  },
  leconSupport: {
    very: 1,
    somewhat: 0.5,
    barely: 0
  }
};

const calculateCategoryScore = (feedback, category) => {
  let total = 0;
  let count = 0;

  if (category === 'teachingContent') {
    const fields = [
      'contentUnderstandable',
      'sufficientTime',
      'learningMethods',
      'professionalDevelopment',
      'meetExpectations',
      'priorKnowledge'
    ];
    
    fields.forEach(field => {
      if (feedback.teachingContent[field]) {
        total += feedbackScores.teachingContent[feedback.teachingContent[field]] || 0;
        count++;
      }
    });
  } else if (category === 'professionalGuidance') {
    // Instructor Support
    if (feedback.professionalGuidance?.instructorSupport) {
      total += feedbackScores.instructorAndFeedback[feedback.professionalGuidance.instructorSupport] || 0;
      count++;
    }
    
    // Regular Feedback
    if (feedback.professionalGuidance?.regularFeedback) {
      total += feedbackScores.instructorAndFeedback[feedback.professionalGuidance.regularFeedback] || 0;
      count++;
    }
    
    // Technical Support
    if (feedback.professionalGuidance?.technicalSupport) {
      total += feedbackScores.technicalSupport[feedback.professionalGuidance.technicalSupport] || 0;
      count++;
    }
    
    // Lecon Support
    if (feedback.professionalGuidance?.leconSupport) {
      total += feedbackScores.leconSupport[feedback.professionalGuidance.leconSupport] || 0;
      count++;
    }
  } else if (category === 'satisfaction') {
    if (feedback.satisfaction?.overallSatisfaction) {
      total += feedbackScores.satisfaction[feedback.satisfaction.overallSatisfaction] || 0;
      count++;
    }
  }

  return count > 0 ? (total / count) * 100 : 0;
};

const getTimeGroupKey = (date) => {
  const year = date.getFullYear();
  if (timeGrouping.value === 'year') {
    return `${year}`;
  } else if (timeGrouping.value === 'quarter') {
    const quarter = Math.floor(date.getMonth() / 3) + 1;
    return `${year} Q${quarter}`;
  } else {
    const month = date.getMonth() + 1;
    return `${year}-${month.toString().padStart(2, '0')}`;
  }
};

const formatTimeGroupLabel = (key) => {
  if (timeGrouping.value === 'year') {
    return key;
  } else if (timeGrouping.value === 'quarter') {
    return key;
  } else {
    const [year, month] = key.split('-');
    const date = new Date(parseInt(year), parseInt(month) - 1);
    return date.toLocaleDateString('de-DE', { year: 'numeric', month: 'short' });
  }
};

const fetchTrainers = async () => {
  const customersRef = collection(cp, 'customers');
  const snapshot = await getDocs(customersRef);
  const trainerSet = new Set();

  snapshot.forEach(doc => {
    const customerData = doc.data();
    if (customerData.courseFeedback) {
      Object.values(customerData.courseFeedback).forEach(feedback => {
        // Find trainer for this feedback
        let trainerName = '';
        if (customerData.TeamMateInfo) {
          const courseInfo = customerData.TeamMateInfo.find(info => 
            info.courseID === feedback.courseId || 
            info.name === feedback.courseName
          );
          if (courseInfo?.trainer) {
            trainerName = typeof courseInfo.trainer === 'object' 
              ? courseInfo.trainer.firstname 
              : courseInfo.trainer;
            if (trainerName) {
              trainerSet.add(trainerName);
            }
          }
        }
      });
    }
  });

  availableTrainers.value = Array.from(trainerSet).sort();
};

const fetchTrendData = async () => {
  if (!selectedTrainer.value) {
    error.value = 'Bitte wählen Sie eine:n Trainer:in aus';
    return null;
  }

  loading.value = true;
  error.value = '';
  
  try {
    const endDate = new Date();
    const startDate = timeRange.value === '0' ? new Date(0) : new Date();
    if (timeRange.value !== '0') {
      startDate.setDate(startDate.getDate() - parseInt(timeRange.value));
    }

    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    const timeGroups = new Map();

    snapshot.forEach(doc => {
      const customerData = doc.data();
      if (customerData.courseFeedback) {
        Object.values(customerData.courseFeedback).forEach(feedback => {
          // Find trainer for this feedback
          let trainerName = '';
          if (customerData.TeamMateInfo) {
            const courseInfo = customerData.TeamMateInfo.find(info => 
              info.courseID === feedback.courseId || 
              info.name === feedback.courseName
            );
            if (courseInfo?.trainer) {
              trainerName = typeof courseInfo.trainer === 'object' 
                ? courseInfo.trainer.firstname 
                : courseInfo.trainer;
            }
          }

          if (trainerName !== selectedTrainer.value) return;

          // Process timestamp
          if (!feedback.timestamp) return;
          let feedbackDate;
          if (typeof feedback.timestamp === 'string') {
            feedbackDate = new Date(feedback.timestamp);
          } else if (feedback.timestamp?.seconds) {
            feedbackDate = new Date(feedback.timestamp.seconds * 1000);
          } else if (feedback.timestamp instanceof Date) {
            feedbackDate = feedback.timestamp;
          } else {
            return;
          }

          if (feedbackDate < startDate || feedbackDate > endDate) return;

          const timeKey = getTimeGroupKey(feedbackDate);
          if (!timeGroups.has(timeKey)) {
            timeGroups.set(timeKey, {
              teachingContent: [],
              professionalGuidance: [],
              satisfaction: []
            });
          }

          const scores = {
            teachingContent: calculateCategoryScore({
              teachingContent: feedback.teachingContent
            }, 'teachingContent'),
            professionalGuidance: calculateCategoryScore({
              professionalGuidance: feedback.professionalGuidance
            }, 'professionalGuidance'),
            satisfaction: calculateCategoryScore({
              satisfaction: feedback.satisfaction
            }, 'satisfaction')
          };

          timeGroups.get(timeKey).teachingContent.push(scores.teachingContent);
          timeGroups.get(timeKey).professionalGuidance.push(scores.professionalGuidance);
          timeGroups.get(timeKey).satisfaction.push(scores.satisfaction);
        });
      }
    });

    // Convert to arrays and calculate averages
    const sortedKeys = Array.from(timeGroups.keys()).sort();
    const data = {
      labels: sortedKeys.map(formatTimeGroupLabel),
      teachingContent: [],
      professionalGuidance: [],
      satisfaction: []
    };

    sortedKeys.forEach(key => {
      const group = timeGroups.get(key);
      data.teachingContent.push(
        group.teachingContent.reduce((a, b) => a + b, 0) / group.teachingContent.length || 0
      );
      data.professionalGuidance.push(
        group.professionalGuidance.reduce((a, b) => a + b, 0) / group.professionalGuidance.length || 0
      );
      data.satisfaction.push(
        group.satisfaction.reduce((a, b) => a + b, 0) / group.satisfaction.length || 0
      );
    });

    if (sortedKeys.length === 0) {
      error.value = `Keine Feedback-Daten für ${selectedTrainer.value} im ausgewählten Zeitraum verfügbar`;
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error fetching trend data:', error);
    error.value = 'Fehler beim Laden der Trend-Daten';
    return null;
  } finally {
    loading.value = false;
  }
};

const updateChart = async () => {
  const data = await fetchTrendData();
  if (!data) return;

  if (chart) {
    chart.destroy();
  }

  const ctx = trendChart.value.getContext('2d');
  chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: data.labels,
      datasets: [
        {
          label: 'Lehrinhalte',
          data: data.teachingContent,
          borderColor: 'rgb(59, 130, 246)',
          backgroundColor: 'rgba(59, 130, 246, 0.1)',
          tension: 0.4
        },
        {
          label: 'Fachliche Begleitung',
          data: data.professionalGuidance,
          borderColor: 'rgb(16, 185, 129)',
          backgroundColor: 'rgba(16, 185, 129, 0.1)',
          tension: 0.4
        },
        {
          label: 'Zufriedenheit',
          data: data.satisfaction,
          borderColor: 'rgb(245, 158, 11)',
          backgroundColor: 'rgba(245, 158, 11, 0.1)',
          tension: 0.4
        }
      ]
    },
    options: {
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback: value => `${value}%`
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              return `${context.dataset.label}: ${context.parsed.y.toFixed(1)}%`;
            }
          }
        }
      }
    }
  });
};

watch([selectedTrainer, timeGrouping, timeRange], () => {
  updateChart();
});

onMounted(async () => {
  await fetchTrainers();
});
</script> 