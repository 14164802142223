<template>
  <div>
    <div class="container mx-auto px-4 py-12">
      <!-- Main content wrapper with improved spacing -->
      <div class="flex flex-col lg:flex-row items-center gap-12">
        <!-- Left content section -->
        <div class="lg:w-1/2 space-y-8">
          <!-- Header section -->
          <div class="text-center lg:text-left">
            <h1 class="text-4xl font-bold text-blue-250">
              KI.Bildung.Zukunft <br>
              <span class="text-red-150">Bereit, wenn du es bist</span>
            </h1>
            <h2 class="text-2xl mt-2 text-blue-250">Mit staatlicher Förderung zum Digital-Experten</h2>
          </div>

          <!-- Main text content -->
          <div class="text-lg text-blue-250 tracking-wide space-y-4">
            <p>
              Transformiere jetzt Deine Karriere mit unseren innovativen <span
                class="font-semibold">KI-Weiterbildungsprogrammen</span>.
              Als AZAV-zertifizierter Bildungsträger bieten wir Dir:
            </p>
            <ul class="list-disc list-inside ml-4 space-y-2">
              <li>100% förderungsfähige Kurse mit <span class="font-semibold">Bildungsgutschein</span> der Arbeitsagentur und dem <span class="font-semibold">Qualifizierungschancengesetz (QCG)</span>
              </li>
              <li>Eine exklusive <span class="font-semibold">Boutique E-Learning</span>-Erfahrung mit Fachexperten
              </li>
              <li>Individuelle Betreuung in kleinen Gruppen und Personalisierung durch künstliche Intelligenz</li>
              <li>KI-gestütztes Lernen mit modernster Technologie und praxisorientierten Inhalten</li>
            </ul>
          </div>

          <!-- CTA button -->
          <div class="text-center lg:text-left">
            <router-link to="/katalog" class="inline-block mr-4">
              <button
                class="bg-red-150 hover:bg-blue-250 transition-colors duration-200 rounded-lg shadow-md text-white px-6 py-3">
                Katalog ansehen
              </button>
            </router-link>
            <router-link to="/qcg" class="inline-block">
              <button
                class="bg-blue-250 hover:bg-red-150 transition-colors duration-200 rounded-lg shadow-md text-white px-6 py-3">
                Staatliche Förderung (QCG)
              </button>
            </router-link>
          </div>
        </div>

        <!-- Right image section -->
        <div class="lg:w-1/2 flex flex-col items-center">
          <div class="relative w-full max-w-sm lg:max-w-xl">
            <router-link 
              to="/chat" 
              class="block group cursor-pointer"
            >
              <img 
                loading="lazy" 
                src="../assets/images/LeeonLightRound.png" 
                alt="Leeon" 
                class="w-full object-contain transform transition-all duration-300 group-hover:scale-120 group-hover:drop-shadow-lg"
              >
            </router-link>
            <p class="text-gray-600 text-center text-lg">
              Leeon - Lernlöwe und persönlicher Chat-Coach
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Customer Testimonials Section -->
    <CustomerTestimonials />
  </div>
</template>

<script setup>
import CustomerTestimonials from './CustomerTestimonials.vue';
import { useStore } from 'vuex';

const store = useStore();

const callMeBanner = () => {
  store.commit("callMeBanner");
};
</script>

<style scoped>
.group:hover img {
  transform: scale(1.02);
}
</style>
