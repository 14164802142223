import { createStore } from "vuex";
import courses from "./modules/courses";
import admins from "./modules/admins";
import trainer from "./modules/trainer";
import quizzes from "./modules/quizzes.js";
import lernBuddy from "./modules/lernBuddy.js";
import media from "./modules/media";
import leeonStats from "./modules/leeonStats";

export default createStore({
  modules: {
    courses,
    admins,
    trainer,
    quizzes,
    lernBuddy,
    media,
    leeonStats,
  },
});
