import { toRaw } from "vue";

const state = {
  quizzes: [],
  quizLength: null,
  currentQuestionIndex: null,
  selectedAnswerIndex: null,
  correctAnswer: null,
  score: 0,
  results: [],
};

const getters = {
  getQuizBySlideId: (state) => (slideId) => {
    return state.quizzes.find((quiz) => quiz.id === slideId);
  },
  getSelectedAnswerIndex: (state) => state.selectedAnswerIndex,
  getQuizData: (state) => state.quizData,
  getCurrentQuestionIndex: (state) => state.currentQuestionIndex,
  getScore: (state) => state.score,
  getQuizLength: (state) => state.quizLength,
};

const mutations = {
  addQuiz(state, quiz) {
    state.quizzes.push(quiz);
  },
  setSelectedAnswerIndex: (state, payload) =>
    (state.selectedAnswerIndex = payload),

  setCurrentQuestionIndex: (state, payload) =>
    (state.currentQuestionIndex = payload),

  setCorrectAnswer: (state, payload) => {
    state.correctAnswer = payload;
  },

  setQuizLength: (state, payload) => {
    state.quizLength = payload;
  },
  incrementScore: (state) => (state.score += 100 / state.quizLength),
  clearScore: (state) => (state.score = 0),
  updateQuizContent(state, { quizId, quizContent }) {
    const quizIndex = state.quizzes.findIndex((quiz) => quiz.id === quizId);
    if (quizIndex !== -1) {
      const originalQuiz = state.quizzes[quizIndex].quiz;
      
      // If we're updating with translations, store them separately
      if (quizContent.translations) {
        state.quizzes[quizIndex] = {
          ...state.quizzes[quizIndex],
          quiz: {
            ...originalQuiz,
            translations: {
              ...originalQuiz.translations,
              ...quizContent.translations
            }
          }
        };
        return;
      }

      // Handle regular quiz content updates
      state.quizzes[quizIndex] = {
        ...state.quizzes[quizIndex],
        quiz: {
          ...originalQuiz,
          ...quizContent,
          questions: quizContent.questions.map((question, index) => {
            const originalQuestion = originalQuiz.questions[index];
            return {
              ...originalQuestion,
              ...question,
              correct_answer: question.correct_answer ?? originalQuestion?.correct_answer,
            };
          }),
        },
      };
    }
  },
};

const actions = {
  addQuizToState({ commit }, quiz) {
    commit("addQuiz", quiz);
  },
  updateQuizContent({ commit }, payload) {
    commit("updateQuizContent", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
