<template>
  <div class="relative mt-12 overflow-hidden">
    <div class="bg-gradient-to-br from-white to-leeonBlue20 rounded-2xl shadow-xl overflow-hidden border border-leeonBlue20">
      <div class="grid md:grid-cols-2 gap-12 items-center p-12">
        <!-- Content Section -->
        <div class="space-y-8">
          <div>
            <h2 class="text-4xl font-bold text-leeonDarkBlue mb-2">
              Entdecke Leeon
            </h2>
            <h3 class="text-2xl font-medium text-leeonPink">
              Dein KI-Karriere-Coach
            </h3>
          </div>
          <p class="text-lg text-leeonDarkBlue60">
            Erlebe personalisierte Karriereberatung mit Leeon, unserem fortschrittlichen KI-Karriere-Coach. Erhalte sofortige, maßgeschneiderte Ratschläge, die dir helfen, deinen beruflichen Weg zu gestalten und dein volles Potenzial zu entfalten.
          </p>
          <div class="space-y-4">
            <div class="flex items-start space-x-3 p-4 rounded-lg bg-white/50 backdrop-blur-sm border border-leeonBlue20 hover:border-leeonBlue transition-colors duration-200">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-leeonPink" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <span class="text-leeonDarkBlue font-medium">24/7 Verfügbarkeit für Karriereberatung</span>
            </div>
            <div class="flex items-start space-x-3 p-4 rounded-lg bg-white/50 backdrop-blur-sm border border-leeonBlue20 hover:border-leeonBlue transition-colors duration-200">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-leeonPink" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <span class="text-leeonDarkBlue font-medium">Individuelle Strategien zur Karriereentwicklung</span>
            </div>
            <div class="flex items-start space-x-3 p-4 rounded-lg bg-white/50 backdrop-blur-sm border border-leeonBlue20 hover:border-leeonBlue transition-colors duration-200">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-leeonPink" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <span class="text-leeonDarkBlue font-medium">KI-gestützte Kompetenzanalyse und Empfehlungen</span>
            </div>
          </div>
          <router-link 
            to="/chat"
            class="inline-flex items-center px-8 py-4 text-lg border-2 border-leeonPink font-medium rounded-xl text-white bg-leeonPink hover:bg-white hover:text-leeonPink transition-all duration-300 shadow-lg hover:shadow-xl hover:scale-105 transform"
          >
            Sprich mit Leeon
            <svg class="ml-2 -mr-1 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </router-link>
        </div>
        
        <!-- Lottie Animation Section -->
        <div class="relative h-[600px] -mr-6 flex items-center justify-center">
          <dotlottie-player
            ref="lottiePlayer"
            src="https://lottie.host/aebcc7e0-09a0-41fe-ab12-74334cdbbe1e/Zl5Ou02bAC.lottie"
            background="transparent"
            speed="1"
            class="w-full h-full scale-110"
            mode="normal"
            loop
            autoplay
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import '@dotlottie/player-component'

const lottiePlayer = ref(null)

onMounted(() => {
  // Wait for the custom element to be defined
  customElements.whenDefined('dotlottie-player').then(() => {
    console.log('DotLottie Player is ready')
    
    // Get the player element
    const player = lottiePlayer.value
    if (player) {
      // Add event listener for the 'ready' event
      player.addEventListener('ready', () => {
        console.log('Animation is ready to play')
        // Ensure loop mode is enabled
        player.setLooping(true)
        player.play()
      })

      // Add complete event listener to ensure continuous looping
      player.addEventListener('complete', () => {
        player.play()
      })
      
      // Initial play attempt
      player.play()
    }
  })
})
</script>

<style scoped>
.backdrop-blur-sm {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}
</style> 