<template>
  <div class="min-h-screen bg-gradient-to-b from-leeonBlue20 to-white py-12">
    <div class="max-w-6xl mx-auto px-4">
      <!-- Header Section -->
      <div class="bg-white rounded-lg shadow-lg mb-8 p-8 border-t-4 border-leeonBlue">
        <h1 class="text-3xl font-bold text-leeonBlue mb-4">
          Unsere Analyse: EU AI Act und seine wirtschaftlichen Auswirkungen
        </h1>
        <p class="text-lg text-gray-700 leading-relaxed">
          Eine umfassende Analyse der Auswirkungen des EU AI Acts auf die europäische und deutsche Wirtschaft, 
          basierend auf aktuellen Studien und Expertenmeinungen.
        </p>
      </div>

      <!-- Add after the header section and before Key Findings -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <!-- Cost Impact -->
        <div class="bg-white rounded-lg shadow-lg p-6 border-t-4 border-red-500">
          <div class="text-4xl font-bold text-red-500 mb-2">€31 Mrd.</div>
          <div class="text-xl font-semibold text-gray-800 mb-1">Wirtschaftliche Kosten</div>
          <p class="text-gray-600 text-sm">
            Geschätzte Gesamtkosten für die EU-Wirtschaft über 5 Jahre durch den AI Act
          </p>
        </div>

        <!-- Investment Impact -->
        <div class="bg-white rounded-lg shadow-lg p-6 border-t-4 border-yellow-500">
          <div class="text-4xl font-bold text-yellow-600 mb-2">-20%</div>
          <div class="text-xl font-semibold text-gray-800 mb-1">KI-Investitionen</div>
          <p class="text-gray-600 text-sm">
            Prognostizierter Rückgang der KI-Investitionen in der EU durch neue Regulierungen
          </p>
        </div>

        <!-- Brain Drain Impact -->
        <div class="bg-white rounded-lg shadow-lg p-6 border-t-4 border-purple-500">
          <div class="text-4xl font-bold text-purple-600 mb-2">37%</div>
          <div class="text-xl font-semibold text-gray-800 mb-1">Talent-Abwanderung</div>
          <p class="text-gray-600 text-sm">
            Anteil europäischer KI-Talente, die bereits abgewandert sind oder dies planen
          </p>
        </div>
      </div>

      <!-- Add a key message box -->
      <div class="bg-white rounded-lg shadow-lg p-8 mb-8 border-l-4 border-leeonBlue">
        <h2 class="text-2xl font-semibold text-leeonBlue mb-4">Kernbotschaft</h2>
        <div class="prose max-w-none">
          <p class="text-lg leading-relaxed">
            Der EU AI Act stellt europäische Unternehmen vor erhebliche Herausforderungen: 
            <span class="font-semibold">Hohe Compliance-Kosten</span> <Citation id="itif2021" />, 
            <span class="font-semibold">sinkende Investitionen</span> und 
            <span class="font-semibold">Talent-Abwanderung</span> <Citation id="atomico2022" /> drohen die Wettbewerbsfähigkeit 
            Europas im globalen KI-Wettlauf zu gefährden. Besonders KMUs und Startups sind von den 
            neuen Regularien betroffen.
          </p>
        </div>
      </div>

      <!-- Key Findings Section -->
      <div class="bg-white rounded-lg shadow-lg p-8 mb-8">
        <h2 class="text-2xl font-semibold text-leeonBlue mb-6">Zentrale Erkenntnisse</h2>
        
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <!-- Economic Impact -->
          <div class="bg-gradient-to-br from-red-50 to-white rounded-xl p-6 border border-red-100">
            <div class="flex items-center mb-4">
              <div class="w-10 h-10 bg-red-100 rounded-full flex items-center justify-center mr-3">
                <svg class="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 17h8m0 0v-8m0 8l-8-8-4 4-6-6"/>
                </svg>
              </div>
              <h3 class="text-lg font-semibold text-gray-800">Wirtschaftliche Auswirkungen</h3>
            </div>

            <div class="space-y-4">
              <div class="bg-white rounded-lg p-4 shadow-sm border border-red-100">
                <div class="text-2xl font-bold text-red-600 mb-1">€31 Mrd.</div>
                <div class="text-sm text-gray-600">Geschätzte Gesamtkosten <Citation id="itif2021" /></div>
              </div>

              <div class="bg-white rounded-lg p-4 shadow-sm border border-red-100">
                <div class="text-2xl font-bold text-red-600 mb-1">-20%</div>
                <div class="text-sm text-gray-600">KI-Investitionsrückgang <Citation id="itif2021" /></div>
              </div>

              <div class="bg-white rounded-lg p-4 shadow-sm border border-red-100">
                <div class="text-2xl font-bold text-red-600 mb-1">€400k</div>
                <div class="text-sm text-gray-600">Compliance-Kosten pro KI-System <Citation id="itif2021" /></div>
              </div>
            </div>
          </div>

          <!-- Industry Impact -->
          <div class="bg-gradient-to-br from-blue-50 to-white rounded-xl p-6 border border-blue-100">
            <div class="flex items-center mb-4">
              <div class="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3">
                <svg class="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
                </svg>
              </div>
              <h3 class="text-lg font-semibold text-gray-800">Branchenspezifische Effekte</h3>
            </div>

            <div class="space-y-3">
              <div class="flex items-start bg-white p-3 rounded-lg shadow-sm border border-blue-100">
                <div class="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-3">
                  <span class="text-blue-600 font-semibold">35%</span>
                </div>
                <p class="text-sm text-gray-600">der EU-Wirtschaftssektoren direkt betroffen</p>
              </div>

              <div class="flex items-start bg-white p-3 rounded-lg shadow-sm border border-blue-100">
                <div class="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-3">
                  <span class="text-blue-600 font-bold">!</span>
                </div>
                <p class="text-sm text-gray-600">Besonders betroffen: Finanzsektor, Gesundheitswesen, Automotive</p>
              </div>

              <div class="flex items-start bg-white p-3 rounded-lg shadow-sm border border-blue-100">
                <div class="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-3">
                  <span class="text-blue-600 font-bold">HR</span>
                </div>
                <p class="text-sm text-gray-600">Strenge Auflagen für "High-Risk" KI-Systeme</p>
              </div>
            </div>
          </div>

          <!-- Innovation Impact -->
          <div class="bg-gradient-to-br from-purple-50 to-white rounded-xl p-6 border border-purple-100">
            <div class="flex items-center mb-4">
              <div class="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-3">
                <svg class="w-5 h-5 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"/>
                </svg>
              </div>
              <h3 class="text-lg font-semibold text-gray-800">Innovationsauswirkungen</h3>
            </div>

            <div class="space-y-3">
              <div class="flex items-start bg-white p-3 rounded-lg shadow-sm border border-purple-100">
                <div class="flex-shrink-0 w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center mr-3">
                  <span class="text-purple-600 font-semibold">73%</span>
                </div>
                <p class="text-sm text-gray-600">der VCs erwarten sinkende Wettbewerbsfähigkeit <Citation id="digitalpoland2023" /></p>
              </div>

              <div class="flex items-start bg-white p-3 rounded-lg shadow-sm border border-purple-100">
                <div class="flex-shrink-0 w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center mr-3">
                  <span class="text-purple-600 font-semibold">16%</span>
                </div>
                <p class="text-sm text-gray-600">der KI-Startups erwägen Standortverlagerung <Citation id="digitalpoland2023" /></p>
              </div>

              <div class="flex items-start bg-white p-3 rounded-lg shadow-sm border border-purple-100">
                <div class="flex-shrink-0 w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center mr-3">
                  <span class="text-purple-600 font-semibold">2x</span>
                </div>
                <p class="text-sm text-gray-600">Längere Time-to-Market für KI-Produkte</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Detailed Analysis Sections -->
      <div class="space-y-8">
        <!-- Economic Projections -->
      

      
        <!-- Add after the Investment Trends section -->
        <section class="bg-white rounded-lg shadow-lg p-8">
          <h2 class="text-2xl font-semibold text-leeonBlue mb-6">Entwicklung der KI-Investitionen</h2>
          <div class="h-80">
            <InvestmentChart />
          </div>
        </section>

      

        <!-- Add these sections after the existing "Data Table" section -->
        <section class="bg-white rounded-lg shadow-lg p-8">
          <h2 class="text-2xl font-semibold text-leeonBlue mb-6">Branchenspezifische Auswirkungen</h2>
          
          <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <!-- Financial Services Card -->
            <div class="bg-gradient-to-br from-blue-50 to-white rounded-xl shadow-lg p-6 border border-blue-100">
              <div class="flex items-center mb-4">
                <div class="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                  <svg class="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800">Finanzdienstleistungen</h3>
              </div>

              <div class="mb-4">
                <div class="text-sm font-medium text-blue-600 mb-2">KERNAUSWIRKUNG</div>
                <p class="text-gray-700 font-medium">
                  Verschärfte Anforderungen an KI-gestützte Finanzentscheidungen
                </p>
              </div>

              <div class="space-y-3">
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-blue-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Neue Transparenzanforderungen für Kreditentscheidungen</p>
                </div>
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-blue-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Verzögerungen bei KI-Implementierungen</p>
                </div>
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-green-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Langfristig: Erhöhtes Kundenvertrauen</p>
                </div>
              </div>
            </div>

            <!-- Healthcare Card -->
            <div class="bg-gradient-to-br from-red-50 to-white rounded-xl shadow-lg p-6 border border-red-100">
              <div class="flex items-center mb-4">
                <div class="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mr-4">
                  <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path>
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800">Gesundheitswesen</h3>
              </div>

              <div class="mb-4">
                <div class="text-sm font-medium text-red-600 mb-2">KERNAUSWIRKUNG</div>
                <p class="text-gray-700 font-medium">
                  Zusätzliche Konformitätsbewertungen für KI-Medizinprodukte
                </p>
              </div>

              <div class="space-y-3">
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-red-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Überschneidung mit Medizinprodukte-Regularien</p>
                </div>
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-red-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Verzögerte Markteinführung von KI-Diagnostik</p>
                </div>
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-green-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Höhere Patientensicherheit durch Standardisierung</p>
                </div>
              </div>
            </div>

            <!-- Manufacturing Card -->
            <div class="bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-lg p-6 border border-gray-100">
              <div class="flex items-center mb-4">
                <div class="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mr-4">
                  <svg class="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"></path>
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800">Produktion & Automotive</h3>
              </div>

              <div class="mb-4">
                <div class="text-sm font-medium text-gray-600 mb-2">KERNAUSWIRKUNG</div>
                <p class="text-gray-700 font-medium">
                  Neue Validierungsanforderungen für KI-Systeme in der Produktion
                </p>
              </div>

              <div class="space-y-3">
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-gray-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Erhöhte Sicherheitsanforderungen für autonome Systeme</p>
                </div>
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-gray-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Umfangreiche Dokumentationspflichten</p>
                </div>
                <div class="flex items-start">
                  <div class="flex-shrink-0 w-5 h-5 text-green-500 mt-1">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </div>
                  <p class="ml-2 text-sm text-gray-600">Chance für EU-Standards in der Industrie 4.0</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Startup Impact Section -->
        <section class="bg-white rounded-lg shadow-lg p-8">
          <h2 class="text-2xl font-semibold text-leeonBlue mb-6">Auswirkungen auf das Startup-Ökosystem</h2>
          
          <!-- Alert Box -->
          <div class="bg-red-50 border-l-4 border-red-500 p-4 mb-8">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm text-red-700">
                  16% der EU-KI-Startups erwägen die Verlagerung ins Ausland aufgrund der neuen Regularien
                  <Citation id="digitalpoland2023" />
                </p>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <!-- Compliance Costs Card -->
            <div class="bg-gradient-to-br from-orange-50 to-white rounded-xl shadow-lg p-6 border border-orange-100">
              <div class="flex items-center mb-6">
                <div class="w-12 h-12 bg-orange-100 rounded-full flex items-center justify-center mr-4">
                  <svg class="w-6 h-6 text-orange-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800">Compliance-Kosten</h3>
              </div>

              <div class="space-y-4">
                <div class="bg-white rounded-lg p-4 shadow-sm border border-orange-100">
                  <div class="text-2xl font-bold text-orange-600 mb-1">€193k - €330k</div>
                  <div class="text-sm text-gray-600">Initiale Setup-Kosten</div>
                </div>

                <div class="bg-white rounded-lg p-4 shadow-sm border border-orange-100">
                  <div class="text-2xl font-bold text-orange-600 mb-1">€70k</div>
                  <div class="text-sm text-gray-600">Jährliche Wartungskosten</div>
                </div>

                <div class="bg-orange-50 p-4 rounded-lg">
                  <p class="text-sm text-gray-700">
                    Diese Kosten können bis zu 40% der verfügbaren Ressourcen eines typischen KI-Startups beanspruchen
                  </p>
                </div>
              </div>
            </div>

            <!-- Investment Climate Card -->
            <div class="bg-gradient-to-br from-purple-50 to-white rounded-xl shadow-lg p-6 border border-purple-100">
              <div class="flex items-center mb-6">
                <div class="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                  <svg class="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"/>
                  </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-800">Investitionsklima</h3>
              </div>

              <div class="space-y-4">
                <div class="bg-white rounded-lg p-4 shadow-sm border border-purple-100">
                  <div class="text-2xl font-bold text-purple-600 mb-1">73%</div>
                  <div class="text-sm text-gray-600">
                    der VCs erwarten sinkende Wettbewerbsfähigkeit <Citation id="digitalpoland2023" />
                  </div>
                </div>

                <div class="space-y-3">
                  <div class="flex items-start">
                    <div class="flex-shrink-0 w-5 h-5 text-purple-500 mt-1">
                      <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                      </svg>
                    </div>
                    <p class="ml-2 text-sm text-gray-600">Verlängerte Time-to-Market für KI-Produkte</p>
                  </div>
                  <div class="flex items-start">
                    <div class="flex-shrink-0 w-5 h-5 text-purple-500 mt-1">
                      <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                      </svg>
                    </div>
                    <p class="ml-2 text-sm text-gray-600">Regulatorische Unsicherheit bremst Innovationen</p>
                  </div>
                  <div class="flex items-start">
                    <div class="flex-shrink-0 w-5 h-5 text-purple-500 mt-1">
                      <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"/>
                      </svg>
                    </div>
                    <p class="ml-2 text-sm text-gray-600">Tendenz zu Investitionen außerhalb der EU</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Brain Drain Section -->
        <section class="bg-white rounded-lg shadow-lg p-8">
          <h2 class="text-2xl font-semibold text-leeonBlue mb-6">Talent-Abwanderung ("Brain Drain")</h2>
          
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div class="prose max-w-none">
              <p class="mb-6">
                Eine der besorgniserregendsten indirekten Auswirkungen des EU AI Acts ist die potenzielle 
                Abwanderung von Top-KI-Talenten und Unternehmen in andere Märkte.
              </p>
              
              <div class="bg-gray-50 p-4 rounded-lg mb-6">
                <p class="font-medium mb-2">Kernzahlen:</p>
                <ul class="list-disc pl-5">
                  <li>~20% der europäischen Top-Ingenieure bereits in die USA abgewandert <Citation id="atomico2022" /></li>
                  <li>17% der von europäischen Startups geschaffenen Werte gehen durch US-Verlagerung verloren <Citation id="atomico2022" /></li>
                  <li>Steigende Tendenz zur Unternehmensverlagerung aufgrund regulatorischer Unsicherheit <Citation id="bitkom2023" /></li>
                </ul>
              </div>
            </div>
            
            <div class="h-80">
              <BrainDrainChart />
            </div>
          </div>
        </section>
      </div>

      <!-- Add before the Download Section -->
      <section id="citations" class="bg-white rounded-lg shadow-lg p-8">
        <h2 class="text-2xl font-semibold text-leeonBlue mb-6">Quellenverzeichnis</h2>
        <div class="space-y-4">
          <div v-for="[id, citation] in citationStore.getAllCitations" :key="id" class="text-sm">
            <p>
              <span class="font-medium">[{{ id }}]</span> {{ citation.author }} ({{ citation.year }}): 
              <span class="italic">{{ citation.title }}</span>. 
              <a 
                :href="citation.url" 
                target="_blank" 
                class="text-leeonBlue hover:underline"
              >
                {{ citation.url }}
              </a> 
              (Zugriff am {{ new Date(citation.accessed).toLocaleDateString('de-DE') }})
            </p>
          </div>
        </div>
      </section>

      <!-- Add before the closing div of max-w-6xl -->
      <div class="mt-12 bg-white rounded-lg shadow-lg p-8 border-t-4 border-leeonPink text-center mb-12">
        <h2 class="text-2xl font-semibold text-leeonBlue mb-4">Teil deine Expertise</h2>
        <p class="text-lg text-gray-700 mb-6">
          Deine Perspektive ist wertvoll! Nimm an unserer Umfrage teil und hilf uns, 
          die Auswirkungen des EU AI Acts besser zu verstehen.
        </p>
        <router-link 
          to="/eu_politik" 
          class="inline-block bg-leeonPink text-white py-3 px-8 rounded-lg text-lg font-semibold 
                 hover:bg-leeonPink60 transform transition-all duration-200 hover:scale-[1.02] 
                 focus:outline-none focus:ring-2 focus:ring-leeonPink focus:ring-offset-2"
        >
          Zur Umfrage
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import InvestmentChart from '../components/charts/InvestmentChart.vue'
import BrainDrainChart from '../components/charts/BrainDrainChart.vue'
import Citation from '../components/Citation.vue'
import { useCitationStore } from '../stores/citationStore'

const citationStore = useCitationStore()

const downloadStudy = () => {
  // Implementation for download functionality
  console.log('Downloading study...')
}
</script>

<style scoped>
.prose {
  max-width: none;
}
</style> 