<template>
  <Popover class="relative" v-slot="{ open, close }">
    <PopoverButton
      class="inline-flex items-center gap-x-1 text-blue-250 focus:outline-none">
      <span>Coaching</span>
      <ChevronDownIcon
        class="h-5 w-5 transition-transform duration-300 ease-out"
        :class="open ? 'rotate-180 transform' : ''"
        aria-hidden="true" />
    </PopoverButton>

    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
      <PopoverPanel class="absolute left-1/2 z-50 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4">
        <div
          class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
          <div class="p-4">
            <div v-for="item in solutions" :key="item.name"
              class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-blue-50">
              <div
                class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <component :is="item.icon" class="h-6 w-6 text-blue-250 group-hover:text-red-150" aria-hidden="true" />
              </div>
              <div>
                <router-link
                  :to="item.to"
                  class="font-semibold text-blue-250"
                  @click="close"
                >
                  {{ item.name }}
                  <span class="absolute inset-0" />
                </router-link>
                <p class="mt-1 text-gray-600">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-blue-50">
            <router-link v-for="item in callsToAction" :key="item.name" :to="item.to"
              class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-blue-250 hover:bg-gray-100"
              @click="close">
              <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon, PhoneIcon } from '@heroicons/vue/20/solid'
import {
  CursorArrowRaysIcon,
  SquaresPlusIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  UserIcon
} from '@heroicons/vue/24/outline'
import { RouterLink } from 'vue-router'
import { useRouter } from 'vue-router'
import { nextTick } from 'vue'

const router = useRouter()

const solutions = [
  {
    name: 'Gründercoaching',
    description: 'Dein Sprungbrett in die Selbstständigkeit',
    to: '/m3FvTGC6wiYSSjC2ezIg/Seminar',
    icon: SquaresPlusIcon
  },
  {
    name: 'Coaching zum Kenntnisaufbau',
    description: 'Coaching-Maßnahme zur nachhaltigen Arbeitsmarktintegration',
    to: '/7vFO3qe1YH50SHPSEa68/Seminar',
    icon: CursorArrowRaysIcon
  },
  {
    name: 'Jobcoaching',
    description: "Individuelle Unterstützung bei beruflicher Orientierung",
    to: '/efn2XUYDlnDqE7Br2R0R/Seminar',
    icon: UserIcon
  },
  { name: 'Leeon Future Lab', description: "Unternehmensgründung mit KI", to: '/futurelab', icon: RocketLaunchIcon },

]

const callsToAction = [
  { name: 'FaQ', to: '/faq', icon: QuestionMarkCircleIcon },
  { name: 'Beratung', to: '/booking', icon: PhoneIcon },
]
</script>
