<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div class="flex items-center gap-2">
        <h2 class="text-lg font-semibold">Lead-Quellen</h2>
        <div class="relative group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-500 cursor-help"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
            <p class="mb-2">Diese Grafik zeigt die Verteilung der Lead-Quellen:</p>
            <ul class="list-disc pl-4 space-y-1">
              <li>Zeigt, über welche Kanäle die Kunden auf uns aufmerksam wurden</li>
              <li>Sortiert nach Häufigkeit (absteigend)</li>
              <li>Die Prozentangaben zeigen den Anteil an der Gesamtmenge</li>
              <li>Konvertierte Leads sind Kunden mit mindestens einem Kurs</li>
              <li class="text-yellow-300">Hinweis: Lead-Quellen werden erst seit Mitte November 2024 erfasst</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="flex items-center gap-4">
        <select
          v-model="selectedRange"
          class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
        >
          <option value="all">Gesamte Zeit</option>
          <option value="custom">Benutzerdefiniert</option>
        </select>

        <div v-if="selectedRange === 'custom'" class="flex items-center gap-2">
          <input
            type="date"
            v-model="startDate"
            class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
          />
          <span>bis</span>
          <input
            type="date"
            v-model="endDate"
            class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
          />
        </div>

        <label class="flex items-center gap-2 ml-4">
          <input
            type="checkbox"
            v-model="showConverted"
            class="rounded border-gray-300 text-blue-600 focus:ring-blue-500 h-4 w-4 bg-white shadow-sm appearance-none border checked:bg-blue-600 checked:border-transparent"
          >
          <span class="text-sm">Nur konvertierte Leads</span>
        </label>
      </div>
    </div>

    <div class="h-[400px]">
      <div v-if="loading" class="flex items-center justify-center h-full">
        <span>Loading...</span>
      </div>
      <Bar
        v-else-if="data.datasets[0].data.length > 0"
        :data="data"
        :options="options"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Keine Daten verfügbar</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { Bar } from 'vue-chartjs';
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const loading = ref(true);
const selectedRange = ref('all');
const startDate = ref(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0]);
const endDate = ref(new Date().toISOString().split('T')[0]);
const showConverted = ref(false);

const data = ref({
  labels: [],
  datasets: [{
    label: 'Lead-Quellen',
    backgroundColor: 'rgba(75, 192, 192, 0.8)',
    borderColor: 'rgba(75, 192, 192, 1)',
    borderWidth: 1,
    data: []
  }]
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          const value = context.raw || 0;
          const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
          const percentage = Math.round((value / total) * 100);
          return `${value} ${showConverted.value ? 'Kunden' : 'Leads'} (${percentage}%)`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1
      },
      title: {
        display: true,
        text: showConverted.value ? 'Anzahl Kunden' : 'Anzahl Leads'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Lead-Quelle'
      },
      ticks: {
        autoSkip: false,
        maxRotation: 45,
        minRotation: 45
      }
    }
  }
};

const parseDate = (dateValue) => {
  if (!dateValue) return null;

  try {
    // If it's a Firestore Timestamp
    if (dateValue.toDate) {
      return dateValue.toDate();
    }
    // If it's a string
    if (typeof dateValue === 'string') {
      const date = new Date(dateValue);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    // If it's already a Date object
    if (dateValue instanceof Date) {
      return dateValue;
    }
  } catch (error) {
    console.error('Error parsing date:', dateValue, error);
  }
  // Return null if created property exists but parsing fails
  return null;
};

const isDateInRange = (date) => {
  if (selectedRange.value === 'all') return true;
  if (!date) return false;

  const start = new Date(startDate.value);
  const end = new Date(endDate.value);
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  return date >= start && date <= end;
};

const updateChartData = async () => {
  try {
    loading.value = true;
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);

    // Collect all leads with their sources and creation dates
    const leads = [];

    const excludedLeads = [];
    snapshot.forEach(doc => {
      const data = doc.data();
      const source = data?.source;
      const created = parseDate(data?.created);
      const hasTeamMateInfo = Array.isArray(data?.TeamMateInfo) && data.TeamMateInfo.length > 0;
      const status = data?.status;

      if (source && created && isDateInRange(created)) {
        // Check if we should include this lead based on conversion status
        const isConverted = ((status !== 'lead') && (status !== 'archive') && (hasTeamMateInfo)) || (status === 'waiting') || (status === 'contract') || (status === 'ready') || (status === 'active');
        if (!showConverted.value || isConverted) {
          leads.push({
            source,
            created,
            isConverted
          });
        }
      } else {
        excludedLeads.push({
          id: doc.id,
          reason: !source ? 'missing source' : 'invalid date',
          data: data
        });
      }
    });

    // Sort leads by creation date
    leads.sort((a, b) => a.created - b.created);

    // Count sources
    const sourceCounts = {};
    leads.forEach(lead => {
      sourceCounts[lead.source] = (sourceCounts[lead.source] || 0) + 1;
    });

    // Sort by count in descending order
    const sortedSources = Object.entries(sourceCounts)
      .sort(([,a], [,b]) => b - a)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    data.value = {
      labels: Object.keys(sortedSources),
      datasets: [{
        label: showConverted.value ? 'Konvertierte Leads' : 'Leads',
        data: Object.values(sortedSources),
        backgroundColor: showConverted.value ? 'rgba(34, 197, 94, 0.8)' : 'rgba(75, 192, 192, 0.8)',
        borderColor: showConverted.value ? 'rgba(34, 197, 94, 1)' : 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };

    console.log('Lead source data:', {
      totalLeads: leads.length,
      convertedLeads: leads.filter(l => l.isConverted).length,
      dateRange: {
        selected: selectedRange.value === 'all' ? 'all' : `${startDate.value} to ${endDate.value}`,
        first: leads[0]?.created,
        last: leads[leads.length - 1]?.created
      },
      sources: Object.entries(sourceCounts).map(([source, count]) => ({
        source,
        count,
        percentage: Math.round((count / leads.length) * 100)
      }))
    });

    // console.log('Excluded leads:', excludedLeads);

  } catch (error) {
    console.error('Error fetching lead source data:', error);
  } finally {
    loading.value = false;
  }
};

// Watch for changes in date range or conversion filter
watch([selectedRange, startDate, endDate, showConverted], () => {
  updateChartData();
});

onMounted(() => {
  updateChartData();
});
</script>

<style scoped>
input[type="checkbox"] {
  appearance: auto;
  -webkit-appearance: checkbox;
  height: 16px;
  width: 16px;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  background-color: white;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #2563eb;
  border-color: #2563eb;
}
</style>
