import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { db } from '../firebaseinit'

export const useCourseStore = defineStore('courses', {
  state: () => ({
    stars: JSON.parse(localStorage.getItem('selectedCourses') || '[]'),
    availableCourses: ref([]),
    callMeBanner: ref(false)
  }),

  getters: {
    getAvailableCourses: (state) => state.availableCourses,
    getStars: (state) => state.stars,
    getCallMeBanner: (state) => state.callMeBanner
  },

  actions: {
    loadFromStorage() {
      this.stars = JSON.parse(localStorage.getItem('selectedCourses') || '[]')
    },

    remember(courseId) {
      const index = this.stars.indexOf(courseId)
      if (index === -1) {
        this.stars.push(courseId)
      } else {
        this.stars.splice(index, 1)
      }
      // Save to localStorage and dispatch storage event
      localStorage.setItem('selectedCourses', JSON.stringify(this.stars))
      // Dispatch storage event for other tabs
      window.dispatchEvent(new StorageEvent('storage', {
        key: 'selectedCourses',
        newValue: JSON.stringify(this.stars)
      }))
    },

    deleteRemember() {
      this.stars = []
      localStorage.removeItem('selectedCourses')
      // Dispatch storage event for other tabs
      window.dispatchEvent(new StorageEvent('storage', {
        key: 'selectedCourses',
        oldValue: JSON.stringify(this.stars),
        newValue: null
      }))
    },

    quote(courseId) {
      if (!this.stars.includes(courseId)) {
        this.stars.push(courseId)
        // Save to localStorage
        localStorage.setItem('selectedCourses', JSON.stringify(this.stars))
      }
    },

    async getCourses() {
      const courses = []
      try {
        const snapshot = await db.collection('courses').get()

        for (const doc of snapshot.docs) {
          const course = {
            name: doc.data().name,
            shortdescription: doc.data().shortdescription,
            imageLink: doc.data().imageLink,
            description: doc.data().description,
            id: doc.id,
            lessons: []
          }

          // Get lessons for this course
          const lessonsSnapshot = await db.collection('lessons')
            .where('course', '==', course.id)
            .get()

          course.lessons = lessonsSnapshot.docs.map(doc => doc.data().headline)
          courses.push(course)
        }

        this.availableCourses = courses
      } catch (error) {
        console.error('Error fetching courses:', error)
        throw error
      }
    },

    toggleCallMeBanner() {
      this.callMeBanner = !this.callMeBanner
    }
  }
})
