<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Aktive Kunden</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-72 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          Diese Grafik zeigt die Anzahl der gleichzeitig aktiven Kunden über die Zeit. 
          Ein Kunde gilt als aktiv während der Laufzeit seiner Kurse (vom ersten Kurstag bis zum letzten Kurstag).
    
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <Line
        v-if="chartData"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Lade Daten...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Line } from 'vue-chartjs';
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const chartData = ref(null);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `Aktive Kunden: ${context.raw}`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Anzahl aktiver Kunden'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Datum'
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45
      }
    }
  }
};

const generateMonthRange = (endDate) => {
  const months = [];
  // Fixed start date: January 2024
  const start = new Date(2024, 0, 1); // 0 = January
  const end = new Date(endDate);
  
  // Set to first day of month
  end.setDate(1);
  
  // Current month
  const current = new Date(start);
  
  while (current <= end) {
    months.push(new Date(current));
    current.setMonth(current.getMonth() + 1);
  }
  
  return months;
};

const updateChartData = async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    const excludedCourseIds = [
      "BfHGPoDdcIpDlWXmNjPZ",
      "l1DKt57j8FuR3dUR1fk6",
      "JiHhZsyMLB06ggMxkm0O"
    ];

    // Collect all course periods
    const coursePeriods = [];
    
    snapshot.forEach(doc => {
      const teamMateInfo = doc.data().TeamMateInfo || [];
      teamMateInfo.forEach(course => {
        if (!excludedCourseIds.includes(course.courseID) &&
            course.semStart &&
            course.semEnd) {
          coursePeriods.push({
            start: new Date(course.semStart),
            end: new Date(course.semEnd)
          });
        }
      });
    });

    // Find latest end date
    const maxDate = new Date(Math.max(...coursePeriods.map(period => period.end)));

    // Generate array of all months from Jan 2024 to latest end date
    const allMonths = generateMonthRange(maxDate);

    // Calculate active customers for each month
    const monthlyData = allMonths.map(month => {
      const activeCount = coursePeriods.filter(period => {
        const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
        const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);
        
        return (period.start <= monthEnd && period.end >= monthStart);
      }).length;

      return {
        date: month,
        count: activeCount
      };
    });

    // Prepare chart data
    chartData.value = {
      labels: monthlyData.map(item => formatDate(item.date)),
      datasets: [
        {
          label: 'Aktive Kunden',
          data: monthlyData.map(item => item.count),
          borderColor: 'rgb(234, 179, 8)',
          backgroundColor: 'rgba(234, 179, 8, 0.5)',
          tension: 0.1,
          fill: true
        }
      ]
    };

    console.log('Active customers data:', {
      totalMonths: monthlyData.length,
      dateRange: {
        start: formatDate(new Date(2024, 0, 1)),
        end: formatDate(maxDate)
      },
      monthlyData: monthlyData.map(item => ({
        date: formatDate(item.date),
        count: item.count
      }))
    });

  } catch (error) {
    console.error('Error fetching active customers data:', error);
  }
};

const formatDate = (date) => {
  return date.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'short'
  });
};

onMounted(() => {
  updateChartData();
});
</script> 