<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Kundenstatus Übersicht</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          <p class="mb-2">Diese Grafik zeigt die Verteilung der Kunden nach ihrem aktuellen Status:</p>
          <ul class="list-disc pl-4 space-y-1">
            <li><span class="font-semibold">Lead:</span> Interessent, noch kein Kunde</li>
            <li><span class="font-semibold">Ready:</span> Bereit für den Kursstart</li>
            <li><span class="font-semibold">TeamMate:</span> Aktiv in einem oder mehreren Kursen</li>
            <li><span class="font-semibold">Fertig:</span> Kurse abgeschlossen, 6 Monate Zugriff</li>
            <li><span class="font-semibold">Done:</span> Kunde vollständig abgeschlossen</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <Bar
        v-if="chartData"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Lade Daten...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { cp } from '../../../firebaseinit.js';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { collection, getDocs, getFirestore } from 'firebase/firestore';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const chartData = ref(null);
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `Anzahl: ${context.raw}`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1
      }
    }
  }
};

onMounted(async () => {
  try {
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    // Initialize all status types with 0
    const statusOrder = ['lead', 'ready', 'TeamMate', 'F', 'done'];
    const statusLabels = {
      'lead': 'Lead',
      'ready': 'Ready',
      'TeamMate': 'TeamMate',
      'F': 'Fertig',
      'done': 'done'
    };
    const statusCounts = {};
    statusOrder.forEach(status => {
      statusCounts[status] = 0;
    });
    
    snapshot.forEach(doc => {
      const status = doc.data().status;
      // Only count if status is in our predefined list
      if (status && status in statusCounts) {
        statusCounts[status]++;
      }
    });

    // Prepare chart data
    chartData.value = {
      labels: statusOrder.map(status => statusLabels[status]),
      datasets: [{
        data: statusOrder.map(status => statusCounts[status]),
        backgroundColor: [
          'rgba(54, 162, 235, 0.5)',   // blue
          'rgba(75, 192, 192, 0.5)',   // green
          'rgba(255, 206, 86, 0.5)',   // yellow
          'rgba(255, 99, 132, 0.5)',   // red
          'rgba(153, 102, 255, 0.5)',  // purple
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1
      }]
    };
  } catch (error) {
    console.error('Error fetching customer status statistics:', error);
  }
});
</script> 