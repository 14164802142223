<template>
  <div>
    <section class="py-8 px-8">
      <div class="flex flex-wrap -mx-4 -mb-6">
        <div class="lg:w-1/4 px-4 mb-6">
          <img loading="lazy" src="../assets/images/soziales_lernen.svg" alt="Soziales Lernen"
            class="h-40 w-40 rounded-full border shadow-md" />
          <h3 class="text-xl my-2 text-blue-250 mt-4">Soziales Lernen</h3>
          <p class="text-gray-500 leading-relaxed">
            Gemeinsam lernen macht Spaß, es fällt leichter und der Austausch ermöglicht neue Perspektiven. Bei uns ist
            das auch im e-Learning möglich. Vorbei sind die Zeiten, in denen man für sich alleine die Seminarunterlagen
            bearbeiten musste!
          </p>
        </div>
        <div class="lg:w-1/4 px-4 mb-6">
          <img loading="lazy" src="../assets/images/ms_teams.svg" alt="MS Teams"
            class="h-40 w-40 rounded-full border shadow-md" />
          <h3 class="text-xl my-2 text-blue-250 mt-4">MS Teams als Lernplattform</h3>
          <p class="text-gray-500 leading-relaxed">
            In der Gruppen-Chat- und Kollaborationsplattform von Microsoft Teams findest duDeine Lerninhalte, kannst
            Deinen Fortschritt verfolgen, dich mit anderen Teilnehmern austauschen und triffst Deinen Personal Trainer.
          </p>
        </div>
        <div class="lg:w-1/4 px-4 mb-6">
          <img loading="lazy" src="../assets/images/personal_trainer.svg" alt="Personal Trainer"
            class="h-40 w-40 rounded-full border shadow-md" />
          <h3 class="text-xl my-2 text-blue-250 mt-4">Personal Trainer</h3>
          <p class="text-gray-500 leading-relaxed">
            Dein Personal Trainer unterstützt dich live und bringt eine Menge Praxiserfahrung in den Lernalltag. Alle
            unsere Trainer arbeiten auch in der Firmenberatung. Sei gespannt auf Live-Sessions in höchster Qualität und
            garantiertem Mehrwert.
          </p>
        </div>
        <div class="lg:w-1/4 px-4 mb-6">
          <img loading="lazy" src="../assets/images/virtueller_lernassistent.svg" alt="Virtueller Lernassistent"
            class="h-40 w-40 rounded-full border shadow-md" />
          <h3 class="text-xl my-2 text-blue-250 mt-4">Virtueller Lernassistent</h3>
          <p class="text-gray-500 leading-relaxed">
            Dein virtueller Lernassistent und digitaler Spezialist im Fachgebiet steht Dir für Fragen und auch
            Prüfungsvorbereitungen rund um die Uhr zur Verfügung.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
