<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <h2 class="text-lg font-semibold">Lead-Wachstum</h2>
      <div class="relative group">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-5 w-5 text-gray-500 cursor-help" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
          <p class="mb-2">Diese Grafik zeigt das kumulative Wachstum aller Leads über die Zeit:</p>
          <ul class="list-disc pl-4 space-y-1">
            <li>Basiert auf dem Erstellungsdatum der Leads</li>
            <li>Zeigt alle Kontakte unabhängig vom aktuellen Status</li>
            <li>Hilft bei der Bewertung des Lead-Wachstums</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <div v-if="loading" class="flex items-center justify-center h-full">
        <span class="text-gray-500">Lade Daten...</span>
      </div>
      <Line
        v-else-if="chartData && chartData.datasets[0].data.length > 0"
        :data="chartData"
        :options="chartOptions"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Keine Daten verfügbar</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Line } from 'vue-chartjs';
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const loading = ref(true);
const chartData = ref(null);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `Gesamtanzahl Leads: ${context.raw}`;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Gesamtanzahl Leads'
      }
    },
    x: {
      title: {
        display: true,
        text: 'Datum'
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45
      }
    }
  }
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'short'
  });
};

const updateChartData = async () => {
  try {
    loading.value = true;
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);
    
    // Get creation dates for all leads
    const leadDates = [];
    
    snapshot.forEach(doc => {
      const data = doc.data();
      const created = data?.created;
      if (created) {
        try {
          // Handle both string dates and Firestore timestamps
          const date = typeof created === 'string' 
            ? new Date(created) 
            : created.toDate?.() || new Date(created);
          
          if (!isNaN(date.getTime())) { // Validate the date is valid
            leadDates.push(date);
          }
        } catch (error) {
          console.error('Error parsing date:', created, error);
        }
      }
    });

    if (leadDates.length === 0) {
      console.log('No lead dates found');
      loading.value = false;
      return;
    }

    // Sort dates chronologically
    leadDates.sort((a, b) => a - b);

    // Create cumulative data points
    const dateCount = {};
    let cumulative = 0;

    leadDates.forEach(date => {
      const formattedDate = formatDate(date);
      cumulative++;
      dateCount[formattedDate] = cumulative;
    });

    // Prepare chart data
    chartData.value = {
      labels: Object.keys(dateCount),
      datasets: [
        {
          label: 'Gesamtanzahl Leads',
          data: Object.values(dateCount),
          borderColor: 'rgb(234, 179, 8)',
          backgroundColor: 'rgba(234, 179, 8, 0.5)',
          tension: 0.1,
          fill: true
        }
      ]
    };

    console.log('Chart data prepared:', {
      labels: Object.keys(dateCount).length,
      dataPoints: Object.values(dateCount).length,
      firstDate: Object.keys(dateCount)[0],
      lastDate: Object.keys(dateCount)[Object.keys(dateCount).length - 1],
      totalLeads: cumulative,
      sampleDate: leadDates[0]  // Log a sample date for debugging
    });

  } catch (error) {
    console.error('Error fetching lead growth data:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  updateChartData();
});
</script> 