<template>
  <div class="fixed inset-y-0 flex w-64 flex-col bg-leeonBlue20">
    <div class="flex min-h-0 flex-1 flex-col ">
      <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-24 ">
        <div class="flex flex-shrink-0 items-center px-4">
          <img class="h-12 w-auto" src="../../assets/images/Leecon.png" alt="Leecon" />
        </div>
        <nav class="mt-5 flex-1 space-y-1  bg-leeonBlue20 px-2">
          <!-- Core Features Section -->
          <div class="space-y-2">
            <!-- Show admin dashboard only if admin and not in trainer routes -->
            <div v-if="isAdmin && !isTrainerRoute" class="nav-item" :class="{'active': isCurrentRoute(dashboardPath)}">
              <router-link :to="dashboardPath" class="nav-link" :key="dashboardPath">
                <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
                Vertriebsübersicht
              </router-link>
            </div>

            <!-- Show trainer dashboard for everyone in trainer routes or non-admins -->
            <div v-if="isTrainerRoute || !isAdmin" class="nav-item" :class="{'active': isCurrentRoute('/trainer/dashboard')}">
              <router-link to="/trainer/dashboard" class="nav-link" key="trainer-dashboard">
                <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                </svg>
                Leeon Chat
              </router-link>
            </div>

            <!-- Show Teamplanung for everyone -->
            <div class="nav-item" :class="{'active': isCurrentRoute(planerPath)}">
              <router-link :to="planerPath" class="nav-link">
                <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
                Teamplanung
              </router-link>
            </div>

            <!-- Show Teamverwaltung for everyone -->
            <div class="nav-item" :class="{'active': isCurrentRoute(managerPath)}">
              <router-link :to="managerPath" class="nav-link">
                <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                </svg>
                Teamverwaltung
              </router-link>
            </div>

            <!-- Homework management button -->
            <div class="nav-item" :class="{'active': isCurrentRoute(homeworkPath)}">
              <router-link :to="homeworkPath" class="nav-link" @click.prevent="navigateToHomework">
                <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                </svg>
                Hausaufgaben
                <span v-if="highlightHomework" class="notification-badge">Neu</span>
              </router-link>
            </div>

            <!-- Audit button - show for both admin and trainer -->
            <div class="nav-item" :class="{'active': isCurrentRoute(ablagePath)}">
              <router-link :to="ablagePath" class="nav-link">
                <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke="currentColor">
                  <path d="M9.707 7.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L13 8.586V5h3a2 2 0 012 2v5a2 2 0 01-2 2H8a2 2 0 01-2-2V7a2 2 0 012-2h3v3.586L9.707 7.293zM11 3a1 1 0 112 0v2h-2V3z" />
                  <path d="M4 9a2 2 0 00-2 2v5a2 2 0 002 2h8a2 2 0 002-2H4V9z" />
                </svg>
                Audit
              </router-link>
            </div>
          </div>

          <!-- Communication Section -->
          <div class="nav-section" v-if="isAdmin || isTrainerRoute">
            <div class="section-header" @click="toggleSection('communication')" :class="{ 'active': !collapsedSections.communication }">
              <h3 class="nav-section-title flex items-center cursor-pointer">
                <span>Kommunikation</span>
                <svg class="w-4 h-4 ml-2 transform transition-transform" :class="{ 'rotate-180': !collapsedSections.communication }"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </h3>
            </div>
            <div class="space-y-2 transition-all duration-300 overflow-hidden" :class="{ 'h-0': collapsedSections.communication }">
              <div v-if="!isTrainerRoute" class="nav-item" :class="{'active': isCurrentRoute('/admin/messageboard')}">
                <router-link to="/admin/messageboard" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 6l2-2m0 0l2 2m-2-2v12"></path>
                  </svg>
                  Nachrichtenbrett
                  <span v-if="highlightMessageBoard" class="notification-badge">Neu</span>
                </router-link>
              </div>

              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/compose-message') || isCurrentRoute('/trainer/compose-message')}">
                <router-link :to="composeMessagePath" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  Nachricht verfassen
                </router-link>
              </div>
            </div>
          </div>

          <!-- Management Section -->
          <div class="nav-section" v-if="isAdmin && !isTrainerRoute">
            <div class="section-header" @click="toggleSection('management')" :class="{ 'active': !collapsedSections.management }">
              <h3 class="nav-section-title flex items-center cursor-pointer">
                <span>Verwaltung</span>
                <svg class="w-4 h-4 ml-2 transform transition-transform" :class="{ 'rotate-180': !collapsedSections.management }"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </h3>
            </div>
            <div class="space-y-2 transition-all duration-300 overflow-hidden" :class="{ 'h-0': collapsedSections.management }">
              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/kostentraeger')}">
                <router-link to="/admin/kostentraeger" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Kostenträger
                </router-link>
              </div>

              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/statistics')}">
                <router-link to="/admin/statistics" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                  Statistiken
                </router-link>
              </div>

              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/support-tickets')}">
                <router-link to="/admin/support-tickets" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  Support Tickets
                  <span v-if="highlightSupportTickets" class="notification-badge">Offen</span>
                </router-link>
              </div>
              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/material-management')}">
                <router-link to="/admin/material-management" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                  </svg>
                  Material Management
                </router-link>
              </div>
            </div>
          </div>

          <!-- Business Management Section -->
          <div class="nav-section" v-if="isAdmin && !isTrainerRoute">
            <div class="section-header" @click="toggleSection('business')" :class="{ 'active': !collapsedSections.business }">
              <h3 class="nav-section-title flex items-center cursor-pointer">
                <span>Business Management</span>
                <svg class="w-4 h-4 ml-2 transform transition-transform" :class="{ 'rotate-180': !collapsedSections.business }"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </h3>
            </div>
            <div class="space-y-2 transition-all duration-300 overflow-hidden" :class="{ 'h-0': collapsedSections.business }">
              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/business-customers')}">
                <router-link to="/admin/business-customers" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  Business Customers
                </router-link>
              </div>

              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/participant-management')}">
                <router-link to="/admin/participant-management" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  Participant Management
                </router-link>
              </div>

              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/accounting')}">
                <router-link to="/admin/accounting" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                  Accounting
                </router-link>
              </div>

              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/business-reports')}">
                <router-link to="/admin/business-reports" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                  Reports & Analytics
                </router-link>
              </div>
            </div>
          </div>

          <!-- Team Management Section -->
          <div class="nav-section" v-if="isAdmin && !isTrainerRoute">
            <div class="section-header" @click="toggleSection('internal')" :class="{ 'active': !collapsedSections.internal }">
              <h3 class="nav-section-title flex items-center cursor-pointer">
                <span>INTERN</span>
                <svg class="w-4 h-4 ml-2 transform transition-transform" :class="{ 'rotate-180': !collapsedSections.internal }"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </h3>
            </div>
            <div class="space-y-2 transition-all duration-300 overflow-hidden" :class="{ 'h-0': collapsedSections.internal }">
              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/onboarding')}">
                <router-link to="/admin/onboarding" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                      d="M12 14c1.38 0 2.5-1.12 2.5-2.5S13.38 9 12 9 9.5 10.12 9.5 11.5 10.62 14 12 14zm0 2c-1.38 0-2.5.56-2.5 1.25v1.25H14.5v-1.25C14.5 16.56 13.38 16 12 16zM4 4a2 2 0 012-2h12a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 0v14h12V4H6z" />
                  </svg>
                  Leeon-Team-Rollen
                </router-link>
              </div>

              <div class="nav-item" :class="{'active': isCurrentRoute('/admin/trainermanagement')}">
                <router-link to="/admin/trainermanagement" class="nav-link">
                  <svg class="nav-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                  </svg>
                  Trainerverwaltung
                </router-link>
              </div>
              <div class="nav-item">
                <button @click="logout" class="nav-link" style="cursor: pointer;">
                  <svg class="text-red-150 mr-3 h-6 w-6 transition-colors group-hover:text-red-200" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </nav>


      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { auth } from "../../firebaseinit";
import { useRouter } from "vue-router";
import { collection, query, where, onSnapshot, doc } from "firebase/firestore";
import { cp } from "../../firebaseinit";
import { getIdTokenResult } from "firebase/auth";
import { useNavigationStore } from '../../stores/navigationStore';

const router = useRouter();
const navigationStore = useNavigationStore();
const userName = ref('');
const isAdmin = ref(false);
const highlightMessageBoard = ref(false);
const highlightSupportTickets = ref(false);
const highlightHomework = ref(false);
const trainerID = ref(null);
let unsubscribeMessages = null;
let unsubscribeTickets = null;
let unsubscribeHomework = null;

// Use the store's state
const collapsedSections = computed(() => navigationStore.collapsedSections);

// Use the store's action
const toggleSection = (section) => {
  navigationStore.toggleSection(section);
};

// Check if we're in trainer routes
const isTrainerRoute = computed(() => {
  return router.currentRoute.value.path.startsWith('/trainer');
});

const setupHomeworkListener = () => {
  // Clean up any existing listener first
  if (unsubscribeHomework) {
    unsubscribeHomework();
    unsubscribeHomework = null;
  }

  // Add homework listener
  if (auth.currentUser) {
    // Get the user's email
    const userEmail = auth.currentUser.email;
    console.log('Setting up homework listener for user:', userEmail);

    // Query the trainers collection to find the document with matching email
    const trainersRef = collection(cp, 'trainers');
    const trainerQuery = query(trainersRef, where('email', '==', userEmail));

    // Set up a one-time listener to get the trainer document
    const unsubscribeQuery = onSnapshot(trainerQuery, (snapshot) => {
      if (!snapshot.empty) {
        // Get the first matching document
        const trainerDoc = snapshot.docs[0];
        trainerID.value = trainerDoc.id;
        console.log('Found trainer document:', trainerID.value);

        // Set up the homework listener on the found trainer document
        unsubscribeHomework = onSnapshot(trainerDoc.ref, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            const hasHomework = Array.isArray(data.homeworkToCorrectSlides) && data.homeworkToCorrectSlides.length > 0;
            console.log('Homework status update:', { hasHomework, slides: data.homeworkToCorrectSlides });
            highlightHomework.value = hasHomework;
          } else {
            console.log('No trainer document found');
            highlightHomework.value = false;
          }
        }, error => {
          console.error('Error fetching homework status:', error);
          highlightHomework.value = false;
        });
      } else {
        console.log('No trainer document found with email:', userEmail);
        trainerID.value = null;
        highlightHomework.value = false;
      }

      // Clean up the query listener since we only need it once
      unsubscribeQuery();
    }, error => {
      console.error('Error querying trainer document:', error);
      trainerID.value = null;
      highlightHomework.value = false;
      unsubscribeQuery();
    });
  } else {
    console.log('No current user found for homework listener');
    trainerID.value = null;
    highlightHomework.value = false;
  }
};

// Watch for auth state changes
watch(() => auth.currentUser, async (user) => {
  if (user) {
    userName.value = user.displayName;
    const idTokenResult = await getIdTokenResult(user, true);
    isAdmin.value = idTokenResult.claims.admin === true;
    const isTrainer = idTokenResult.claims.trainer === true;

    console.log('Auth state changed:', { isAdmin: isAdmin.value, isTrainer });
    if (isAdmin.value || isTrainer) {
      setupHomeworkListener();
      // Only setup admin listeners if we're not in trainer routes and user is admin
      if (isAdmin.value && !isTrainerRoute.value) {
        setupAdminListeners();
      }
    }
  } else {
    console.log('User logged out');
    isAdmin.value = false;
    cleanupListeners();
    navigationStore.resetState();
  }
}, { immediate: true });

// Add onMounted hook to ensure listener is set up
onMounted(() => {
  console.log('SideNavigation mounted, setting up listeners');
  if (auth.currentUser) {
    setupHomeworkListener();
  }
});

// Add onUnmounted hook to ensure proper cleanup
onUnmounted(() => {
  console.log('SideNavigation unmounting, cleaning up listeners');
  cleanupListeners();
});

const setupAdminListeners = () => {
  const actionItemsRef = collection(cp, 'actionItems');
  const actionItemsQuery = query(actionItemsRef, where('status', '==', 'open'));

  unsubscribeMessages = onSnapshot(actionItemsQuery, (snapshot) => {
    const items = [];
    snapshot.forEach((doc) => {
      items.push(doc.data());
    });
    highlightMessageBoard.value = items.length > 0;
  });

  const ticketsRef = collection(cp, 'support_tickets');
  const ticketsQuery = query(ticketsRef, where('status', '==', 'open'));

  unsubscribeTickets = onSnapshot(ticketsQuery, (snapshot) => {
    highlightSupportTickets.value = !snapshot.empty;
  }, error => {
    console.error('Error fetching support tickets:', error);
    highlightSupportTickets.value = false;
  });
};

const cleanupListeners = () => {
  if (unsubscribeMessages) {
    unsubscribeMessages();
    unsubscribeMessages = null;
  }
  if (unsubscribeTickets) {
    unsubscribeTickets();
    unsubscribeTickets = null;
  }
  if (unsubscribeHomework) {
    unsubscribeHomework();
    unsubscribeHomework = null;
  }
  trainerID.value = null;
};

const isCurrentRoute = (path) => {
  return router.currentRoute.value.path === path;
};

const dashboardPath = computed(() => {
  const currentPath = router.currentRoute.value.path;
  if (currentPath.startsWith('/admin')) {
    return '/admin/dashboard';
  } else if (currentPath.startsWith('/trainer')) {
    return '/trainer/dashboard';
  } else {
    return '/';
  }
});

const planerPath = computed(() => {
  const currentPath = router.currentRoute.value.path;
  if (currentPath.startsWith('/admin')) {
    return '/admin/planer';
  } else if (currentPath.startsWith('/trainer')) {
    return '/trainer/planer';
  } else {
    return '/';
  }
});

const managerPath = computed(() => {
  const currentPath = router.currentRoute.value.path;
  if (currentPath.startsWith('/admin')) {
    return '/admin/manager';
  } else if (currentPath.startsWith('/trainer')) {
    return '/trainer/manager';
  } else {
    return '/';
  }
});

const ablagePath = computed(() => {
  const currentPath = router.currentRoute.value.path;
  if (currentPath.startsWith('/admin')) {
    return '/admin/ablage';
  } else if (currentPath.startsWith('/trainer')) {
    return '/trainer/ablage';
  } else {
    return '/';
  }
});

const homeworkPath = computed(() => {
  const currentPath = router.currentRoute.value.path;
  const role = currentPath.startsWith('/admin') ? 'admin' :
               currentPath.startsWith('/trainer') ? 'trainer' : '';
  return role ? `/${role}/homework` : '/';
});

const composeMessagePath = computed(() => {
  const role = isTrainerRoute.value ? 'trainer' : 'admin';
  return `/${role}/compose-message`;
});

const logout = () => {
  auth.signOut().then(() => {
    router.push("/");
  });
};

// Add navigation handler
const navigateToHomework = () => {
  const role = router.currentRoute.value.path.startsWith('/admin') ? 'admin' :
               router.currentRoute.value.path.startsWith('/trainer') ? 'trainer' : '';

  if (trainerID.value) {
    // If we have a trainerID, navigate directly to their homework page
    router.push({
      path: `/${role}/homework/${trainerID.value}`,
      query: { status: 'uncorrected' }
    });
  } else {
    // Fallback to the homework manager page
    router.push({
      path: `/${role}/homework`,
      query: { status: 'uncorrected' }
    });
  }
};
</script>

<style scoped>
.nav-section {
  @apply pt-4 first:pt-0;
}

.section-header {
  @apply mb-2 transition-colors duration-200 ease-in-out rounded-md hover:bg-white/5;
}

.section-header.active {
  @apply bg-white/5;
}

.nav-section-title {
  @apply px-3 py-2 text-xs font-semibold text-red-150 uppercase tracking-wider;
}

.nav-section-title svg {
  @apply text-red-150 transition-transform duration-300 ease-in-out;
}

.section-header:hover .nav-section-title {
  @apply text-red-150;
}

.section-header:hover .nav-section-title svg {
  @apply text-red-150;
}

.nav-item {
  @apply rounded-lg border border-leeonBlue40 hover:bg-leeonBlue40 transition-all duration-200 ease-in-out;
}

.nav-item:hover .nav-link {
  @apply text-white;
}

.nav-item:hover .nav-icon {
  @apply text-white;
}

.nav-item.active {
  @apply bg-leeonBlue40 text-white border-leeonBlue60;
}

.nav-link {
  @apply flex items-center px-2 py-2 text-sm font-medium text-leeonDarkBlue rounded-md transition-all duration-200 ease-in-out;
}

.active .nav-link {
  @apply text-white;
}

.nav-icon {
  @apply mr-3 h-6 w-6 transition-all duration-200 ease-in-out;
  @apply text-leeonDarkBlue;
}

.active .nav-icon {
  @apply text-white;
}

.notification-badge {
  @apply ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-leeonPink20 text-leeonPink;
}

/* User Profile Button Hover Effect */
button.group {
  @apply transition-all duration-200 ease-in-out;
}

button.group:hover {
  @apply bg-leeonBlue40 text-white;
}

button.group:hover svg {
  @apply text-white;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-leeonBlue20;
}

::-webkit-scrollbar-thumb {
  @apply bg-leeonBlue60;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-leeonBlue;
}

/* Firefox scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: #003882 #ccebf8;
}
</style>
