import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/tailwind.css";
import "./firebaseinit";
import store from "./store/index";
import { onAuthStateChanged, getIdTokenResult } from "firebase/auth";
import { auth } from "./firebaseinit.js";
import vueVimeoPlayer from "vue-vimeo-player";
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueGtag from 'vue-gtag'
import "./datadog";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

app.use(pinia);
app.use(vueVimeoPlayer);
app.use(store);
app.use(router);
app.use(
  VueGtag,
  {
    appName: "A-Leecon Website",
    pageTrackerScreenviewEnabled: true,
    config: {
      id: "G-7YZR22JH68",
      params: {
        anonymize_ip: true,
      },
    },
  },
  router
);

app.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  if (to.path === "/EAFH6d5BiXn4XxZgxbN9/Seminar") {
    // Redirect to the Bundle route with the same ID
    next("/v5b7TDVYz0COjw18dUnS/Bundle");
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdminAuth = to.matched.some(
    (record) => record.meta.requiresAdminAuth
  );
  const requiresTrainerAuth = to.matched.some(
    (record) => record.meta.requiresTrainerAuth
  );
  const requiresEmailVerification = to.matched.some(
    (record) => record.meta.requiresEmailVerification
  );

  const user = await new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    });
  });

  if (!user) {
    if (requiresAuth || requiresAdminAuth) {
      next("/admin/login");
    } else if (requiresTrainerAuth) {
      next("/trainer/login");
    } else {
      next();
    }
    return;
  }

  const userCreationDate = new Date(user.metadata.creationTime);
  const cutoffDate = new Date("2024-11-10"); // XXX What is this date? Correct to hardcode here? AAA Ask Nadine
  const isNewUser = userCreationDate > cutoffDate;

  try {
    const idTokenResult = await getIdTokenResult(user, true);
    const isAdmin = idTokenResult.claims.admin === true;
    const isTrainer = idTokenResult.claims.trainer === true;
    const isBusinessUser = idTokenResult.claims.businessUser === true;

    if (requiresAdminAuth && !isAdmin) {
      next("/admin/login");
    } else if (requiresTrainerAuth && !isTrainer && !isAdmin) {
      // Allow both trainers and admins to access trainer routes
      next("/trainer/login");
    } else if (requiresEmailVerification && isNewUser && !user.emailVerified && !isBusinessUser) {
      next("/notverified");
    } else {
      next();
    }
  } catch (error) {
    console.error("Error checking user claims:", error);
    next("/error");
  }
});

let appMounted = false;
onAuthStateChanged(auth, () => {
  if (!appMounted) {
    app.mount("#app");
    appMounted = true;
  }
});
