<template>
  <div class="flex-wrap justify-around my-12 hidden lg:flex">
    <div><img
        loading="lazy"
        src="../assets/images/logo/APMG.jpg"
        alt="APMG"
        class="h-10"
      ></div>
    <div><img
        loading="lazy"
        src="../assets/images/logo/AgilePMLogo.jpg"
        alt="Agile PM"
        class="h-10"
      ></div>
  </div>
</template>