<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div class="flex items-center gap-2">
        <h2 class="text-lg font-semibold">Konvertierte Leads</h2>
        <div class="relative group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-500 cursor-help"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div class="absolute left-0 top-6 w-80 p-2 bg-gray-800 text-white text-sm rounded shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10">
            <p class="mb-2">Diese Grafik zeigt konvertierte Leads über die Zeit:</p>
            <ul class="list-disc pl-4 space-y-1">
              <li>Basiert auf dem Entstehungsdatum der Leads</li>
              <li>Zeigt konvertierte Leads pro Monat</li>
              <li>Zeitraum kann gefiltert werden</li>
              <li>Kann nach Lead-Quellen gefiltert werden</li>
              <li>Kann als absolute Zahl oder Konversionsrate angezeigt werden</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="flex items-center gap-3">
        <!-- Display Type Dropdown (1st dropdown, leftmost) -->
        <select
          v-model="selectedDisplayType"
          class="rounded-md border border-gray-300 px-3 py-1.5 text-sm w-48"
        >
          <option value="absolut">Darstellung: Absolut</option>
          <option value="rate">Darstellung: Rate</option>
        </select>

        <!-- Time Range Dropdown (2nd dropdown) -->
        <div class="flex items-center gap-2">
          <select
            v-model="selectedRange"
            class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
          >
            <option value="all">Gesamte Zeit</option>
            <option value="custom">Benutzerdefiniert</option>
          </select>

          <div v-if="selectedRange === 'custom'" class="flex items-center gap-2">
            <input
              type="date"
              v-model="startDate"
              class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
            />
            <span>bis</span>
            <input
              type="date"
              v-model="endDate"
              class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
            />
          </div>
        </div>

        <!-- Source View Dropdown (3rd dropdown) -->
        <select
          v-model="selectedSourceView"
          class="rounded-md border border-gray-300 px-3 py-1.5 text-sm"
        >
          <option value="egal">Lead-Quelle: Egal</option>
          <option value="alle">Lead-Quelle: Alle</option>
          <option value="einzelne">Lead-Quelle: Einzelne</option>
        </select>

        <!-- Source Selection Dropdown (4th dropdown, conditional) -->
        <div v-if="selectedSourceView === 'einzelne'" class="relative">
          <button
            @click="toggleSourcesDropdown"
            class="flex items-center gap-1 rounded-md border border-gray-300 px-3 py-1.5 text-sm"
          >
            Quellen-Auswahl
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          <div
            v-if="showSourcesDropdown"
            class="absolute left-0 top-10 z-10 w-64 max-h-60 overflow-y-auto bg-white rounded-md shadow-lg border border-gray-200 sources-dropdown"
          >
            <div class="p-2">
              <div class="mb-2 flex justify-between items-center">
                <span class="text-sm font-medium">Lead-Quellen</span>
                <button @click="selectAllSources" class="text-xs text-blue-600 hover:text-blue-800">
                  {{ selectedSources.length === availableSources.length ? 'Alle abwählen' : 'Alle auswählen' }}
                </button>
              </div>
              <div class="space-y-1">
                <label
                  v-for="source in availableSources"
                  :key="source"
                  class="flex items-center gap-2 text-sm cursor-pointer hover:bg-gray-50 p-1 rounded"
                >
                  <input
                    type="checkbox"
                    v-model="selectedSources"
                    :value="source"
                    class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  {{ source }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="h-[400px]">
      <div v-if="loading" class="flex items-center justify-center h-full">
        <span class="text-gray-500">Lade Daten...</span>
      </div>
      <Line
        v-else-if="chartData && chartData.datasets.length > 0 && chartData.datasets[0].data.length > 0"
        :data="chartData"
        :options="chartOptions"
        ref="chartRef"
      />
      <div v-else class="flex items-center justify-center h-full">
        <span class="text-gray-500">Keine Daten verfügbar</span>
      </div>
    </div>

    <!-- Custom Legend for sources -->
    <div
      v-if="(selectedSourceView === 'alle' || selectedSourceView === 'einzelne') &&
            !loading &&
            chartData &&
            chartData.datasets.length > 0"
      class="mt-4 flex flex-wrap gap-4 justify-center"
    >
      <div
        v-for="(dataset, index) in chartData.datasets"
        :key="dataset.label"
        class="flex items-center cursor-pointer hover:bg-gray-100 px-2 py-1 rounded transition-colors"
        @mouseenter="highlightSource(index)"
        @mouseleave="resetHighlight()"
      >
        <span
          class="inline-block w-4 h-4 mr-2 rounded-full"
          :style="{ backgroundColor: dataset.borderColor }"
        ></span>
        <span class="text-sm">{{ dataset.label }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted, nextTick } from 'vue';
import { Line } from 'vue-chartjs';
import { cp } from '../../../firebaseinit.js';
import { collection, getDocs } from 'firebase/firestore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

// State variables
const loading = ref(true);
const chartData = ref(null);
const selectedDisplayType = ref('absolut');
const selectedSourceView = ref('egal');
const showSourcesDropdown = ref(false);
const availableSources = ref([]);
const selectedSources = ref([]);
const monthlyData = ref({});
const chartRef = ref(null);
const highlightedSourceIndex = ref(null);
const tooltipTimeoutId = ref(null);

// Time range filter variables
const selectedRange = ref('all');
const startDate = ref(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0]);
const endDate = ref(new Date().toISOString().split('T')[0]);

// Store original styles to restore them later
const originalStyles = ref({
  borderWidth: 2,
  pointRadius: 4,
  pointHoverRadius: 6
});

// Chart colors for different sources
const sourceColors = [
  { borderColor: 'rgb(59, 130, 246)', backgroundColor: 'rgba(59, 130, 246, 0.5)' },  // Blue
  { borderColor: 'rgb(16, 185, 129)', backgroundColor: 'rgba(16, 185, 129, 0.5)' },  // Green
  { borderColor: 'rgb(249, 115, 22)', backgroundColor: 'rgba(249, 115, 22, 0.5)' },  // Orange
  { borderColor: 'rgb(236, 72, 153)', backgroundColor: 'rgba(236, 72, 153, 0.5)' },  // Pink
  { borderColor: 'rgb(139, 92, 246)', backgroundColor: 'rgba(139, 92, 246, 0.5)' },  // Purple
  { borderColor: 'rgb(234, 179, 8)', backgroundColor: 'rgba(234, 179, 8, 0.5)' },    // Yellow
  { borderColor: 'rgb(239, 68, 68)', backgroundColor: 'rgba(239, 68, 68, 0.5)' },    // Red
  { borderColor: 'rgb(20, 184, 166)', backgroundColor: 'rgba(20, 184, 166, 0.5)' },  // Teal
  { borderColor: 'rgb(217, 119, 6)', backgroundColor: 'rgba(217, 119, 6, 0.5)' },    // Amber
  { borderColor: 'rgb(124, 58, 237)', backgroundColor: 'rgba(124, 58, 237, 0.5)' },  // Violet
];

// Highlight a specific source when hovering over legend item
const highlightSource = (index) => {
  if (!chartRef.value || !chartRef.value.chart) return;

  try {
    // Set the highlighted index
    highlightedSourceIndex.value = index;

    // Clear any existing timeout
    if (tooltipTimeoutId.value) {
      clearTimeout(tooltipTimeoutId.value);
      tooltipTimeoutId.value = null;
    }

    // Get a reference to the chart instance
    const chartInstance = chartRef.value.chart;

    // Apply visual changes to the datasets
    for (let i = 0; i < chartInstance.data.datasets.length; i++) {
      try {
        const meta = chartInstance.getDatasetMeta(i);
        if (!meta || meta.hidden) continue;

        // Determine if this is the highlighted dataset
        const isHighlighted = i === index;

        // Apply styles to the line
        if (meta.dataset) {
          meta.dataset.options = meta.dataset.options || {};
          meta.dataset.options.borderWidth = isHighlighted ? 3 : 1;
          meta.dataset.options.borderColor = isHighlighted
            ? chartInstance.data.datasets[i].borderColor
            : `${chartInstance.data.datasets[i].borderColor}80`; // Add transparency to non-highlighted
        }

        // Apply styles to the points
        if (meta.data) {
          meta.data.forEach(point => {
            if (point && point.options) {
              point.options.radius = isHighlighted ? 6 : 2;
              point.options.hoverRadius = isHighlighted ? 8 : 3;
              point.options.borderWidth = isHighlighted ? 2 : 1;
            }
          });
        }
      } catch (err) {
        console.error(`Error processing dataset ${i}:`, err);
      }
    }

    // Force a redraw without triggering a full update
    chartInstance.draw();

    // Find the first non-zero data point of the highlighted dataset
    const dataset = chartInstance.data.datasets[index];
    if (dataset && dataset.data) {
      const pointIndex = dataset.data.findIndex(value => value > 0);
      if (pointIndex >= 0) {
        // Use a timeout to ensure the chart has updated before showing tooltip
        tooltipTimeoutId.value = setTimeout(() => {
          try {
            if (!chartInstance || !chartInstance.getDatasetMeta) return;

            // Force a redraw without triggering a full update
            chartInstance.draw();
          } catch (error) {
            console.error('Error showing tooltip:', error);
          }
        }, 50);
      }
    }
  } catch (error) {
    console.error('Error in highlightSource:', error);
  }
};

// Reset highlight when mouse leaves legend item
const resetHighlight = () => {
  if (!chartRef.value || !chartRef.value.chart) return;

  try {
    // Clear the highlighted index
    highlightedSourceIndex.value = null;

    // Clear any active tooltips
    if (tooltipTimeoutId.value) {
      clearTimeout(tooltipTimeoutId.value);
      tooltipTimeoutId.value = null;
    }

    // Get a reference to the chart instance
    const chartInstance = chartRef.value.chart;
    if (!chartInstance) return;

    // Reset all datasets to original appearance
    for (let i = 0; i < chartInstance.data.datasets.length; i++) {
      try {
        const meta = chartInstance.getDatasetMeta(i);
        if (!meta || meta.hidden) continue;

        // Reset line style
        if (meta.dataset) {
          meta.dataset.options = meta.dataset.options || {};
          meta.dataset.options.borderWidth = originalStyles.value.borderWidth;
          meta.dataset.options.borderColor = chartInstance.data.datasets[i].borderColor;
        }

        // Reset point styles
        if (meta.data) {
          meta.data.forEach(point => {
            if (point && point.options) {
              point.options.radius = originalStyles.value.pointRadius;
              point.options.hoverRadius = originalStyles.value.pointHoverRadius;
              point.options.borderWidth = originalStyles.value.borderWidth;
            }
          });
        }
      } catch (err) {
        console.error(`Error resetting dataset ${i}:`, err);
      }
    }

    // Force a redraw without triggering a full update
    chartInstance.draw();
  } catch (error) {
    console.error('Error in resetHighlight:', error);
  }
};

// Chart options
const chartOptions = computed(() => {
  const isRate = selectedDisplayType.value === 'rate';
  const showMultipleSources = selectedSourceView.value === 'alle' ||
    (selectedSourceView.value === 'einzelne' && selectedSources.value.length > 0);

  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the default legend since we're using our custom one
        position: 'bottom'
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const value = context.raw;
            if (isRate) {
              return `${context.dataset.label}: ${(value * 100).toFixed(1)}%`;
            } else {
              return `${context.dataset.label}: ${value}`;
            }
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: isRate ? 'Konversionsrate (%)' : 'Anzahl konvertierter Leads'
        },
        ticks: {
          callback: function(value) {
            if (isRate) {
              return `${(value * 100).toFixed(0)}%`;
            }
            return value;
          }
        },
        max: isRate ? 1 : undefined
      },
      x: {
        title: {
          display: true,
          text: 'Monat'
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 12 // Limit the number of ticks shown for readability
        }
      }
    },
    elements: {
      point: {
        radius: 4,
        hoverRadius: 6,
        borderWidth: 2
      },
      line: {
        tension: 0.1,
        borderWidth: 2
      }
    }
  };
});

// Format date to month and year
const formatMonthYear = (date) => {
  return new Date(date).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'short'
  });
};

// Parse date from Firestore
const parseDate = (dateValue) => {
  if (!dateValue) return null;

  try {
    // If it's a Firestore Timestamp
    if (dateValue.toDate) {
      return dateValue.toDate();
    }
    // If it's a string
    if (typeof dateValue === 'string') {
      const date = new Date(dateValue);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    // If it's already a Date object
    if (dateValue instanceof Date) {
      return dateValue;
    }
  } catch (error) {
    console.error('Error parsing date:', dateValue, error);
  }
  return null;
};

// Check if a date is within the selected time range
const isDateInRange = (date) => {
  if (selectedRange.value === 'all') return true;
  if (!date) return false;

  const start = new Date(startDate.value);
  const end = new Date(endDate.value);
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  return date >= start && date <= end;
};

// Toggle sources dropdown
const toggleSourcesDropdown = () => {
  showSourcesDropdown.value = !showSourcesDropdown.value;
};

// Select all sources
const selectAllSources = () => {
  if (selectedSources.value.length === availableSources.value.length) {
    // If all sources are selected, deselect all
    selectedSources.value = [];
  } else {
    // Otherwise, select all sources
    selectedSources.value = [...availableSources.value];
  }
};

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
  // Get the dropdown button and dropdown content elements
  const dropdownButton = document.querySelector('.relative button');
  const dropdown = document.querySelector('.sources-dropdown');

  // Only close if both elements exist, dropdown is open, and click is outside both elements
  if (showSourcesDropdown.value &&
      dropdown &&
      dropdownButton &&
      !dropdown.contains(event.target) &&
      !dropdownButton.contains(event.target)) {
    showSourcesDropdown.value = false;
  }
};

// Update chart based on selected options
const updateChart = async () => {
  try {
    loading.value = true;
    const customersRef = collection(cp, 'customers');
    const snapshot = await getDocs(customersRef);

    // Process customer data
    const leadData = [];
    const sources = new Set();

    snapshot.forEach(doc => {
      const data = doc.data();
      const created = parseDate(data?.created);
      const source = data?.source || 'Unbekannt';
      const hasTeamMateInfo = Array.isArray(data?.TeamMateInfo) && data.TeamMateInfo.length > 0;
      const status = data?.status;

      if (created && isDateInRange(created)) {
        // Check if lead is converted
        const isConverted = ((status !== 'lead') && (status !== 'archive') && (hasTeamMateInfo)) ||
                            (status === 'waiting') || (status === 'contract') ||
                            (status === 'ready') || (status === 'active');

        leadData.push({
          created,
          source,
          isConverted
        });

        // Add to available sources
        sources.add(source);
      }
    });

    // Sort leads by creation date
    leadData.sort((a, b) => a.created - b.created);

    // Update available sources
    availableSources.value = Array.from(sources);
    if (selectedSources.value.length === 0) {
      selectedSources.value = Array.from(sources);
    }

    // Group data by month and source
    const monthlyDataBySource = {};

    leadData.forEach(lead => {
      const monthYear = formatMonthYear(lead.created);
      const source = lead.source;

      if (!monthlyDataBySource[monthYear]) {
        monthlyDataBySource[monthYear] = {};
      }

      if (!monthlyDataBySource[monthYear][source]) {
        monthlyDataBySource[monthYear][source] = {
          total: 0,
          converted: 0
        };
      }

      monthlyDataBySource[monthYear][source].total++;
      if (lead.isConverted) {
        monthlyDataBySource[monthYear][source].converted++;
      }
    });

    // Get all months from the data
    const existingMonths = Object.keys(monthlyDataBySource);

    if (existingMonths.length === 0) {
      chartData.value = {
        labels: [],
        datasets: []
      };
      return;
    }

    // Find the earliest and latest dates to create a complete month range
    let earliestDate = new Date();
    let latestDate = new Date(0);

    existingMonths.forEach(monthStr => {
      const dateParts = monthStr.split(' ');
      // Handle German month abbreviations
      const monthMap = {
        'Jan.': 0, 'Feb.': 1, 'März': 2, 'Apr.': 3, 'Mai': 4, 'Juni': 5,
        'Juli': 6, 'Aug.': 7, 'Sept.': 8, 'Okt.': 9, 'Nov.': 10, 'Dez.': 11
      };

      const month = monthMap[dateParts[0]];
      const year = parseInt(dateParts[1]);
      const date = new Date(year, month, 1);

      if (date < earliestDate) earliestDate = new Date(date);
      if (date > latestDate) latestDate = new Date(date);
    });

    // Generate all months between earliest and latest
    const allMonths = [];
    const allMonthsFormatted = [];
    const currentDate = new Date(earliestDate);

    while (currentDate <= latestDate) {
      const formattedMonth = formatMonthYear(new Date(currentDate));
      allMonthsFormatted.push(formattedMonth);
      allMonths.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    const isRate = selectedDisplayType.value === 'rate';
    const datasets = [];

    if (selectedSourceView.value === 'egal') {
      // Aggregate all sources
      const data = allMonthsFormatted.map(month => {
        const sourcesData = monthlyDataBySource[month] || {};
        let totalLeads = 0;
        let totalConverted = 0;

        Object.values(sourcesData).forEach(sourceData => {
          totalLeads += sourceData.total;
          totalConverted += sourceData.converted;
        });

        return isRate ? (totalLeads > 0 ? totalConverted / totalLeads : 0) : totalConverted;
      });

      datasets.push({
        label: 'Alle Quellen',
        data,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.8)',
        tension: 0.1,
        fill: true
      });
    } else if (selectedSourceView.value === 'alle' || selectedSourceView.value === 'einzelne') {
      // Determine which sources to include
      const sourcesToInclude = selectedSourceView.value === 'alle'
        ? availableSources.value
        : selectedSources.value;

      // Create a dataset for each source
      sourcesToInclude.forEach((source, index) => {
        const colorIndex = index % sourceColors.length;
        const data = allMonthsFormatted.map(month => {
          const sourcesData = monthlyDataBySource[month] || {};
          const sourceData = sourcesData[source] || { total: 0, converted: 0 };

          return isRate
            ? (sourceData.total > 0 ? sourceData.converted / sourceData.total : 0)
            : sourceData.converted;
        });

        datasets.push({
          label: source,
          data,
          borderColor: sourceColors[colorIndex].borderColor,
          backgroundColor: sourceColors[colorIndex].backgroundColor,
          tension: 0.1,
          fill: false
        });
      });
    }

    chartData.value = {
      labels: allMonthsFormatted,
      datasets
    };

    // Store original styles for later restoration
    originalStyles.value = {
      borderWidth: 2,
      pointRadius: 4,
      pointHoverRadius: 6
    };

  } catch (error) {
    console.error('Error updating lead conversion chart:', error);
  } finally {
    loading.value = false;

    // Wait for the chart to be rendered before trying to access it
    await nextTick();

    // Reset any highlighted source when data changes
    if (highlightedSourceIndex.value !== null) {
      resetHighlight();
      highlightedSourceIndex.value = null;
    }
  }
};

// Watch for changes in display options and time range
watch([selectedDisplayType, selectedSourceView, selectedSources, selectedRange, startDate, endDate], () => {
  updateChart();
});

// Lifecycle hooks
onMounted(() => {
  updateChart();
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);

  // Clear any pending timeouts
  if (tooltipTimeoutId.value) {
    clearTimeout(tooltipTimeoutId.value);
    tooltipTimeoutId.value = null;
  }
});
</script>

<style scoped>
input[type="checkbox"] {
  appearance: auto;
  -webkit-appearance: checkbox;
  height: 16px;
  width: 16px;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  background-color: white;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #2563eb;
  border-color: #2563eb;
}
</style>
