<template>
  <div class="flex-wrap -mx-8 justify-center pt-8 hidden lg:flex">


    <div class="flex justify-center mb-32"><span class="text-blue-250 font-thin text-center text-sm">ITIL®, ITIL® 4,
        PRINCE2® and PRINCE2 Agile® are a registered trademarks of AXELOS Limited, used under permission of AXELOS
        Limited. All rights reserved. <br>

        The Swirl logo™ is a trademark of AXELOS Limited, used under permission of AXELOS Limited. All rights reserved.
        <br>

        The ITIL®, ITIL® 4, PRINCE2® and PRINCE2 Agile® Accredited Training Organization logos are a trademarks of
        AXELOS.
        Limited, used under permission of AXELOS Limited. All reserved. AXELOS seminars can only be acquired with the
        examination.</span></div>

  </div>
</template>
