import { db } from "../../firebaseinit";

const state = {
  availableCourses: [],
  star: [],
  callMeBanner: false,
};
const getters = {
  getAvailableCourses: (state) => {
    return state.availableCourses;
  },
  getStars: (state) => {
    return state.star;
  },
  callMeBanner: (state) => {
    return state.callMeBanner;
  },
};
const mutations = {
  callMeBanner(state) {
    state.callMeBanner = !state.callMeBanner;
  },
  SET_AVAILABLE_COURSES: (state, Courses) => {
    state.availableCourses = Courses;
  
  },
  remember(state, payload) {
    if (state.star.includes(payload)) {
      let index = state.star.indexOf(payload);
      state.star.splice(index, 1);
    } else {
      state.star.push(payload);
    }
  },
  deleteRemember(state) {
    state.star = [];
  },
  quote(state, payload) {
    if (!state.star.includes(payload)) {
      state.star.push(payload);
    }
  },
};
const actions = {
  getCourses: ({ commit }) => {
    let Courses = [];
    db.collection("courses")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let Course = {};
          Course.name = doc.data().name;
          Course.shortdescription = doc.data().shortdescription;
          Course.imageLink = doc.data().imageLink;
          Course.description = doc.data().description;
          Course.id = doc.id;
          Courses.push(Course);
        });
      })
      .then(() => {
        Courses.forEach((el) => {
          let lessons = [];
          db.collection("lessons")
            .where("course", "==", el.id)
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                lessons.push(doc.data().headline);
              });
            });
          el.lessons = lessons;
        });
      });
    commit("SET_AVAILABLE_COURSES", Courses);
    console.log("Action", Courses);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
