export const contentDBConfig = {
  apiKey: import.meta.env.VITE_CONTENTDB_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_CONTENTDB_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_CONTENTDB_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_CONTENTDB_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_CONTENTDB_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env
    .VITE_CONTENTDB_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_CONTENTDB_FIREBASE_APP_ID,
};

export const customerPortalConfig = {
  apiKey: import.meta.env.VITE_CUSTOMERPORTAL_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_CUSTOMERPORTAL_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_CUSTOMERPORTAL_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_CUSTOMERPORTAL_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env
    .VITE_CUSTOMERPORTAL_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env
    .VITE_CUSTOMERPORTAL_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_CUSTOMERPORTAL_FIREBASE_APP_ID,
};
